import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { SidePanelDialogActionsComponent } from 'gain-lib/dialog/side-panel-dialog/side-panel-dialog-actions/side-panel-dialog-actions.component';
import { SidePanelDialogComponent } from 'gain-lib/dialog/side-panel-dialog/side-panel-dialog.component';
import { SidePanelDialogTitleComponent } from 'gain-lib/dialog/side-panel-dialog/side-panel-dialog-title/side-panel-dialog-title.component';
import { SidePanelDialogContentComponent } from 'gain-lib/dialog/side-panel-dialog/side-panel-dialog-content/side-panel-dialog-content.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { SidePanelDialogService } from 'gain-lib/dialog/side-panel-dialog/side-panel-dialog.service';

@NgModule({
  declarations: [
    SidePanelDialogComponent,
    SidePanelDialogActionsComponent,
    SidePanelDialogTitleComponent,
    SidePanelDialogContentComponent,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
  ],
  exports: [
    SidePanelDialogComponent,
    SidePanelDialogActionsComponent,
    SidePanelDialogTitleComponent,
    SidePanelDialogContentComponent,
  ],
  providers: [SidePanelDialogService],
})
export class GaSidePanelModalModule {}
