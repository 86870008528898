import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

/*
 * Emphasize characters within a given string
 * Takes in 2 string arguments
 * Usage:
 *   value | EmphasizeCharacters:searchTerm
 * Example:
 *   {{ 'nike' | EmphasizeCharacters:'ni' }}
 *   formats to: <strong>ni<strong><span>ke<span>
 */

@Pipe({ name: 'emphasizeCharacters' })
export class EmphasizeCharacters implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) {}

  transform(value: string, inputValue: string): SafeHtml {
    if (inputValue == null) return value;
    const regExp: RegExp = new RegExp(inputValue, 'gmi');
    const indexOf = value.substring(0).search(regExp);
    if (inputValue.length < 1 || indexOf === -1) return value;
    const preSearchTerm = value.slice(0, indexOf);
    const searchTerm = value.slice(indexOf, indexOf + inputValue.length);
    const postSearchTerm = value.slice(indexOf + inputValue.length);
    const html = `<span>${preSearchTerm}<strong>${searchTerm}</strong>${postSearchTerm}</span>`;
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }
}
