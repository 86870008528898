@if (hostClass === "editing") {
  <input
    type="number"
    [matTooltip]="toolTipText"
    (input)="handleInputChanged($event.target)"
    (keydown.escape)="exitEditMode()"
    (keydown.enter)="updateValue(input.value)"
    [placeholder]="displayValue | percent: percentageFormat"
    #input
    class="cell-input"
  />
  <mat-icon (click)="updateValue(input.value)">check</mat-icon>
  <mat-icon (click)="exitEditMode()">close</mat-icon>
} @else {
  <div class="cell-display-value" (click)="enterEditMode()">
    <div>
      {{ displayValue | percent: percentageFormat }}
    </div>
  </div>
  <mat-icon
    matTooltip="Revert to default value"
    (click)="revertChanges()"
    *ngIf="hostClass === 'edited'"
    class="color-green-darker"
    >cached
  </mat-icon>
}
