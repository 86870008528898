import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'gax-button-spinner',
  templateUrl: './ga-button-spinner.component.html',
  styleUrls: ['./ga-button-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GaButtonSpinnerComponent {
  @Input()
  diameter: number = 24;
}
