import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ViewEncapsulation,
} from '@angular/core';
import { PageHeaderActionsComponent } from './page-header-actions/page-header-actions.component';

@Component({
  selector: 'gax-page-header',
  templateUrl: './page-header.component.html',
  styleUrl: './page-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PageHeaderComponent {
  @ContentChild(PageHeaderActionsComponent)
  pageActionComponent!: PageHeaderActionsComponent;
}
