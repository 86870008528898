import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'gax-option-group',
  templateUrl: './option-group.component.html',
  styleUrls: ['./option-group.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OptionGroupComponent {
  @Input()
  label: string = '';
}
