import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { CountryNameService, RegionNameService } from 'gain-lib/geography';
import { CountryRegionDataService } from 'gain-web/shared-modules/country-region-data/country-region-data.service';
import { Observable, combineLatest } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  skip,
  startWith,
  takeUntil,
} from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LocationFormHelper {
  constructor(
    private _countryRegionDataService: CountryRegionDataService,
    private _countryName: CountryNameService,
    private _regionName: RegionNameService,
  ) {}

  public getCountryOptions(control: FormControl<any>) {
    return control.valueChanges.pipe(
      startWith(control.value),
      map((value) => {
        // Show all countries as options unless we know we're getting input from the user
        return value == null || control.valid
          ? this._countryRegionDataService.countries
          : this._countryRegionDataService.getAllCountriesByNameOrCode(value);
      }),
    );
  }

  public displayCountryNameAndCode(countryCode: string | null) {
    const name =
      countryCode != null
        ? this._countryName.findCountryName(countryCode)
        : null;
    return name != null ? `${name} (${countryCode})` : '';
  }

  public supportsRegionCalculations(countryCode: string | null) {
    return (
      countryCode != null &&
      this._countryRegionDataService.findCountryByCode(countryCode)
        ?.supportsRegionCalculations === true
    );
  }

  public displayRegionNameAndCode(countryControl: FormControl<string | null>) {
    return (regionCode: string | null) => {
      const country =
        countryControl.value != null
          ? this._countryRegionDataService.findCountryByCode(
              countryControl.value,
            )
          : null;
      if (country != null && regionCode != null) {
        const regionName = this._regionName.findRegionName({
          countryCode: country.code,
          regionCode,
        });
        if (regionName != null) {
          return `${regionName} (${regionCode})`;
        }
      }
      return '';
    };
  }

  public init({
    countryControl,
    regionControl,
    onDestroy$,
  }: {
    countryControl: FormControl<string | null | undefined>;
    regionControl?: FormControl<string | null | undefined>;
    onDestroy$: Observable<any>;
  }) {
    function setToNullWhenWhitespace(
      control: FormControl<string | null | undefined>,
    ) {
      control.valueChanges.pipe(takeUntil(onDestroy$)).subscribe((value) => {
        if (value != null && value.trim() === '') {
          control.setValue(null, { emitEvent: false });
        }
      });
    }

    setToNullWhenWhitespace(countryControl);
    if (regionControl != null) {
      setToNullWhenWhitespace(regionControl);
      countryControl.valueChanges
        .pipe(
          // Start with current value and skip one after we've detected
          // changes to ensure we're only observing true value changes
          startWith(countryControl.value),
          distinctUntilChanged(),
          skip(1),
          takeUntil(onDestroy$),
        )
        .subscribe((countryCode) => {
          const country =
            countryCode != null
              ? this._countryRegionDataService.findCountryByCode(countryCode)
              : null;
          // any time the country changes, the region control should be considered
          // untouched and unmodified via the user
          regionControl.markAsUntouched();
          regionControl.markAsPristine();
          regionControl.clearValidators();
          if (country != null && country.supportsRegionCalculations) {
            regionControl.setValidators([
              Validators.required,
              this.validRegionCode(countryCode),
            ]);
          }
          regionControl.setValue(null);
          regionControl.updateValueAndValidity({ emitEvent: false });
        });
    }
  }

  private validRegionCode(countryCode: string | null | undefined) {
    return (control: AbstractControl) => {
      if (control.value == null || countryCode == null) {
        return null;
      }
      const country =
        this._countryRegionDataService.findCountryByCode(countryCode);
      if (
        country != null &&
        country.regions.some((r) => r.code === control.value)
      ) {
        return null;
      }
      return {
        invalidRegion: control.value,
      };
    };
  }

  public getSupportedRegionOptions(
    countryControl: FormControl<string | null>,
    regionControl: FormControl<string | null>,
  ) {
    return combineLatest([
      countryControl.valueChanges.pipe(startWith(countryControl.value)),
      regionControl.valueChanges.pipe(startWith(regionControl.value)),
    ]).pipe(
      map(([countryCtrlValue, regionCtrlValue]) => {
        const country =
          countryCtrlValue != null
            ? this._countryRegionDataService.findCountryByCode(countryCtrlValue)
            : null;
        if (country == null || !country.supportsRegionCalculations) {
          return [];
        }
        if (regionCtrlValue == null) {
          return country.regions;
        }
        const regionControlValueLower = regionCtrlValue.toLowerCase();
        return country.regions.filter(
          (r) =>
            r.code.toLowerCase().includes(regionControlValueLower) ||
            r.name.toLowerCase().includes(regionControlValueLower),
        );
      }),
    );
  }

  public getRegionOptions(countryControl: FormControl<string | null>) {
    return combineLatest([
      countryControl.valueChanges.pipe(startWith(countryControl.value)),
    ]).pipe(
      map(([countryCtrlValue]) => {
        const country =
          countryCtrlValue != null
            ? this._countryRegionDataService.findCountryByCode(countryCtrlValue)
            : null;
        if (country == null) {
          return [];
        }

        return country.regions;
      }),
    );
  }
}
