import {
  Directive,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { PageEditTracker } from 'gain-lib/ga-page-editing/page-edit-tracker';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export type HighlightableRow = {
  editedOn: Date | undefined | null;
  saved?: boolean | null;
};

@Directive({ selector: '[gaxHighlightableRow]' })
export class GaEditedHighlightDirective
  implements OnChanges, OnDestroy, OnInit
{
  onDestroy$ = new Subject();
  @Input('gaxHighlightableRow') row!: HighlightableRow;

  private _saved: boolean = false;
  private _edited: boolean = false;
  private _previouslyEdited = false;

  @HostBinding('class')
  get classes(): Record<string, boolean> {
    return {
      saved: this._saved,
      edited: this._edited,
      'previously-edited': this._previouslyEdited,
    };
  }

  constructor(private _pageEdits: PageEditTracker) {}

  ngOnChanges(changes: SimpleChanges): void {
    const change: SimpleChange | undefined = changes.row;

    if (change != null) {
      const row = change.currentValue as HighlightableRow;
      this._applyStyles(row);
    }
  }

  private _applyStyles(row: HighlightableRow) {
    this._previouslyEdited = this._edited;
    this._saved = row.saved || this._pageEdits.saved;
    const recentlyEdited =
      row.editedOn != null &&
      new Date().getTime() - row.editedOn.getTime() < 1000;
    this._edited = !this._saved && (this._edited || recentlyEdited);
    this._previouslyEdited = this._saved && recentlyEdited;

    if (this._saved) {
      setTimeout(() => {
        this._previouslyEdited = this._edited;
      }, 3000);
    }
  }

  ngOnInit() {
    this._applyStyles(this.row);
    this._pageEdits.state$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((pageEditState) => {
        if (pageEditState === 'SAVED') {
          this._applyStyles(this.row);
        }
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
