// TODO: This list was provided in user story: https://dev.azure.com/apps-its-us-deloitte/GES-Incentives/_workitems/edit/3006716
// Consider leveraging ISO 4217 and putting in ga-money module in ui kit
const _currencyCodes = [
  'AED',
  'AFN',
  'ALL',
  'AMD',
  'AOA',
  'ARS',
  'AUD',
  'AWG',
  'AZN',
  'BAM',
  'BBD',
  'BDT',
  'BGN',
  'BHD',
  'BIF',
  'BMD',
  'BND',
  'BOB',
  'BRL',
  'BSD',
  'BTN',
  'BWP',
  'BZD',
  'CAD',
  'CDF',
  'CHF',
  'CLP',
  'CNY',
  'COP',
  'CRC',
  'CUP',
  'CVE',
  'CZK',
  'DJF',
  'DKK',
  'DOP',
  'DZD',
  'EGP',
  'ERN',
  'ETB',
  'EUR',
  'FJD',
  'FKP',
  'GBP',
  'GEL',
  'GHS',
  'GIP',
  'GMD',
  'GNF',
  'GTQ',
  'GYD',
  'HKD',
  'HNL',
  'HRK',
  'HTG',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'IQD',
  'ISK',
  'JMD',
  'JOD',
  'JPY',
  'KES',
  'KGS',
  'KHR',
  'KMF',
  'KPW',
  'KRW',
  'KWD',
  'KYD',
  'KZT',
  'LAK',
  'LBP',
  'LKR',
  'LRD',
  'LSL',
  'LTL',
  'LYD',
  'MAD',
  'MDL',
  'MGA',
  'MKD',
  'MMK',
  'MNT',
  'MOP',
  'MRO',
  'MUR',
  'MVR',
  'MWK',
  'MXN',
  'MYR',
  'MZN',
  'NAD',
  'NGN',
  'NIO',
  'NOK',
  'NPR',
  'NZD',
  'OMR',
  'PEN',
  'PGK',
  'PHP',
  'PKR',
  'PLN',
  'PYG',
  'QAR',
  'RON',
  'RSD',
  'RWF',
  'SAR',
  'SBD',
  'SCR',
  'SDD',
  'SDG',
  'SEK',
  'SGD',
  'SHP',
  'SLL',
  'SOS',
  'SRG',
  'STD',
  'SVC',
  'SYP',
  'SZL',
  'THB',
  'TJS',
  'TMT',
  'TND',
  'TOP',
  'TRY',
  'TTD',
  'TWD',
  'TZS',
  'UAH',
  'UGX',
  'USD',
  'UYU',
  'UZS',
  'VES',
  'VND',
  'VUV',
  'WST',
  'XAF',
  'XCD',
  'XOF',
  'XPF',
  'YER',
  'ZAR',
  'ZMW',
  'ZWD',
] as const;

// prettier-ignore
export type CurrencyCode = typeof _currencyCodes[number];

export const ISO_4217 = [
  { currencyCode: 'AED', currencyName: 'United Arab Emirates dirham' },
  { currencyCode: 'AFN', currencyName: 'Afghan afghani' },
  { currencyCode: 'ALL', currencyName: 'Albanian lek' },
  { currencyCode: 'AMD', currencyName: 'Armenian dram' },
  { currencyCode: 'ANG', currencyName: 'Netherlands Antillean guilder' },
  { currencyCode: 'AOA', currencyName: 'Angolan kwanza' },
  { currencyCode: 'ARS', currencyName: 'Argentine peso' },
  { currencyCode: 'AUD', currencyName: 'Australian dollar' },
  { currencyCode: 'AWG', currencyName: 'Aruban florin' },
  { currencyCode: 'AZN', currencyName: 'Azerbaijani manat' },
  {
    currencyCode: 'BAM',
    currencyName: 'Bosnia and Herzegovina convertible mark',
  },
  { currencyCode: 'BBD', currencyName: 'Barbados dollar' },
  { currencyCode: 'BDT', currencyName: 'Bangladeshi taka' },
  { currencyCode: 'BGN', currencyName: 'Bulgarian lev' },
  { currencyCode: 'BHD', currencyName: 'Bahraini dinar' },
  { currencyCode: 'BIF', currencyName: 'Burundian franc' },
  { currencyCode: 'BMD', currencyName: 'Bermudian dollar' },
  { currencyCode: 'BND', currencyName: 'Brunei dollar' },
  { currencyCode: 'BOB', currencyName: 'Boliviano' },
  { currencyCode: 'BOV', currencyName: 'Bolivian Mvdol (funds code)' },
  { currencyCode: 'BRL', currencyName: 'Brazilian real' },
  { currencyCode: 'BSD', currencyName: 'Bahamian dollar' },
  { currencyCode: 'BTN', currencyName: 'Bhutanese ngultrum' },
  { currencyCode: 'BWP', currencyName: 'Botswana pula' },
  { currencyCode: 'BYN', currencyName: 'Belarusian ruble' },
  { currencyCode: 'BZD', currencyName: 'Belize dollar' },
  { currencyCode: 'CAD', currencyName: 'Canadian dollar' },
  { currencyCode: 'CDF', currencyName: 'Congolese franc' },
  { currencyCode: 'CHE', currencyName: 'WIR euro (complementary currency)' },
  { currencyCode: 'CHF', currencyName: 'Swiss franc' },
  { currencyCode: 'CHW', currencyName: 'WIR franc (complementary currency)' },
  { currencyCode: 'CLF', currencyName: 'Unidad de Fomento (funds code)' },
  { currencyCode: 'CLP', currencyName: 'Chilean peso' },
  { currencyCode: 'CNY', currencyName: 'Renminbi[6]' },
  { currencyCode: 'COP', currencyName: 'Colombian peso' },
  {
    currencyCode: 'COU',
    currencyName: 'Unidad de Valor Real (UVR) (funds code)[7]',
  },
  { currencyCode: 'CRC', currencyName: 'Costa Rican colon' },
  { currencyCode: 'CUP', currencyName: 'Cuban peso' },
  { currencyCode: 'CVE', currencyName: 'Cape Verdean escudo' },
  { currencyCode: 'CZK', currencyName: 'Czech koruna' },
  { currencyCode: 'DJF', currencyName: 'Djiboutian franc' },
  { currencyCode: 'DKK', currencyName: 'Danish krone' },
  { currencyCode: 'DOP', currencyName: 'Dominican peso' },
  { currencyCode: 'DZD', currencyName: 'Algerian dinar' },
  { currencyCode: 'EGP', currencyName: 'Egyptian pound' },
  { currencyCode: 'ERN', currencyName: 'Eritrean nakfa' },
  { currencyCode: 'ETB', currencyName: 'Ethiopian birr' },
  { currencyCode: 'EUR', currencyName: 'Euro' },
  { currencyCode: 'FJD', currencyName: 'Fiji dollar' },
  { currencyCode: 'FKP', currencyName: 'Falkland Islands pound' },
  { currencyCode: 'GBP', currencyName: 'Pound sterling' },
  { currencyCode: 'GEL', currencyName: 'Georgian lari' },
  { currencyCode: 'GHS', currencyName: 'Ghanaian cedi' },
  { currencyCode: 'GIP', currencyName: 'Gibraltar pound' },
  { currencyCode: 'GMD', currencyName: 'Gambian dalasi' },
  { currencyCode: 'GNF', currencyName: 'Guinean franc' },
  { currencyCode: 'GTQ', currencyName: 'Guatemalan quetzal' },
  { currencyCode: 'GYD', currencyName: 'Guyanese dollar' },
  { currencyCode: 'HKD', currencyName: 'Hong Kong dollar' },
  { currencyCode: 'HNL', currencyName: 'Honduran lempira' },
  { currencyCode: 'HTG', currencyName: 'Haitian gourde' },
  { currencyCode: 'HUF', currencyName: 'Hungarian forint' },
  { currencyCode: 'IDR', currencyName: 'Indonesian rupiah' },
  { currencyCode: 'ILS', currencyName: 'Israeli new shekel' },
  { currencyCode: 'INR', currencyName: 'Indian rupee' },
  { currencyCode: 'IQD', currencyName: 'Iraqi dinar' },
  { currencyCode: 'IRR', currencyName: 'Iranian rial' },
  { currencyCode: 'ISK', currencyName: 'Icelandic króna (plural: krónur)' },
  { currencyCode: 'JMD', currencyName: 'Jamaican dollar' },
  { currencyCode: 'JOD', currencyName: 'Jordanian dinar' },
  { currencyCode: 'JPY', currencyName: 'Japanese yen' },
  { currencyCode: 'KES', currencyName: 'Kenyan shilling' },
  { currencyCode: 'KGS', currencyName: 'Kyrgyzstani som' },
  { currencyCode: 'KHR', currencyName: 'Cambodian riel' },
  { currencyCode: 'KMF', currencyName: 'Comoro franc' },
  { currencyCode: 'KPW', currencyName: 'North Korean won' },
  { currencyCode: 'KRW', currencyName: 'South Korean won' },
  { currencyCode: 'KWD', currencyName: 'Kuwaiti dinar' },
  { currencyCode: 'KYD', currencyName: 'Cayman Islands dollar' },
  { currencyCode: 'KZT', currencyName: 'Kazakhstani tenge' },
  { currencyCode: 'LAK', currencyName: 'Lao kip' },
  { currencyCode: 'LBP', currencyName: 'Lebanese pound' },
  { currencyCode: 'LKR', currencyName: 'Sri Lankan rupee' },
  { currencyCode: 'LRD', currencyName: 'Liberian dollar' },
  { currencyCode: 'LSL', currencyName: 'Lesotho loti' },
  { currencyCode: 'LYD', currencyName: 'Libyan dinar' },
  { currencyCode: 'MAD', currencyName: 'Moroccan dirham' },
  { currencyCode: 'MDL', currencyName: 'Moldovan leu' },
  { currencyCode: 'MGA', currencyName: 'Malagasy ariary' },
  { currencyCode: 'MKD', currencyName: 'Macedonian denar' },
  { currencyCode: 'MMK', currencyName: 'Myanmar kyat' },
  { currencyCode: 'MNT', currencyName: 'Mongolian tögrög' },
  { currencyCode: 'MOP', currencyName: 'Macanese pataca' },
  { currencyCode: 'MRU', currencyName: 'Mauritanian ouguiya' },
  { currencyCode: 'MUR', currencyName: 'Mauritian rupee' },
  { currencyCode: 'MVR', currencyName: 'Maldivian rufiyaa' },
  { currencyCode: 'MWK', currencyName: 'Malawian kwacha' },
  { currencyCode: 'MXN', currencyName: 'Mexican peso' },
  {
    currencyCode: 'MXV',
    currencyName: 'Mexican Unidad de Inversion (UDI) (funds code)',
  },
  { currencyCode: 'MYR', currencyName: 'Malaysian ringgit' },
  { currencyCode: 'MZN', currencyName: 'Mozambican metical' },
  { currencyCode: 'NAD', currencyName: 'Namibian dollar' },
  { currencyCode: 'NGN', currencyName: 'Nigerian naira' },
  { currencyCode: 'NIO', currencyName: 'Nicaraguan córdoba' },
  { currencyCode: 'NOK', currencyName: 'Norwegian krone' },
  { currencyCode: 'NPR', currencyName: 'Nepalese rupee' },
  { currencyCode: 'NZD', currencyName: 'New Zealand dollar' },
  { currencyCode: 'OMR', currencyName: 'Omani rial' },
  { currencyCode: 'PAB', currencyName: 'Panamanian balboa' },
  { currencyCode: 'PEN', currencyName: 'Peruvian sol' },
  { currencyCode: 'PGK', currencyName: 'Papua New Guinean kina' },
  { currencyCode: 'PHP', currencyName: 'Philippine peso[11]' },
  { currencyCode: 'PKR', currencyName: 'Pakistani rupee' },
  { currencyCode: 'PLN', currencyName: 'Polish złoty' },
  { currencyCode: 'PYG', currencyName: 'Paraguayan guaraní' },
  { currencyCode: 'QAR', currencyName: 'Qatari riyal' },
  { currencyCode: 'RON', currencyName: 'Romanian leu' },
  { currencyCode: 'RSD', currencyName: 'Serbian dinar' },
  { currencyCode: 'RUB', currencyName: 'Russian ruble' },
  { currencyCode: 'RWF', currencyName: 'Rwandan franc' },
  { currencyCode: 'SAR', currencyName: 'Saudi riyal' },
  { currencyCode: 'SBD', currencyName: 'Solomon Islands dollar' },
  { currencyCode: 'SCR', currencyName: 'Seychelles rupee' },
  { currencyCode: 'SDG', currencyName: 'Sudanese pound' },
  { currencyCode: 'SEK', currencyName: 'Swedish krona (plural: kronor)' },
  { currencyCode: 'SGD', currencyName: 'Singapore dollar' },
  { currencyCode: 'SHP', currencyName: 'Saint Helena pound' },
  {
    currencyCode: 'SLE',
    currencyName: 'Sierra Leonean leone (new leone)[12][13][14]',
  },
  { currencyCode: 'SOS', currencyName: 'Somalian shilling' },
  { currencyCode: 'SRD', currencyName: 'Surinamese dollar' },
  { currencyCode: 'SSP', currencyName: 'South Sudanese pound' },
  { currencyCode: 'STN', currencyName: 'São Tomé and Príncipe dobra' },
  { currencyCode: 'SVC', currencyName: 'Salvadoran colón' },
  { currencyCode: 'SYP', currencyName: 'Syrian pound' },
  { currencyCode: 'SZL', currencyName: 'Swazi lilangeni' },
  { currencyCode: 'THB', currencyName: 'Thai baht' },
  { currencyCode: 'TJS', currencyName: 'Tajikistani somoni' },
  { currencyCode: 'TMT', currencyName: 'Turkmenistan manat' },
  { currencyCode: 'TND', currencyName: 'Tunisian dinar' },
  { currencyCode: 'TOP', currencyName: 'Tongan paʻanga' },
  { currencyCode: 'TRY', currencyName: 'Turkish lira' },
  { currencyCode: 'TTD', currencyName: 'Trinidad and Tobago dollar' },
  { currencyCode: 'TWD', currencyName: 'New Taiwan dollar' },
  { currencyCode: 'TZS', currencyName: 'Tanzanian shilling' },
  { currencyCode: 'UAH', currencyName: 'Ukrainian hryvnia' },
  { currencyCode: 'UGX', currencyName: 'Ugandan shilling' },
  { currencyCode: 'USD', currencyName: 'United States dollar' },
  {
    currencyCode: 'USN',
    currencyName: 'United States dollar (next day) (funds code)',
  },
  {
    currencyCode: 'UYI',
    currencyName: 'Uruguay Peso en Unidades Indexadas (URUIURUI) (funds code)',
  },
  { currencyCode: 'UYU', currencyName: 'Uruguayan peso' },
  { currencyCode: 'UYW', currencyName: 'Unidad previsional[16]' },
  { currencyCode: 'UZS', currencyName: 'Uzbekistani sum' },
  { currencyCode: 'VED', currencyName: 'Venezuelan digital bolívar[17]' },
  { currencyCode: 'VES', currencyName: 'Venezuelan sovereign bolívar[11]' },
  { currencyCode: 'VND', currencyName: 'Vietnamese đồng' },
  { currencyCode: 'VUV', currencyName: 'Vanuatu vatu' },
  { currencyCode: 'WST', currencyName: 'Samoan tala' },
  { currencyCode: 'XAF', currencyName: 'CFA franc BEAC' },
  { currencyCode: 'XAG', currencyName: 'Silver (one troy ounce)' },
  { currencyCode: 'XAU', currencyName: 'Gold (one troy ounce)' },
  {
    currencyCode: 'XBA',
    currencyName: 'European Composite Unit (EURCO) (bond market unit)',
  },
  {
    currencyCode: 'XBB',
    currencyName: 'European Monetary Unit (E.M.U.-6) (bond market unit)',
  },
  {
    currencyCode: 'XBC',
    currencyName: 'European Unit of Account 9 (E.U.A.-9) (bond market unit)',
  },
  {
    currencyCode: 'XBD',
    currencyName: 'European Unit of Account 17 (E.U.A.-17) (bond market unit)',
  },
  { currencyCode: 'XCD', currencyName: 'East Caribbean dollar' },
  { currencyCode: 'XDR', currencyName: 'Special drawing rights' },
  { currencyCode: 'XOF', currencyName: 'CFA franc BCEAO' },
  { currencyCode: 'XPD', currencyName: 'Palladium (one troy ounce)' },
  { currencyCode: 'XPF', currencyName: 'CFP franc (franc Pacifique)' },
  { currencyCode: 'XPT', currencyName: 'Platinum (one troy ounce)' },
  { currencyCode: 'XSU', currencyName: 'SUCRE' },
  { currencyCode: 'XTS', currencyName: 'Code reserved for testing' },
  { currencyCode: 'XUA', currencyName: 'ADB Unit of Account' },
  { currencyCode: 'XXX', currencyName: 'No currency' },
  { currencyCode: 'YER', currencyName: 'Yemeni rial' },
  { currencyCode: 'ZAR', currencyName: 'South African rand' },
  { currencyCode: 'ZMW', currencyName: 'Zambian kwacha' },
  { currencyCode: 'ZWG', currencyName: 'Zimbabwe Gold' },
];

export const currencyCodes: readonly CurrencyCode[] = _currencyCodes;
