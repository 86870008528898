<gax-side-panel-dialog>
  <gax-side-panel-dialog-title>
    {{ data.title }}
  </gax-side-panel-dialog-title>
  <gax-side-panel-dialog-content>
    @if (data.template) {
      <ng-container *ngTemplateOutlet="data.template"></ng-container>
    } @else if (data.contentHtml) {
      <div [innerHtml]="data.contentHtml"></div>
    }
  </gax-side-panel-dialog-content>
</gax-side-panel-dialog>
