import { ISO_3166_COUNTRIES } from 'gain-lib/geography';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CountryNameService {
  findCountryName(
    countryCode: string,
    includeCode: boolean = false,
  ): string | undefined {
    const isoCountry = ISO_3166_COUNTRIES.find((c) => c.iso2 === countryCode);
    if (isoCountry != null) {
      return includeCode
        ? `${isoCountry.en} (${isoCountry.iso2})`
        : isoCountry.en;
    }
    return undefined;
  }
}
