import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog-modal',
  templateUrl: './confirm-dialog-modal.component.html',
  styleUrls: ['./confirm-dialog-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogModalComponent {
  public popupHeaderText: string = 'Confirm Save';
  public popupMessageText: string = 'Message';
  public popupConfirmText: string = 'Confirm';
  public popupCancelText: string = 'Cancel';

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      headerText: string;
      messageText: string;
      confirmText: string;
      cancelText: string;
    },
  ) {
    this.popupHeaderText = data.headerText;
    this.popupMessageText = data.messageText;
    this.popupConfirmText = data.confirmText;
    this.popupCancelText = data.cancelText;
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }
}
