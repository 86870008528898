import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-select-trigger',
  templateUrl: './select-trigger.component.html',
  styleUrls: ['./select-trigger.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition(':enter', [animate('300ms linear')]),
    ]),
  ],
})
export class GaSelectTriggerComponent {
  @ViewChild(TemplateRef, { static: true })
  template!: TemplateRef<any>;
}
