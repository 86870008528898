@if (summary()) {
  {{ summary() }}
} @else if (countryCodes().length === 0) {
  <span title="0 countries">&mdash;</span>
} @else {
  @for (cc of countryCodes(); track cc; let last = $last) {
    <gax-country [countryCode]="cc" [format]="format()" /><ng-container
      *ngIf="!last"
      >,
    </ng-container>
  }
}
