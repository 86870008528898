<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="cell-content" #editValue [class.edited]="edited">
  @if (!!editing) {
    @if (valueType() === "string") {
      <input
        (keydown.escape)="exitEditMode()"
        (keydown.enter)="updateValue(editValueInput.value, $event)"
        #editValueInput
        class="cell-input"
        (input)="onInputChange()"
        [value]="userEditableValue"
        [min]="min()"
        [max]="max()"
      />
      <div class="cell-edit-icons">
        <mat-icon
          class="cursor-pointer"
          [matTooltip]="isClean ? '' : 'Commit changes'"
          [class.clean]="isClean"
          (click)="updateValue(editValueInput.value, $event)"
          >check
        </mat-icon>
        <mat-icon
          matTooltip="Cancel changes"
          (click)="exitEditMode()"
          class="cursor-pointer"
          >close
        </mat-icon>
      </div>
    } @else {
      <input
        type="number"
        (keydown.escape)="exitEditMode()"
        (keydown.enter)="updateValue(editValueInput.value, $event)"
        #editValueInput
        class="cell-input"
        [value]="userEditableValue"
      />
      <div class="cell-edit-icons">
        <mat-icon
          class="cursor-pointer"
          matTooltip="Save changes"
          (click)="updateValue(editValueInput.value, $event)"
          >check
        </mat-icon>

        <mat-icon
          matTooltip="Cancel changes"
          (click)="exitEditMode()"
          class="cursor-pointer"
          >close
        </mat-icon>
      </div>
    }
  } @else {
    <div
      [ngClass]="{
        'cell-display-value': true,
        'has-custom-icon': hasCustomIcon,
      }"
      (click)="enterEditMode(editValue)"
    >
      <span>
        <ng-content></ng-content>
      </span>
      <!-- if edited, show recycle icon, else show edit icon -->
      <mat-icon class="edit-icon">edit</mat-icon>
      <mat-icon class="custom-icon" *ngIf="icon">{{ icon() }}</mat-icon>
    </div>
  }
</div>
