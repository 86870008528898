import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import BigNumber from 'bignumber.js';
import { DocumentEventService } from 'gain-lib/services/documentClickDetectionService.service';
import { Subscription } from 'rxjs';

export type ValueType = 'percent' | 'money' | 'number' | 'string';

@Component({
  selector: 'gax-table-cell-value-edit-new',
  templateUrl: './table-cell-value-edit-new.component.html',
  styleUrl: './table-cell-value-edit-new.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, MatTooltipModule],
})
export class TableCellValueEditNewComponent implements OnInit, OnDestroy {
  @Input()
  valueType: ValueType = 'string';

  @Input()
  toolTipText: string = 'Click to edit';

  @Input()
  value: string | number = this.valueType === 'string' ? '' : 0;

  @Input()
  currencyCode?: string;

  @Input()
  displayValue?: string | number | null;

  @Input()
  disableRevert: boolean = false;

  // number min
  @Input()
  min?: number;

  // number max
  @Input()
  max?: number;

  @HostBinding('class.edited')
  edited?: boolean | undefined | null;

  @Input()
  differentFromPrevious: boolean = false;

  @Input() revertable: boolean = false;
  @Input() enableEditedIcon: boolean = false;

  @Input()
  previouslyEditedValue?: string;

  @Input()
  percentageFormat: string = '1.0-4';

  @Input()
  defaultValue?: string | number;

  @Input()
  originalValue?: string | number;

  previousValue!: string | number;

  @Input()
  icon: string = 'rotate_right';

  get userEditableValue() {
    if (this.valueType === 'money') {
      // return this.valueInSelectedCurrency;
      return this.value;
    } else if (this.valueType === 'percent') {
      return new BigNumber(this.value).times(100).toNumber();
    }
    return this.value;
  }

  @Output()
  valueChange = new EventEmitter<number | string>();

  @HostBinding('class.editing')
  editing: boolean = false;

  @HostBinding('class.clean')
  isClean: boolean = true;

  documentClickSubscription!: Subscription;

  constructor(
    private elRef: ElementRef,
    private documentEventService: DocumentEventService,
    private cd: ChangeDetectorRef,
  ) {
    // Listen for document click
    this.documentClickSubscription = this.documentEventService.click$.subscribe(
      (event) => {
        this.documentClickListener(event.target as HTMLElement);
      },
    );
  }
  ngOnDestroy(): void {
    this.documentClickSubscription?.unsubscribe();
  }

  /**
   * Handles the click event outside of the component element.
   * If the target is inside the component element, it does nothing.
   * If the target is outside the component element, it calls the `exitEditMode` method.
   * @param target - The target element that triggered the click event.
   */
  documentClickListener(target: HTMLElement | undefined): void {
    if (target && this.elRef.nativeElement.contains(target)) {
      // console.log('Clicked inside');
    } else {
      // Clicked outside
      this.exitEditMode();
    }
  }

  checkDefaultDifference() {
    if (this.displayValueNew) {
      if (this.defaultValue && this.displayValueNew !== this.defaultValue) {
        this.differentFromPrevious = true;
      } else {
        this.differentFromPrevious = false;
      }
    }
  }

  ngOnInit() {
    this.checkDefaultDifference();
    this.previousValue = this.value;
  }

  onInputChange() {
    this.isClean = false;
  }

  enterEditMode(element: HTMLDivElement) {
    this.editing = true;
    setTimeout(() => {
      const inputBox = element.querySelector('input');
      if (inputBox) inputBox.focus();
      this.cd.detectChanges();
      this.cd.markForCheck();
    }, 100);
  }

  exitEditMode() {
    this.editing = false;
    this.cd.detectChanges();
    this.cd.markForCheck();
  }

  // Update row with new value amount
  updateValue(newValue: string, event: Event) {
    //This stops the expansion panel from opening after the parentRow is edited
    event.stopPropagation();
    if (!newValue && this.valueType === 'number') return;

    if (newValue && this.valueType === 'number') {
      const parsedOverrideValue = parseFloat(parseFloat(newValue).toFixed(2));
      if (parsedOverrideValue > 100 || parsedOverrideValue < 0) {
        this.exitEditMode();
        return;
      }
    }
    // if value is the same as before, do nothing
    if (newValue === this.value) {
      return;
    }

    if (this.valueType === 'string') {
      this.value = newValue;
      this.edited = true;
      this.valueChange.emit(this.value);
      this.exitEditMode();
      this.checkDefaultDifference();
      return;
    }

    const numberValue = parseFloat(newValue);
    const isNegative = Math.sign(numberValue) === -1 ? true : false;
    if (isNegative) return;

    if (this.valueType === 'percent') {
      if (numberValue > 100) {
        return;
      }
      this.value = new BigNumber(numberValue).dividedBy(100).toNumber();
    } else if (this.valueType === 'money') {
      this.value = numberValue;
    } else {
      this.value = numberValue;
    }

    this.edited = true;
    this.valueChange.emit(this.value);
    this.exitEditMode();
    this.checkDefaultDifference();
  }

  revertValue(event: Event) {
    event.stopPropagation();

    this.value = this.previousValue;

    this.edited = false;
    this.valueChange.emit(this.value);
    this.exitEditMode();
    this.isClean = true;
    this.checkDefaultDifference();
  }

  get displayValueNew() {
    return this.value ?? this.previouslyEditedValue ?? this.defaultValue;
  }
}
