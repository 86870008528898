<div class="container">
  <div class="title">
    <h2>{{ dialogTitle }}</h2>
  </div>
  <mat-dialog-content>
    <div class="region-lists">
      <div class="left-side">
        <div class="search-bar">
          <mat-icon matPrefix>search</mat-icon>
          <input
            matInput
            placeholder="Enter region name"
            [formControl]="searchCtrl"
          />
          <button matSuffix mat-stroked-button (click)="searchCtrl.reset()">
            clear
          </button>
        </div>
        <mat-checkbox
          class="select-all-regions"
          (change)="toggleAll($event)"
          [checked]="areAllRegionsSelected"
        >
          Select all regions
        </mat-checkbox>
        <div class="region-selection">
          <div class="country">
            <mat-checkbox
              (change)="toggleAll($event)"
              [checked]="areAllRegionsSelected"
              [indeterminate]="isCountryIndeterminate"
              ><strong>
                {{ country.name }}
                ({{ regions.length }})
              </strong>
            </mat-checkbox>
          </div>
          <section>
            <div class="regions">
              <ng-container *ngFor="let region of regions">
                <mat-checkbox
                  *ngIf="showRegion(region)"
                  (change)="toggleRegion(region, $event)"
                  [checked]="isRegionChecked(region)"
                  [disabled]="isRegionReadonly(region)"
                  [class.readOnly]="isRegionReadonly(region)"
                  (click)="onRegionCheckboxClick($event, region)"
                  [title]="getRegionTooltip(region)"
                >
                  {{ region.name }}
                </mat-checkbox>
              </ng-container>
            </div>
          </section>
        </div>
      </div>
      <section class="your-selections">
        <h4>Your selection ({{ selectedRegionsSortedByName.length }})</h4>
        <ng-container *ngFor="let region of selectedRegionsSortedByName">
          <mat-checkbox
            *ngIf="showRegion(region)"
            (change)="toggleRegion(region, $event)"
            [checked]="isRegionChecked(region)"
            [class.readOnly]="isRegionReadonly(region)"
            (click)="onRegionCheckboxClick($event, region)"
            [title]="getRegionTooltip(region)"
            >{{ region.name }}
          </mat-checkbox>
        </ng-container>
      </section>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button color="secondary" mat-button (click)="close()">Close</button>
    <button color="primary" mat-button (click)="select()">
      Select ({{ selectedRegionsSortedByName.length }}) regions
    </button>
  </mat-dialog-actions>
</div>
