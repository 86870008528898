import { inject, Pipe, PipeTransform } from '@angular/core';
import { CountryNameService } from 'gain-lib/geography';

@Pipe({ name: 'countryName' })
export class CountryNamePipe implements PipeTransform {
  private _countryName = inject(CountryNameService);

  transform(
    countryCode: string | null | undefined,
    includeCode: boolean = false,
  ): string | null | undefined {
    return countryCode != null
      ? this._countryName.findCountryName(countryCode, includeCode)
      : null;
  }
}
