<mat-toolbar class="main-list">
  <ng-container *ngIf="!backButton">
    <div
      class="default-text"
      *ngFor="let breadcrumb of breadcrumbs; let i = index; let last = last"
    >
      <mat-label>
        <ng-container *ngIf="breadcrumb.path; else noLink">
          <a [routerLink]="'/' + breadcrumb.path" class="breadCrumb-link">
            {{ breadcrumb.title }}
          </a>
        </ng-container>
        <ng-template #noLink>{{ breadcrumb.title }}</ng-template>
      </mat-label>
      <mat-icon *ngIf="!last">chevron_right</mat-icon>
    </div>
  </ng-container>
  <ng-container *ngIf="backButton">
    <div
      class="default-text"
      *ngFor="let breadcrumb of breadcrumbs; let i = index; let last = last"
    >
      <mat-icon *ngIf="last">chevron_left</mat-icon>
      <mat-label *ngIf="last">
        <ng-container *ngIf="breadcrumb.path; else noLink">
          <a [routerLink]="'/' + breadcrumb.path" class="breadCrumb-link">
            {{ breadcrumb.title }}
          </a>
        </ng-container>
        <ng-template #noLink>{{ breadcrumb.title }}</ng-template>
      </mat-label>
    </div>
  </ng-container>
</mat-toolbar>
