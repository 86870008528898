import { Injectable, signal } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import BigNumber from 'bignumber.js';
import { AsyncRequestHelper } from 'gain-lib/ga-async-request/async-request-helper';
import { ApiClient } from 'gain-web/shared-services/api-client.generated.service';
import { BehaviorSubject } from 'rxjs';

export type ConvertCurrencyRequest = {
  fromCurrency: string;
  toCurrency: string;
  amount: number;
};
export type ConvertCurrencyResult = {
  amount: number;
  exchangeRate: number;
} | null;

export interface ExchangeRate {
  pair: string;
  rate: number;
  source: string;
}

@Injectable()
export class CurrencyConversionContext {
  private readonly _selectedCurrency$ = new BehaviorSubject<string | null>(
    null,
  );
  private readonly _currentTransactionGuid$ = new BehaviorSubject<
    string | null
  >(null);

  private _exchangeRateUsedDate?: Date | null;
  private readonly _exchangeRates = new Map<string, ExchangeRate>();
  private readonly _registeredCurrencies = new Set<string>();
  private _fetchExchangeRates: AsyncRequestHelper<() => Promise<void>>;

  get getExchangeRateRequestProcess() {
    return this._fetchExchangeRates.process;
  }

  get selectedCurrency$() {
    return this._selectedCurrency$.asObservable();
  }

  readonly getSelectedCurrency = signal<string | null>(null);

  constructor(
    private _currencyConversionContextService: ApiClient.CurrencyConversionContextService,
    private _snack: MatSnackBar,
  ) {
    this._fetchExchangeRates = new AsyncRequestHelper<() => Promise<void>>(
      (req) => req(),
    );
    this._selectedCurrency$.subscribe((c) => {
      this.getSelectedCurrency.set(c);
    });
  }

  initialize(tr: ApiClient.ITransactionResult) {
    this.registerCurrency(tr.currencyCode);

    for (const ct of tr.countryTotals) {
      this.registerCurrency(ct.currencyCode);
    }

    for (const lpr of tr.locationPeriodResults) {
      for (const cr of lpr.countryResults) {
        this.registerCurrency(cr.currencyCode);
      }
    }

    for (const r of tr.exchangeRatesUsed) {
      this._exchangeRates.set(r.currencyPair, {
        pair: r.currencyPair,
        rate: r.rate,
        source: `Transaction result (${r.exchangeRateTypeUsed})`,
      });
    }

    this._exchangeRateUsedDate = tr.exchangeRateUsedDate;
  }

  registerCurrency(code: string) {
    this._registeredCurrencies.add(code);
  }

  private _getExchangeRate(
    fromCurrency: string,
    toCurrency: string,
    exchangeRateUsedDate: Date,
  ): Promise<ApiClient.IGetExchangeRateByDateQueryResultSuccess> {
    return this._currencyConversionContextService
      .getExchangeRateByDate(fromCurrency, toCurrency, exchangeRateUsedDate)
      .toPromise();
  }

  private async _fetchMissingExchangeRates(currencyCode: string) {
    const missingExchangeRates = [
      ...[...this._registeredCurrencies].map((c) => `${c}/${currencyCode}`),
    ].filter((rate) => !this._exchangeRates.has(rate));
    if (missingExchangeRates.length > 0) {
      if (this._exchangeRateUsedDate == null) {
        this._snack.open(
          `Exchange rate used date not provided for transaction result. Cannot perform currency conversions: ${missingExchangeRates.join(
            ', ',
          )}.`,
          'OK',
        );
        return;
      } else {
        await this._fetchExchangeRates
          .execute(async () => {
            try {
              const erNotice = this._snack.open(
                `Requesting exchange rates: ${missingExchangeRates.join(
                  ', ',
                )} `,
              );

              const fetchTasks = missingExchangeRates.map(
                async (conversionPair) => {
                  let rate =
                    conversionPair === `${currencyCode}/${currencyCode}`
                      ? {
                          rate: 1,
                          source: `Assuming rate of 1 for ${conversionPair}`,
                          pair: conversionPair,
                        }
                      : null;
                  if (rate == null) {
                    const from = conversionPair.split('/')[0];
                    const to = conversionPair.split('/')[1];
                    const result = await this._getExchangeRate(
                      from,
                      to,
                      this._exchangeRateUsedDate!,
                    );
                    rate =
                      result.rate != null
                        ? {
                            rate: result.rate,
                            source: 'Exchange Rate Service',
                            pair: conversionPair,
                          }
                        : null;
                  }
                  if (rate != null) {
                    this._exchangeRates.set(conversionPair, rate);
                  }
                },
              );
              await Promise.all(fetchTasks);

              erNotice.dismiss();
            } catch {
              this._snack.open('Error requesting exchange rates', '', {
                duration: 5000,
              });
            }
          })
          .toPromise();
      }
    }
  }

  async selectCurrency(currencyCode: string) {
    this._selectedCurrency$.next(currencyCode);
    await this._fetchMissingExchangeRates(currencyCode).then(() => {
      this._selectedCurrency$.next(currencyCode);
    });
  }

  async updateTransactionGuid(tr: ApiClient.ITransactionResult) {
    if (tr.transactionGuid !== this._currentTransactionGuid$.getValue()) {
      await this.selectCurrency(tr.currencyCode);
    }

    this._currentTransactionGuid$.next(tr.transactionGuid);
  }

  getExchangeRate({
    fromCurrency,
    toCurrency,
  }: {
    fromCurrency: string;
    toCurrency: string;
  }): ExchangeRate {
    const er = this.findExchangeRate({ fromCurrency, toCurrency });
    if (er == null) {
      throw new Error(
        `CurrencyConversionContext: Unable to find exchange rate ${fromCurrency}/${toCurrency}`,
      );
    }
    return er;
  }

  findExchangeRate({
    fromCurrency,
    toCurrency,
  }: {
    fromCurrency: string;
    toCurrency: string;
  }): ExchangeRate | undefined {
    const requestedConversionPair = `${fromCurrency}/${toCurrency}`;
    const exchangeRate = this._exchangeRates.get(requestedConversionPair);
    if (exchangeRate != null) {
      return exchangeRate;
    }
    if (fromCurrency === toCurrency) {
      return {
        source: `Default for ${requestedConversionPair}`,
        pair: requestedConversionPair,
        rate: 1,
      };
    }
    return undefined;
  }

  canConvert({
    fromCurrency,
    toCurrency,
  }: {
    fromCurrency: string;
    toCurrency: string;
  }): boolean {
    return this.findExchangeRate({ fromCurrency, toCurrency }) != null;
  }

  convert({
    fromCurrency,
    toCurrency,
    amount,
  }: ConvertCurrencyRequest): ConvertCurrencyResult {
    const exchangeRate = this.findExchangeRate({ fromCurrency, toCurrency });
    if (exchangeRate == null) {
      return null;
    } else {
      return {
        amount: new BigNumber(exchangeRate.rate).times(amount).toNumber(),
        exchangeRate: exchangeRate.rate,
      };
    }
  }
}
