<ng-container *ngIf="view$ | async; let view">
  <span
    [ngClass]="{ converting: getExchangeRateRequestProcess.pending$ | async }"
  >
    <mat-icon [matTooltip]="view.tooltip" *ngIf="view.state === 'error'" inline
      >error</mat-icon
    >
    <gax-money
      [roundingBehavior]="roundingBehavior"
      [amount]="view.money.amount"
      [currencyCode]="view.money.currencyCode"
    ></gax-money>
  </span>
</ng-container>
