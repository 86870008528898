import {
  ChangeDetectionStrategy,
  Component,
  input,
  OnInit,
} from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroupDirective,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'gax-money-input',
  templateUrl: './money-input.component.html',
  styleUrls: ['./money-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class MoneyInputComponent implements OnInit {
  currencyCodeControl!: FormControl<string>;
  label = input<string | null | undefined>();
  currencyCodeRequiredErrorMessage = input<string>('Currency required');
  amountRequiredErrorMessage = input<string>('Amount required');
  currencyCodeHint = input<string | null | undefined>(null);
  currencyCodeFormControlName = input.required<string>();
  amountFormControlName = input.required<string>();
  amountHint = input<string | null | undefined>(null);

  constructor(private formGroupDirective: FormGroupDirective) {}

  ngOnInit() {
    this.currencyCodeControl = this.formGroupDirective.form.get(
      this.currencyCodeFormControlName(),
    ) as FormControl;

    const amountCtrl = this.formGroupDirective.form.get(
      this.amountFormControlName(),
    ) as FormControl;
    if (
      amountCtrl.hasValidator(Validators.required) &&
      !this.currencyCodeControl.hasValidator(Validators.required)
    ) {
      this.currencyCodeControl.addValidators(Validators.required);
    }
  }
}
