import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';

@Directive({
  selector: '[appLimitDecimals]',
})
export class LimitDecimalsDirective implements OnInit {
  private regex?: RegExp;
  private specialKeys = [
    'ArrowLeft',
    'ArrowRight',
    'Backspace',
    'Del',
    'Delete',
    'End',
    'Enter',
    'Home',
    'Tab',
  ];

  @Input()
  places?: number;

  constructor(private el: ElementRef<HTMLInputElement>) {}

  ngOnInit() {
    this.regex = new RegExp(`^\\d*\\.?\\d{0,${this.places || 2}}$`, 'g');
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.includes(event.key)) {
      return;
    }

    const current = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart ?? undefined;
    const next = [
      current.slice(0, position),
      event.key === 'Decimal' ? '.' : event.key,
      current.slice(position),
    ].join('');

    if (
      event.ctrlKey !== true &&
      next &&
      this.regex &&
      !String(next).match(this.regex)
    ) {
      event.preventDefault();
    }
  }
}
