import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
} from '@angular/core';
import { isNullishOrWhitespace } from 'gain-lib/string';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'gax-select-search',
  templateUrl: './select-search.component.html',
  styleUrl: './select-search.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectSearchComponent {
  private search$ = new Subject<string>();
  searchChange = output<string | null>();
  placeholder = input<string>('Search');

  constructor() {
    this.search$
      .pipe(
        map((v) => (isNullishOrWhitespace(v) ? null : v.trim())),
        distinctUntilChanged(),
        takeUntilDestroyed(),
      )
      .subscribe((searchInput) => {
        this.searchChange.emit(searchInput);
      });
  }

  protected emitSearchChange(event: KeyboardEvent) {
    const inputValue = (event.target as HTMLInputElement).value;
    this.search$.next(inputValue);
  }
}
