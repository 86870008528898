<button mat-icon-button [mat-dialog-close]="false">
  <mat-icon>close</mat-icon>
</button>
<img src="/ga-assets/illustrations/question_hires.png" alt="Unsaved Changes" />
<h1 mat-dialog-title>Delete</h1>
<div mat-dialog-content [innerHTML]="deleteText"></div>
<div mat-dialog-actions align="center">
  <button mat-button color="secondary" [mat-dialog-close]="false">
    CANCEL
  </button>
  <button mat-button color="primary" [mat-dialog-close]="true">
    YES, DELETE IT
  </button>
</div>
