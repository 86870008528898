import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ErrorComponent } from 'gain-lib/error';
import {
  CountryNameService,
  GaGeographyModule,
  RegionNameService,
} from 'gain-lib/geography';
import { GaHelpIconComponent } from 'gain-lib/help-icon/help-icon.component';
import { GASelectFormFieldModule } from 'gain-lib/select-form-field';
import { GainCountryNameService } from 'gain-web/shared-modules/geography/gain-country-name.service';
import { GainRegionNameService } from 'gain-web/shared-modules/geography/gain-region-name.service';
import { LocationFormComponent } from './location-form/location-form.component';

@NgModule({
  declarations: [LocationFormComponent],
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatIconModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    ReactiveFormsModule,
    TextFieldModule,
    GaGeographyModule,
    GaHelpIconComponent,
    GASelectFormFieldModule,
    ErrorComponent,
  ],
  providers: [
    { provide: CountryNameService, useClass: GainCountryNameService },
    {
      provide: RegionNameService,
      useClass: GainRegionNameService,
    },
  ],
  exports: [LocationFormComponent, GaGeographyModule],
})
export class GainGeographyModule {}
