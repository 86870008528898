import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ErrorComponent } from 'gain-lib/error';
import { IconComponent } from 'gain-lib/icon';
import { OptionComponent } from './option/option.component';
import { GaSelectTriggerComponent } from './select-trigger/select-trigger.component';
import { GaTableSelectFormFieldComponent } from './table-select-form-field.component';

@NgModule({
  declarations: [
    OptionComponent,
    GaTableSelectFormFieldComponent,
    GaSelectTriggerComponent,
  ],
  imports: [
    CommonModule,
    OverlayModule,
    MatIconModule,
    ErrorComponent,
    MatProgressSpinnerModule,
    MatIconModule,
    MatChipsModule,
    IconComponent,
  ],
  exports: [
    OptionComponent,
    GaTableSelectFormFieldComponent,
    GaSelectTriggerComponent,
  ],
})
export class GATableSelectFormFieldModule {}
