import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InformationSidePanelModalComponent } from './information-side-panel-modal/information-side-panel-modal.component';
import { GaSidePanelModalModule } from 'gain-lib/dialog/dialog.module';
import { InformationSidePanelModalService } from 'gain-lib/information-side-panel-modal/information-side-panel-modal.service';

@NgModule({
  declarations: [InformationSidePanelModalComponent],
  imports: [CommonModule, GaSidePanelModalModule],
  providers: [InformationSidePanelModalService],
})
export class InformationSidePanelModalModule {}
