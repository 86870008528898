import { capitalize, kebabCase as _kebabCase, startCase } from 'lodash-es';

export function kebabCase(value: string): string {
  return _kebabCase(value);
}

export function titleCase(value: string): string {
  return capitalize(value);
}

export function sentenceCase(value: string): string {
  return startCase(value);
}
