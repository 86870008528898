import { Injectable } from '@angular/core';
import { CountryRegionDataService } from 'gain-web/shared-modules/country-region-data/country-region-data.service';
import { CurrencyNameService } from 'gain-lib/money';

@Injectable({ providedIn: 'root' })
export class GainCurrencyNameService extends CurrencyNameService {
  constructor(private _countryDataService: CountryRegionDataService) {
    super();
  }

  override findCurrencyName(currencyCode: string): string | undefined {
    return this._countryDataService.findCountryByCurrencyCode(currencyCode)
      ?.currency?.name;
  }
}
