import { Injectable } from '@angular/core';
import { CountryRegionDataService } from 'gain-web/shared-modules/country-region-data/country-region-data.service';
import { CountryNameService } from 'gain-lib/geography';

@Injectable()
export class GainCountryNameService extends CountryNameService {
  constructor(private _countryData: CountryRegionDataService) {
    super();
  }

  override findCountryName(
    countryCode: string,
    includeCode: boolean,
  ): string | undefined {
    const country = this._countryData.findCountryByCode(countryCode);
    if (country != null) {
      return includeCode ? `${country.name} (${country.code})` : country.name;
    }
    return super.findCountryName(countryCode, includeCode);
  }
}
