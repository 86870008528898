import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class RegionNameService {
  findRegionName(
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      countryCode,
      regionCode,
    }: {
      countryCode: string[] | string;
      regionCode: string;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    includeCode: boolean = false,
  ): string | undefined {
    console.warn(
      `RegionNameService.findRegionName: this method is not fully implemented. It's recommended your application provide its own implementation of 'RegionNameService'.`,
    );
    return regionCode;
  }
}
