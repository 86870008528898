<div class="side-panel-header">
  <button class="side-panel-close" mat-icon-button>
    <mat-icon mat-dialog-close>close</mat-icon>
  </button>
  <ng-content select="gax-side-panel-dialog-title"></ng-content>

  <mat-divider></mat-divider>
</div>
<div class="side-panel-content-container">
  <ng-content select="gax-side-panel-dialog-content"></ng-content>
</div>
<ng-content select="gax-side-panel-dialog-actions"></ng-content>
