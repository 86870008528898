<mat-form-field class="width-full">
  <input
    [formControlName]="amountFormControlName()"
    matInput
    type="text"
    placeholder="0.00"
    appLimitDecimals
    gaxMoneyFormatter
    [currencyCode]="currencyCode()"
    required
  />

  @if (amountControl.hasError("required")) {
    <mat-error>{{ requiredErrorMessage() }}</mat-error>
  } @else if (amountControl.hasError("min")) {
    <mat-error
      >Amount must be greater than {{ amountControl.errors?.min.min }}
    </mat-error>
  } @else if (amountControl.hasError("maxlength")) {
    <mat-error
      >Amount cannot be greater than
      {{ amountControl.errors?.maxlength?.requiredLength }} digits</mat-error
    >
  } @else if (amountControl.hasError("numbersOnly")) {
    <mat-error>{{ numericErrorMessage() }}</mat-error>
  }

  @if (hint()) {
    <mat-hint>{{ hint() }}</mat-hint>
  }
</mat-form-field>
