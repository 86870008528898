import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ErrorComponent } from 'gain-lib/error';

@Component({
  selector: 'gax-help-icon',
  template: ` <mat-icon>help</mat-icon> `,
  styles: [
    `
      mat-icon.mat-icon {
        color: #71c72a;
        transform: scale(0.5);
        vertical-align: bottom;
        margin-left: -6px;
        cursor: pointer;
      }
    `,
  ],

  standalone: true,
  imports: [CommonModule, MatIconModule, ErrorComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GaHelpIconComponent {}
