<div class="main-Container">
  <!-- Title section -->
  <ng-container *ngIf="backNavItem">
    <li
      [routerLink]="getRoutePath(backNavItem)"
      class="mat-tree-node mat-tree-node-custom highlight"
    >
      <div class="label-section">
        <div class="icon-left">
          <mat-icon>chevron_left</mat-icon>
        </div>
        <div class="label">{{ backNavItem.name }}</div>
      </div>
    </li>

    <!-- Blank section -->
    <div class="mat-tree-node mat-tree-node-custom">
      <div class="label-section"></div>
    </div>
  </ng-container>

  <mat-tree
    [dataSource]="dataSource"
    [treeControl]="treeControl"
    class="main-list-section"
  >
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node *matTreeNodeDef="let node; i as index" matTreeNodeToggle>
      <li
        #parent
        [routerLink]="getRoutePath(node)"
        [routerLinkActive]="highlightActiveLink(node)"
        [ngClass]="getRoutePath(node) === null ? 'noRoute' : ''"
        [routerLinkActiveOptions]="{ exact: true }"
        class="mat-tree-node mat-tree-node-custom"
      >
        <div
          class="label-section"
          [ngClass]="{ 'label-space': !node.iconStatus || !node.icon }"
        >
          <div *ngIf="node.iconStatus || node.icon" class="icon-left">
            <mat-icon *ngIf="node.iconStatus === 'complete'" class="completed"
              >check_circle
            </mat-icon>
            <mat-icon
              *ngIf="node.iconStatus === 'incomplete'"
              class="incomplete"
              >error
            </mat-icon>
            <mat-icon *ngIf="node.icon">
              {{ node.icon }}
            </mat-icon>
          </div>
          <div class="label">
            <a>{{ node.name }}</a>
          </div>
          <div *ngIf="node.showInfoIcon" class="icon-right">
            <mat-icon class="info">info</mat-icon>
          </div>
        </div>
      </li>
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
      <li
        [routerLink]="getRoutePath(node)"
        [routerLinkActive]="highlightActiveLink(node)"
        [routerLinkActiveOptions]="{ exact: true }"
        matTreeNodeToggle
      >
        <div class="mat-tree-node mat-tree-node-custom">
          <div
            class="label-section"
            [ngClass]="!node.iconStatus || !node.icon ? 'label-space' : ''"
          >
            <div *ngIf="node.iconStatus || node.icon" class="icon-left">
              <mat-icon *ngIf="node.iconStatus === 'complete'" class="completed"
                >check_circle
              </mat-icon>
              <mat-icon
                *ngIf="node.iconStatus === 'incomplete'"
                class="incomplete"
                >error
              </mat-icon>
              <mat-icon *ngIf="node.icon">
                {{ node.icon }}
              </mat-icon>
            </div>
            <div class="label">
              <a>{{ node.name }}</a>
            </div>
          </div>

          <!-- Right Icon Info/Arrow -->
          <div
            *ngIf="!node.showInfoIcon"
            [attr.aria-label]="'toggle ' + node.name"
            class="icon-right"
          >
            <gax-icon
              [class.expanded]="treeControl.isExpanded(node)"
              icon="expand_small"
            ></gax-icon>
          </div>

          <div *ngIf="node.showInfoIcon" class="icon-right">
            <mat-icon class="info">info</mat-icon>
          </div>
        </div>
        <ul
          [ngClass]="
            treeControl.isExpanded(node) ? 'expanded-node' : 'tree-invisible'
          "
        >
          <ng-container matTreeNodeOutlet></ng-container>
        </ul>
      </li>
    </mat-nested-tree-node>
  </mat-tree>
</div>
