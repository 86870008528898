<mat-toolbar>
  <div>
    <img
      class="deloitte-ga-logo"
      alt="Deloitte GlobalAdvantage"
      src="assets/icons/header-logo.svg"
    />
    <mat-divider vertical></mat-divider>
    <a class="home-link" routerLink="/">Incentives</a>
  </div>
  <div>
    <div class="link-icon">
      <a [href]="portalHomeUrl">
        <mat-icon>home</mat-icon>
      </a>
    </div>
    <div class="link-icon">
      <a [href]="portalHelpUrl">
        <mat-icon>help</mat-icon>
      </a>
    </div>
    <div
      class="link-icon link-dropdown"
      #userMenuTrigger="matMenuTrigger"
      [matMenuTriggerFor]="userMenu"
    >
      <mat-icon>person</mat-icon>
      <mat-icon class="person-icon--inline"
        >{{ userMenuTrigger.menuOpen ? "arrow_drop_up" : "arrow_drop_down" }}
      </mat-icon>
    </div>
    <mat-menu #userMenu="matMenu" xPosition="before">
      <button mat-menu-item (click)="handleLogout()">Log out</button>
    </mat-menu>
    <div
      class="apps-menu link-icon link-dropdown"
      [matMenuTriggerFor]="appsMenu"
    >
      Menu
      <mat-icon>apps</mat-icon>
    </div>

    <mat-menu #appsMenu="matMenu" xPosition="before" class="nav-dropdown">
      <div class="dropdown--inline">
        <ng-container *ngFor="let tile of menuItems">
          <ng-container
            *ngIf="
              tile.tileType === 'TOOL_GROUP' && tile.tiles!.length >= 2;
              else noSubmenu
            "
          >
            <button mat-menu-item [mat-menu-trigger-for]="subMenu">
              {{ tile.tileName }}
            </button>
            <mat-menu
              #subMenu="matMenu"
              xPosition="after"
              [overlapTrigger]="false"
            >
              <button
                mat-menu-item
                *ngFor="let submenuTile of tile?.tiles"
                (click)="menuTileClicked(submenuTile)"
              >
                {{ submenuTile.tileName }}
              </button>
            </mat-menu>
          </ng-container>
          <ng-template #noSubmenu>
            <button mat-menu-item (click)="menuTileClicked(tile)">
              {{ tile.tileName }}
            </button>
          </ng-template>
        </ng-container>
      </div>
    </mat-menu>
  </div>
</mat-toolbar>
