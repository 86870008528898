import { Injectable, TemplateRef } from '@angular/core';
import { SidePanelDialogService } from 'gain-lib/dialog/side-panel-dialog/side-panel-dialog.service';
import {
  InformationSidePanelModalComponent,
  InformationSidePanelModalDialogData,
} from 'gain-lib/information-side-panel-modal/information-side-panel-modal/information-side-panel-modal.component';

@Injectable()
export class InformationSidePanelModalService {
  constructor(private _sidePanel: SidePanelDialogService) {}

  open(
    params: { title: string } & (
      | { contentHtml: string }
      | { template: TemplateRef<unknown> }
    ),
  ) {
    const data: InformationSidePanelModalDialogData = params;
    return this._sidePanel.open(InformationSidePanelModalComponent, data);
  }
}
