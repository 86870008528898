export type ContinentCode = 'AF' | 'AS' | 'AN' | 'EU' | 'NA' | 'OC' | 'SA';
export type Country2DigitIsoCode =
  | `AF`
  | `AL`
  | `DZ`
  | `AS`
  | `AD`
  | `AO`
  | `AI`
  | `AQ`
  | `AG`
  | `AR`
  | `AM`
  | `AW`
  | `AU`
  | `AT`
  | `AZ`
  | `BS`
  | `BH`
  | `BD`
  | `BB`
  | `BY`
  | `BE`
  | `BZ`
  | `BJ`
  | `BM`
  | `BT`
  | `BO`
  | `BQ`
  | `BA`
  | `BW`
  | `BV`
  | `BR`
  | `IO`
  | `BN`
  | `BG`
  | `BF`
  | `BI`
  | `CV`
  | `KH`
  | `CM`
  | `CA`
  | `KY`
  | `CF`
  | `TD`
  | `CL`
  | `CN`
  | `CX`
  | `CC`
  | `CO`
  | `KM`
  | `CD`
  | `CG`
  | `CK`
  | `CR`
  | `HR`
  | `CU`
  | `CW`
  | `CY`
  | `CZ`
  | `CI`
  | `DK`
  | `DJ`
  | `DM`
  | `DO`
  | `EC`
  | `EG`
  | `SV`
  | `GQ`
  | `ER`
  | `EE`
  | `SZ`
  | `ET`
  | `FK`
  | `FO`
  | `FJ`
  | `FI`
  | `FR`
  | `GF`
  | `PF`
  | `TF`
  | `GA`
  | `GM`
  | `GE`
  | `DE`
  | `GH`
  | `GI`
  | `GR`
  | `GL`
  | `GD`
  | `GP`
  | `GU`
  | `GT`
  | `GG`
  | `GN`
  | `GW`
  | `GY`
  | `HT`
  | `HM`
  | `VA`
  | `HN`
  | `HK`
  | `HU`
  | `IS`
  | `IN`
  | `ID`
  | `IR`
  | `IQ`
  | `IE`
  | `IM`
  | `IL`
  | `IT`
  | `JM`
  | `JP`
  | `JE`
  | `JO`
  | `KZ`
  | `KE`
  | `KI`
  | `KP`
  | `KR`
  | `KW`
  | `KG`
  | `LA`
  | `LV`
  | `LB`
  | `LS`
  | `LR`
  | `LY`
  | `LI`
  | `LT`
  | `LU`
  | `MO`
  | `MG`
  | `MW`
  | `MY`
  | `MV`
  | `ML`
  | `MT`
  | `MH`
  | `MQ`
  | `MR`
  | `MU`
  | `YT`
  | `MX`
  | `FM`
  | `MD`
  | `MC`
  | `MN`
  | `ME`
  | `MS`
  | `MA`
  | `MZ`
  | `MM`
  | `NA`
  | `NR`
  | `NP`
  | `NL`
  | `NC`
  | `NZ`
  | `NI`
  | `NE`
  | `NG`
  | `NU`
  | `NF`
  | `MK`
  | `MP`
  | `NO`
  | `OM`
  | `PK`
  | `PW`
  | `PS`
  | `PA`
  | `PG`
  | `PY`
  | `PE`
  | `PH`
  | `PN`
  | `PL`
  | `PT`
  | `PR`
  | `QA`
  | `RO`
  | `RU`
  | `RW`
  | `RE`
  | `BL`
  | `SH`
  | `KN`
  | `LC`
  | `MF`
  | `PM`
  | `VC`
  | `WS`
  | `SM`
  | `ST`
  | `SA`
  | `SN`
  | `RS`
  | `SC`
  | `SL`
  | `SG`
  | `SX`
  | `SK`
  | `SI`
  | `SB`
  | `SO`
  | `ZA`
  | `GS`
  | `SS`
  | `ES`
  | `LK`
  | `SD`
  | `SR`
  | `SJ`
  | `SE`
  | `CH`
  | `SY`
  | `TW`
  | `TJ`
  | `TZ`
  | `TH`
  | `TL`
  | `TG`
  | `TK`
  | `TO`
  | `TT`
  | `TN`
  | `TM`
  | `TC`
  | `TV`
  | `TR`
  | `UG`
  | `UA`
  | `AE`
  | `GB`
  | `UM`
  | `US`
  | `UY`
  | `UZ`
  | `VU`
  | `VE`
  | `VN`
  | `VG`
  | `VI`
  | `WF`
  | `EH`
  | `YE`
  | `ZM`
  | `ZW`
  | `AX`;

export type Country3DigitIsoCode =
  | `AFG`
  | `ALB`
  | `DZA`
  | `ASM`
  | `AND`
  | `AGO`
  | `AIA`
  | `ATA`
  | `ATG`
  | `ARG`
  | `ARM`
  | `ABW`
  | `AUS`
  | `AUT`
  | `AZE`
  | `BHS`
  | `BHR`
  | `BGD`
  | `BRB`
  | `BLR`
  | `BEL`
  | `BLZ`
  | `BEN`
  | `BMU`
  | `BTN`
  | `BOL`
  | `BES`
  | `BIH`
  | `BWA`
  | `BVT`
  | `BRA`
  | `IOT`
  | `BRN`
  | `BGR`
  | `BFA`
  | `BDI`
  | `CPV`
  | `KHM`
  | `CMR`
  | `CAN`
  | `CYM`
  | `CAF`
  | `TCD`
  | `CHL`
  | `CHN`
  | `CXR`
  | `CCK`
  | `COL`
  | `COM`
  | `COD`
  | `COG`
  | `COK`
  | `CRI`
  | `HRV`
  | `CUB`
  | `CUW`
  | `CYP`
  | `CZE`
  | `CIV`
  | `DNK`
  | `DJI`
  | `DMA`
  | `DOM`
  | `ECU`
  | `EGY`
  | `SLV`
  | `GNQ`
  | `ERI`
  | `EST`
  | `SWZ`
  | `ETH`
  | `FLK`
  | `FRO`
  | `FJI`
  | `FIN`
  | `FRA`
  | `GUF`
  | `PYF`
  | `ATF`
  | `GAB`
  | `GMB`
  | `GEO`
  | `DEU`
  | `GHA`
  | `GIB`
  | `GRC`
  | `GRL`
  | `GRD`
  | `GLP`
  | `GUM`
  | `GTM`
  | `GGY`
  | `GIN`
  | `GNB`
  | `GUY`
  | `HTI`
  | `HMD`
  | `VAT`
  | `HND`
  | `HKG`
  | `HUN`
  | `ISL`
  | `IND`
  | `IDN`
  | `IRN`
  | `IRQ`
  | `IRL`
  | `IMN`
  | `ISR`
  | `ITA`
  | `JAM`
  | `JPN`
  | `JEY`
  | `JOR`
  | `KAZ`
  | `KEN`
  | `KIR`
  | `PRK`
  | `KOR`
  | `KWT`
  | `KGZ`
  | `LAO`
  | `LVA`
  | `LBN`
  | `LSO`
  | `LBR`
  | `LBY`
  | `LIE`
  | `LTU`
  | `LUX`
  | `MAC`
  | `MDG`
  | `MWI`
  | `MYS`
  | `MDV`
  | `MLI`
  | `MLT`
  | `MHL`
  | `MTQ`
  | `MRT`
  | `MUS`
  | `MYT`
  | `MEX`
  | `FSM`
  | `MDA`
  | `MCO`
  | `MNG`
  | `MNE`
  | `MSR`
  | `MAR`
  | `MOZ`
  | `MMR`
  | `NAM`
  | `NRU`
  | `NPL`
  | `NLD`
  | `NCL`
  | `NZL`
  | `NIC`
  | `NER`
  | `NGA`
  | `NIU`
  | `NFK`
  | `MKD`
  | `MNP`
  | `NOR`
  | `OMN`
  | `PAK`
  | `PLW`
  | `PSE`
  | `PAN`
  | `PNG`
  | `PRY`
  | `PER`
  | `PHL`
  | `PCN`
  | `POL`
  | `PRT`
  | `PRI`
  | `QAT`
  | `ROU`
  | `RUS`
  | `RWA`
  | `REU`
  | `BLM`
  | `SHN`
  | `KNA`
  | `LCA`
  | `MAF`
  | `SPM`
  | `VCT`
  | `WSM`
  | `SMR`
  | `STP`
  | `SAU`
  | `SEN`
  | `SRB`
  | `SYC`
  | `SLE`
  | `SGP`
  | `SXM`
  | `SVK`
  | `SVN`
  | `SLB`
  | `SOM`
  | `ZAF`
  | `SGS`
  | `SSD`
  | `ESP`
  | `LKA`
  | `SDN`
  | `SUR`
  | `SJM`
  | `SWE`
  | `CHE`
  | `SYR`
  | `TWN`
  | `TJK`
  | `TZA`
  | `THA`
  | `TLS`
  | `TGO`
  | `TKL`
  | `TON`
  | `TTO`
  | `TUN`
  | `TKM`
  | `TCA`
  | `TUV`
  | `TUR`
  | `UGA`
  | `UKR`
  | `ARE`
  | `GBR`
  | `UMI`
  | `USA`
  | `URY`
  | `UZB`
  | `VUT`
  | `VEN`
  | `VNM`
  | `VGB`
  | `VIR`
  | `WLF`
  | `ESH`
  | `YEM`
  | `ZMB`
  | `ZWE`
  | `ALA`;

export interface Continent {
  readonly code: ContinentCode;
  readonly name: string;
}

export interface Country {
  readonly en: string;
  readonly fr: string;
  readonly iso2: Country2DigitIsoCode;
  readonly iso3: Country3DigitIsoCode;
  readonly continents: ContinentCode[];
}

export const continents: readonly Continent[] = [
  { code: 'AF', name: 'Africa' },
  { code: 'AN', name: 'Antartica' },
  { code: 'AS', name: 'Asia' },
  { code: 'EU', name: 'Europe' },
  { code: 'NA', name: 'North America' },
  { code: 'OC', name: 'Oceania' },
  { code: 'SA', name: 'South America' },
];
continents.forEach((_) => Object.freeze(_));

export const ISO_3166_COUNTRIES: readonly Country[] = [
  {
    en: `Afghanistan`,
    fr: `Afghanistan (l')`,
    iso2: `AF`,
    iso3: `AFG`,
    continents: [`AS`],
  },
  {
    en: `Albania`,
    fr: `Albanie (l')`,
    iso2: `AL`,
    iso3: `ALB`,
    continents: [`EU`],
  },
  {
    en: `Algeria`,
    fr: `Algérie (l')`,
    iso2: `DZ`,
    iso3: `DZA`,
    continents: [`AF`],
  },
  {
    en: `American Samoa`,
    fr: `Samoa américaines (les)`,
    iso2: `AS`,
    iso3: `ASM`,
    continents: [`OC`],
  },
  {
    en: `Andorra`,
    fr: `Andorre (l')`,
    iso2: `AD`,
    iso3: `AND`,
    continents: [`EU`],
  },
  {
    en: `Angola`,
    fr: `Angola (l')`,
    iso2: `AO`,
    iso3: `AGO`,
    continents: [`AF`],
  },
  {
    en: `Anguilla`,
    fr: `Anguilla`,
    iso2: `AI`,
    iso3: `AIA`,
    continents: [`NA`],
  },
  {
    en: `Antarctica`,
    fr: `Antarctique (l')`,
    iso2: `AQ`,
    iso3: `ATA`,
    continents: [`AN`],
  },
  {
    en: `Antigua and Barbuda`,
    fr: `Antigua-et-Barbuda`,
    iso2: `AG`,
    iso3: `ATG`,
    continents: [`NA`],
  },
  {
    en: `Argentina`,
    fr: `Argentine (l')`,
    iso2: `AR`,
    iso3: `ARG`,
    continents: [`SA`],
  },
  {
    en: `Armenia`,
    fr: `Arménie (l')`,
    iso2: `AM`,
    iso3: `ARM`,
    continents: [`AS`, `EU`],
  },
  { en: `Aruba`, fr: `Aruba`, iso2: `AW`, iso3: `ABW`, continents: [`NA`] },
  {
    en: `Australia`,
    fr: `Australie (l')`,
    iso2: `AU`,
    iso3: `AUS`,
    continents: [`OC`],
  },
  {
    en: `Austria`,
    fr: `Autriche (l')`,
    iso2: `AT`,
    iso3: `AUT`,
    continents: [`EU`],
  },
  {
    en: `Azerbaijan`,
    fr: `Azerbaïdjan (l')`,
    iso2: `AZ`,
    iso3: `AZE`,
    continents: [`AS`, `EU`],
  },
  {
    en: `Bahamas (the)`,
    fr: `Bahamas (les)`,
    iso2: `BS`,
    iso3: `BHS`,
    continents: [`NA`],
  },
  { en: `Bahrain`, fr: `Bahreïn`, iso2: `BH`, iso3: `BHR`, continents: [`AS`] },
  {
    en: `Bangladesh`,
    fr: `Bangladesh (le)`,
    iso2: `BD`,
    iso3: `BGD`,
    continents: [`AS`],
  },
  {
    en: `Barbados`,
    fr: `Barbade (la)`,
    iso2: `BB`,
    iso3: `BRB`,
    continents: [`NA`],
  },
  {
    en: `Belarus`,
    fr: `Bélarus (le)`,
    iso2: `BY`,
    iso3: `BLR`,
    continents: [`EU`],
  },
  {
    en: `Belgium`,
    fr: `Belgique (la)`,
    iso2: `BE`,
    iso3: `BEL`,
    continents: [`EU`],
  },
  {
    en: `Belize`,
    fr: `Belize (le)`,
    iso2: `BZ`,
    iso3: `BLZ`,
    continents: [`NA`],
  },
  {
    en: `Benin`,
    fr: `Bénin (le)`,
    iso2: `BJ`,
    iso3: `BEN`,
    continents: [`AF`],
  },
  {
    en: `Bermuda`,
    fr: `Bermudes (les)`,
    iso2: `BM`,
    iso3: `BMU`,
    continents: [`NA`],
  },
  {
    en: `Bhutan`,
    fr: `Bhoutan (le)`,
    iso2: `BT`,
    iso3: `BTN`,
    continents: [`AS`],
  },
  {
    en: `Bolivia (Plurinational State of)`,
    fr: `Bolivie (État plurinational de)`,
    iso2: `BO`,
    iso3: `BOL`,
    continents: [`SA`],
  },
  {
    en: 'Bonaire, Sint Eustatius and Saba',
    fr: 'Bonaire, Saint-Eustache et Saba',
    iso2: `BQ`,
    iso3: `BES`,
    continents: [`NA`],
  },
  {
    en: `Bosnia and Herzegovina`,
    fr: `Bosnie-Herzégovine (la)`,
    iso2: `BA`,
    iso3: `BIH`,
    continents: [`EU`],
  },
  {
    en: `Botswana`,
    fr: `Botswana (le)`,
    iso2: `BW`,
    iso3: `BWA`,
    continents: [`AF`],
  },
  {
    en: `Bouvet Island`,
    fr: `Bouvet (l'Île)`,
    iso2: `BV`,
    iso3: `BVT`,
    continents: [`AN`],
  },
  {
    en: `Brazil`,
    fr: `Brésil (le)`,
    iso2: `BR`,
    iso3: `BRA`,
    continents: [`SA`],
  },
  {
    en: `British Indian Ocean Territory (the)`,
    fr: `Indien (le Territoire britannique de l'océan)`,
    iso2: `IO`,
    iso3: `IOT`,
    continents: [`AF`],
  },
  {
    en: `Brunei Darussalam`,
    fr: `Brunéi Darussalam (le)`,
    iso2: `BN`,
    iso3: `BRN`,
    continents: [`AS`],
  },
  {
    en: `Bulgaria`,
    fr: `Bulgarie (la)`,
    iso2: `BG`,
    iso3: `BGR`,
    continents: [`EU`],
  },
  {
    en: `Burkina Faso`,
    fr: `Burkina Faso (le)`,
    iso2: `BF`,
    iso3: `BFA`,
    continents: [`AF`],
  },
  {
    en: `Burundi`,
    fr: `Burundi (le)`,
    iso2: `BI`,
    iso3: `BDI`,
    continents: [`AF`],
  },
  {
    en: `Cabo Verde`,
    fr: `Cabo Verde`,
    iso2: `CV`,
    iso3: `CPV`,
    continents: [`AF`],
  },
  {
    en: `Cambodia`,
    fr: `Cambodge (le)`,
    iso2: `KH`,
    iso3: `KHM`,
    continents: [`AS`],
  },
  {
    en: `Cameroon`,
    fr: `Cameroun (le)`,
    iso2: `CM`,
    iso3: `CMR`,
    continents: [`AF`],
  },
  {
    en: `Canada`,
    fr: `Canada (le)`,
    iso2: `CA`,
    iso3: `CAN`,
    continents: [`NA`],
  },
  {
    en: `Cayman Islands (the)`,
    fr: `Caïmans (les Îles)`,
    iso2: `KY`,
    iso3: `CYM`,
    continents: [`NA`],
  },
  {
    en: `Central African Republic (the)`,
    fr: `République centrafricaine (la)`,
    iso2: `CF`,
    iso3: `CAF`,
    continents: [`AF`],
  },
  { en: `Chad`, fr: `Tchad (le)`, iso2: `TD`, iso3: `TCD`, continents: [`AF`] },
  {
    en: `Chile`,
    fr: `Chili (le)`,
    iso2: `CL`,
    iso3: `CHL`,
    continents: [`SA`],
  },
  {
    en: `China`,
    fr: `Chine (la)`,
    iso2: `CN`,
    iso3: `CHN`,
    continents: [`AS`],
  },
  {
    en: `Christmas Island`,
    fr: `Christmas (l'Île)`,
    iso2: `CX`,
    iso3: `CXR`,
    continents: [`AS`],
  },
  {
    en: `Cocos (Keeling) Islands (the)`,
    fr: `Cocos (les Îles) / Keeling (les Îles)`,
    iso2: `CC`,
    iso3: `CCK`,
    continents: [`AS`],
  },
  {
    en: `Colombia`,
    fr: `Colombie (la)`,
    iso2: `CO`,
    iso3: `COL`,
    continents: [`SA`],
  },
  {
    en: `Comoros (the)`,
    fr: `Comores (les)`,
    iso2: `KM`,
    iso3: `COM`,
    continents: [`AF`],
  },
  {
    en: `Congo (the Democratic Republic of the)`,
    fr: `Congo (la République démocratique du)`,
    iso2: `CD`,
    iso3: `COD`,
    continents: [`AF`],
  },
  {
    en: `Congo (the)`,
    fr: `Congo (le)`,
    iso2: `CG`,
    iso3: `COG`,
    continents: [`AF`],
  },
  {
    en: `Cook Islands (the)`,
    fr: `Cook (les Îles)`,
    iso2: `CK`,
    iso3: `COK`,
    continents: [`OC`],
  },
  {
    en: `Costa Rica`,
    fr: `Costa Rica (le)`,
    iso2: `CR`,
    iso3: `CRI`,
    continents: [`NA`],
  },
  {
    en: `Croatia`,
    fr: `Croatie (la)`,
    iso2: `HR`,
    iso3: `HRV`,
    continents: [`EU`],
  },
  { en: `Cuba`, fr: `Cuba`, iso2: `CU`, iso3: `CUB`, continents: [`NA`] },
  {
    en: `Curaçao`,
    fr: `Curaçao`,
    iso2: `CW`,
    iso3: `CUW`,
    continents: [`NA`],
  },
  {
    en: `Cyprus`,
    fr: `Chypre`,
    iso2: `CY`,
    iso3: `CYP`,
    continents: [`AS`, `EU`],
  },
  {
    en: `Czechia`,
    fr: `Tchéquie (la)`,
    iso2: `CZ`,
    iso3: `CZE`,
    continents: [`EU`],
  },
  {
    en: `Côte d'Ivoire`,
    fr: `Côte d'Ivoire (la)`,
    iso2: `CI`,
    iso3: `CIV`,
    continents: [`AF`],
  },
  {
    en: `Denmark`,
    fr: `Danemark (le)`,
    iso2: `DK`,
    iso3: `DNK`,
    continents: [`EU`],
  },
  {
    en: `Djibouti`,
    fr: `Djibouti`,
    iso2: `DJ`,
    iso3: `DJI`,
    continents: [`AF`],
  },
  {
    en: `Dominica`,
    fr: `Dominique (la)`,
    iso2: `DM`,
    iso3: `DMA`,
    continents: [`NA`],
  },
  {
    en: `Dominican Republic (the)`,
    fr: `dominicaine (la République)`,
    iso2: `DO`,
    iso3: `DOM`,
    continents: [`NA`],
  },
  {
    en: `Ecuador`,
    fr: `Équateur (l')`,
    iso2: `EC`,
    iso3: `ECU`,
    continents: [`SA`],
  },
  {
    en: `Egypt`,
    fr: `Égypte (l')`,
    iso2: `EG`,
    iso3: `EGY`,
    continents: [`AF`, `AS`],
  },
  {
    en: `El Salvador`,
    fr: `El Salvador`,
    iso2: `SV`,
    iso3: `SLV`,
    continents: [`NA`],
  },
  {
    en: `Equatorial Guinea`,
    fr: `Guinée équatoriale (la)`,
    iso2: `GQ`,
    iso3: `GNQ`,
    continents: [`AF`],
  },
  {
    en: `Eritrea`,
    fr: `Érythrée (l')`,
    iso2: `ER`,
    iso3: `ERI`,
    continents: [`AF`],
  },
  {
    en: `Estonia`,
    fr: `Estonie (l')`,
    iso2: `EE`,
    iso3: `EST`,
    continents: [`EU`],
  },
  {
    en: `Eswatini`,
    fr: `Eswatini (l')`,
    iso2: `SZ`,
    iso3: `SWZ`,
    continents: [`AF`],
  },
  {
    en: `Ethiopia`,
    fr: `Éthiopie (l')`,
    iso2: `ET`,
    iso3: `ETH`,
    continents: [`AF`],
  },
  {
    en: `Falkland Islands (the)[Malvinas]`,
    fr: `Falkland (les Îles) / Malouines (les Îles)`,
    iso2: `FK`,
    iso3: `FLK`,
    continents: [`SA`],
  },
  {
    en: `Faroe Islands (the)`,
    fr: `Féroé (les Îles)`,
    iso2: `FO`,
    iso3: `FRO`,
    continents: [`EU`],
  },
  {
    en: `Fiji`,
    fr: `Fidji (les)`,
    iso2: `FJ`,
    iso3: `FJI`,
    continents: [`OC`],
  },
  {
    en: `Finland`,
    fr: `Finlande (la)`,
    iso2: `FI`,
    iso3: `FIN`,
    continents: [`EU`],
  },
  {
    en: `France`,
    fr: `France (la)`,
    iso2: `FR`,
    iso3: `FRA`,
    continents: [`EU`],
  },
  {
    en: `French Guiana`,
    fr: `Guyane française (la)`,
    iso2: `GF`,
    iso3: `GUF`,
    continents: [`SA`],
  },
  {
    en: `French Polynesia`,
    fr: `Polynésie française (la)`,
    iso2: `PF`,
    iso3: `PYF`,
    continents: [`OC`],
  },
  {
    en: `French Southern Territories (the)`,
    fr: `Terres australes françaises (les)`,
    iso2: `TF`,
    iso3: `ATF`,
    continents: [`AF`],
  },
  {
    en: `Gabon`,
    fr: `Gabon (le)`,
    iso2: `GA`,
    iso3: `GAB`,
    continents: [`AF`],
  },
  {
    en: `Gambia (the)`,
    fr: `Gambie (la)`,
    iso2: `GM`,
    iso3: `GMB`,
    continents: [`AF`],
  },
  {
    en: `Georgia`,
    fr: `Géorgie (la)`,
    iso2: `GE`,
    iso3: `GEO`,
    continents: [`AS`, `EU`],
  },
  {
    en: `Germany`,
    fr: `Allemagne (l')`,
    iso2: `DE`,
    iso3: `DEU`,
    continents: [`EU`],
  },
  {
    en: `Ghana`,
    fr: `Ghana (le)`,
    iso2: `GH`,
    iso3: `GHA`,
    continents: [`AF`],
  },
  {
    en: `Gibraltar`,
    fr: `Gibraltar`,
    iso2: `GI`,
    iso3: `GIB`,
    continents: [`EU`],
  },
  {
    en: `Greece`,
    fr: `Grèce (la)`,
    iso2: `GR`,
    iso3: `GRC`,
    continents: [`EU`],
  },
  {
    en: `Greenland`,
    fr: `Groenland (le)`,
    iso2: `GL`,
    iso3: `GRL`,
    continents: [`NA`],
  },
  {
    en: `Grenada`,
    fr: `Grenade (la)`,
    iso2: `GD`,
    iso3: `GRD`,
    continents: [`NA`],
  },
  {
    en: `Guadeloupe`,
    fr: `Guadeloupe (la)`,
    iso2: `GP`,
    iso3: `GLP`,
    continents: [`NA`],
  },
  { en: `Guam`, fr: `Guam`, iso2: `GU`, iso3: `GUM`, continents: [`OC`] },
  {
    en: `Guatemala`,
    fr: `Guatemala (le)`,
    iso2: `GT`,
    iso3: `GTM`,
    continents: [`NA`],
  },
  {
    en: `Guernsey`,
    fr: `Guernesey`,
    iso2: `GG`,
    iso3: `GGY`,
    continents: [`EU`],
  },
  {
    en: `Guinea`,
    fr: `Guinée (la)`,
    iso2: `GN`,
    iso3: `GIN`,
    continents: [`AF`],
  },
  {
    en: `Guinea - Bissau`,
    fr: `Guinée - Bissau (la)`,
    iso2: `GW`,
    iso3: `GNB`,
    continents: [`AF`],
  },
  {
    en: `Guyana`,
    fr: `Guyana (le)`,
    iso2: `GY`,
    iso3: `GUY`,
    continents: [`SA`],
  },
  { en: `Haiti`, fr: `Haïti`, iso2: `HT`, iso3: `HTI`, continents: [`NA`] },
  {
    en: `Heard Island and McDonald Islands`,
    fr: `Heard - et - Îles MacDonald (l'Île)`,
    iso2: `HM`,
    iso3: `HMD`,
    continents: [`AN`],
  },
  {
    en: `Holy See (the)`,
    fr: `Saint - Siège (le)`,
    iso2: `VA`,
    iso3: `VAT`,
    continents: [`EU`],
  },
  {
    en: `Honduras`,
    fr: `Honduras (le)`,
    iso2: `HN`,
    iso3: `HND`,
    continents: [`NA`],
  },
  {
    en: `Hong Kong`,
    fr: `Hong Kong`,
    iso2: `HK`,
    iso3: `HKG`,
    continents: [`AS`],
  },
  {
    en: `Hungary`,
    fr: `Hongrie (la)`,
    iso2: `HU`,
    iso3: `HUN`,
    continents: [`EU`],
  },
  {
    en: `Iceland`,
    fr: `Islande (l')`,
    iso2: `IS`,
    iso3: `ISL`,
    continents: [`EU`],
  },
  { en: `India`, fr: `Inde (l')`, iso2: `IN`, iso3: `IND`, continents: [`AS`] },
  {
    en: `Indonesia`,
    fr: `Indonésie (l')`,
    iso2: `ID`,
    iso3: `IDN`,
    continents: [`AS`],
  },
  {
    en: `Iran (Islamic Republic of)`,
    fr: `Iran (République Islamique d')`,
    iso2: `IR`,
    iso3: `IRN`,
    continents: [`AS`],
  },
  { en: `Iraq`, fr: `Iraq (l')`, iso2: `IQ`, iso3: `IRQ`, continents: [`AS`] },
  {
    en: `Ireland`,
    fr: `Irlande (l')`,
    iso2: `IE`,
    iso3: `IRL`,
    continents: [`EU`],
  },
  {
    en: `Isle of Man`,
    fr: `Île de Man`,
    iso2: `IM`,
    iso3: `IMN`,
    continents: [`EU`],
  },
  { en: `Israel`, fr: `Israël`, iso2: `IL`, iso3: `ISR`, continents: [`AS`] },
  {
    en: `Italy`,
    fr: `Italie (l')`,
    iso2: `IT`,
    iso3: `ITA`,
    continents: [`EU`],
  },
  {
    en: `Jamaica`,
    fr: `Jamaïque (la)`,
    iso2: `JM`,
    iso3: `JAM`,
    continents: [`NA`],
  },
  {
    en: `Japan`,
    fr: `Japon (le)`,
    iso2: `JP`,
    iso3: `JPN`,
    continents: [`AS`],
  },
  { en: `Jersey`, fr: `Jersey`, iso2: `JE`, iso3: `JEY`, continents: [`EU`] },
  {
    en: `Jordan`,
    fr: `Jordanie (la)`,
    iso2: `JO`,
    iso3: `JOR`,
    continents: [`AS`],
  },
  {
    en: `Kazakhstan`,
    fr: `Kazakhstan (le)`,
    iso2: `KZ`,
    iso3: `KAZ`,
    continents: [`AS`, `EU`],
  },
  {
    en: `Kenya`,
    fr: `Kenya (le)`,
    iso2: `KE`,
    iso3: `KEN`,
    continents: [`AF`],
  },
  {
    en: `Kiribati`,
    fr: `Kiribati`,
    iso2: `KI`,
    iso3: `KIR`,
    continents: [`OC`],
  },
  {
    en: `Korea (the Democratic People's Republic of)`,
    fr: `Corée (la République populaire démocratique de)`,
    iso2: `KP`,
    iso3: `PRK`,
    continents: [`AS`],
  },
  {
    en: `Korea (the Republic of)`,
    fr: `Corée (la République de)`,
    iso2: `KR`,
    iso3: `KOR`,
    continents: [`AS`],
  },
  {
    en: `Kuwait`,
    fr: `Koweït (le)`,
    iso2: `KW`,
    iso3: `KWT`,
    continents: [`AS`],
  },
  {
    en: `Kyrgyzstan`,
    fr: `Kirghizistan (le)`,
    iso2: `KG`,
    iso3: `KGZ`,
    continents: [`AS`],
  },
  {
    en: `Lao People's Democratic Republic (the)`,
    fr: `Lao (la République démocratique populaire)`,
    iso2: `LA`,
    iso3: `LAO`,
    continents: [`AS`],
  },
  {
    en: `Latvia`,
    fr: `Lettonie (la)`,
    iso2: `LV`,
    iso3: `LVA`,
    continents: [`EU`],
  },
  {
    en: `Lebanon`,
    fr: `Liban (le)`,
    iso2: `LB`,
    iso3: `LBN`,
    continents: [`AS`],
  },
  {
    en: `Lesotho`,
    fr: `Lesotho (le)`,
    iso2: `LS`,
    iso3: `LSO`,
    continents: [`AF`],
  },
  {
    en: `Liberia`,
    fr: `Libéria (le)`,
    iso2: `LR`,
    iso3: `LBR`,
    continents: [`AF`],
  },
  {
    en: `Libya`,
    fr: `Libye (la)`,
    iso2: `LY`,
    iso3: `LBY`,
    continents: [`AF`],
  },
  {
    en: `Liechtenstein`,
    fr: `Liechtenstein (le)`,
    iso2: `LI`,
    iso3: `LIE`,
    continents: [`EU`],
  },
  {
    en: `Lithuania`,
    fr: `Lituanie (la)`,
    iso2: `LT`,
    iso3: `LTU`,
    continents: [`EU`],
  },
  {
    en: `Luxembourg`,
    fr: `Luxembourg (le)`,
    iso2: `LU`,
    iso3: `LUX`,
    continents: [`EU`],
  },
  { en: `Macao`, fr: `Macao`, iso2: `MO`, iso3: `MAC`, continents: [`AS`] },
  {
    en: `Madagascar`,
    fr: `Madagascar`,
    iso2: `MG`,
    iso3: `MDG`,
    continents: [`AF`],
  },
  {
    en: `Malawi`,
    fr: `Malawi (le)`,
    iso2: `MW`,
    iso3: `MWI`,
    continents: [`AF`],
  },
  {
    en: `Malaysia`,
    fr: `Malaisie (la)`,
    iso2: `MY`,
    iso3: `MYS`,
    continents: [`AS`],
  },
  {
    en: `Maldives`,
    fr: `Maldives (les)`,
    iso2: `MV`,
    iso3: `MDV`,
    continents: [`AS`],
  },
  { en: `Mali`, fr: `Mali (le)`, iso2: `ML`, iso3: `MLI`, continents: [`AF`] },
  { en: `Malta`, fr: `Malte`, iso2: `MT`, iso3: `MLT`, continents: [`EU`] },
  {
    en: `Marshall Islands (the)`,
    fr: `Marshall (les Îles)`,
    iso2: `MH`,
    iso3: `MHL`,
    continents: [`OC`],
  },
  {
    en: `Martinique`,
    fr: `Martinique (la)`,
    iso2: `MQ`,
    iso3: `MTQ`,
    continents: [`NA`],
  },
  {
    en: `Mauritania`,
    fr: `Mauritanie (la)`,
    iso2: `MR`,
    iso3: `MRT`,
    continents: [`AF`],
  },
  {
    en: `Mauritius`,
    fr: `Maurice`,
    iso2: `MU`,
    iso3: `MUS`,
    continents: [`AF`],
  },
  {
    en: `Mayotte`,
    fr: `Mayotte`,
    iso2: `YT`,
    iso3: `MYT`,
    continents: [`AF`],
  },
  {
    en: `Mexico`,
    fr: `Mexique (le)`,
    iso2: `MX`,
    iso3: `MEX`,
    continents: [`NA`],
  },
  {
    en: `Micronesia (Federated States of)`,
    fr: `Micronésie (États fédérés de)`,
    iso2: `FM`,
    iso3: `FSM`,
    continents: [`OC`],
  },
  {
    en: `Moldova (the Republic of)`,
    fr: `Moldova (la République de)`,
    iso2: `MD`,
    iso3: `MDA`,
    continents: [`EU`],
  },
  { en: `Monaco`, fr: `Monaco`, iso2: `MC`, iso3: `MCO`, continents: [`EU`] },
  {
    en: `Mongolia`,
    fr: `Mongolie (la)`,
    iso2: `MN`,
    iso3: `MNG`,
    continents: [`AS`],
  },
  {
    en: `Montenegro`,
    fr: `Monténégro (le)`,
    iso2: `ME`,
    iso3: `MNE`,
    continents: [`EU`],
  },
  {
    en: `Montserrat`,
    fr: `Montserrat`,
    iso2: `MS`,
    iso3: `MSR`,
    continents: [`NA`],
  },
  {
    en: `Morocco`,
    fr: `Maroc (le)`,
    iso2: `MA`,
    iso3: `MAR`,
    continents: [`AF`],
  },
  {
    en: `Mozambique`,
    fr: `Mozambique (le)`,
    iso2: `MZ`,
    iso3: `MOZ`,
    continents: [`AF`],
  },
  {
    en: `Myanmar`,
    fr: `Myanmar (le)`,
    iso2: `MM`,
    iso3: `MMR`,
    continents: [`AS`],
  },
  {
    en: `Namibia`,
    fr: `Namibie (la)`,
    iso2: `NA`,
    iso3: `NAM`,
    continents: [`AF`],
  },
  { en: `Nauru`, fr: `Nauru`, iso2: `NR`, iso3: `NRU`, continents: [`OC`] },
  {
    en: `Nepal`,
    fr: `Népal (le)`,
    iso2: `NP`,
    iso3: `NPL`,
    continents: [`AS`],
  },
  {
    en: `Netherlands (the)`,
    fr: `Pays - Bas (les)`,
    iso2: `NL`,
    iso3: `NLD`,
    continents: [`EU`],
  },
  {
    en: `New Caledonia`,
    fr: `Nouvelle - Calédonie (la)`,
    iso2: `NC`,
    iso3: `NCL`,
    continents: [`OC`],
  },
  {
    en: `New Zealand`,
    fr: `Nouvelle - Zélande (la)`,
    iso2: `NZ`,
    iso3: `NZL`,
    continents: [`OC`],
  },
  {
    en: `Nicaragua`,
    fr: `Nicaragua (le)`,
    iso2: `NI`,
    iso3: `NIC`,
    continents: [`NA`],
  },
  {
    en: `Niger (the)`,
    fr: `Niger (le)`,
    iso2: `NE`,
    iso3: `NER`,
    continents: [`AF`],
  },
  {
    en: `Nigeria`,
    fr: `Nigéria (le)`,
    iso2: `NG`,
    iso3: `NGA`,
    continents: [`AF`],
  },
  { en: `Niue`, fr: `Niue`, iso2: `NU`, iso3: `NIU`, continents: [`OC`] },
  {
    en: `Norfolk Island`,
    fr: `Norfolk (l'Île)`,
    iso2: `NF`,
    iso3: `NFK`,
    continents: [`OC`],
  },
  {
    en: `North Macedonia`,
    fr: `Macédoine du Nord (la)`,
    iso2: `MK`,
    iso3: `MKD`,
    continents: [`EU`],
  },
  {
    en: `Northern Mariana Islands (the)`,
    fr: `Mariannes du Nord (les Îles)`,
    iso2: `MP`,
    iso3: `MNP`,
    continents: [`OC`],
  },
  {
    en: `Norway`,
    fr: `Norvège (la)`,
    iso2: `NO`,
    iso3: `NOR`,
    continents: [`EU`],
  },
  { en: `Oman`, fr: `Oman`, iso2: `OM`, iso3: `OMN`, continents: [`AS`] },
  {
    en: `Pakistan`,
    fr: `Pakistan (le)`,
    iso2: `PK`,
    iso3: `PAK`,
    continents: [`AS`],
  },
  {
    en: `Palau`,
    fr: `Palaos (les)`,
    iso2: `PW`,
    iso3: `PLW`,
    continents: [`OC`],
  },
  {
    en: 'Palestine, State of',
    fr: 'Palestine, État de',
    iso2: `PS`,
    iso3: `PSE`,
    continents: [`AS`],
  },
  {
    en: `Panama`,
    fr: `Panama (le)`,
    iso2: `PA`,
    iso3: `PAN`,
    continents: [`NA`],
  },
  {
    en: `Papua New Guinea`,
    fr: `Papouasie - Nouvelle - Guinée (la)`,
    iso2: `PG`,
    iso3: `PNG`,
    continents: [`OC`],
  },
  {
    en: `Paraguay`,
    fr: `Paraguay (le)`,
    iso2: `PY`,
    iso3: `PRY`,
    continents: [`SA`],
  },
  { en: `Peru`, fr: `Pérou (le)`, iso2: `PE`, iso3: `PER`, continents: [`SA`] },
  {
    en: `Philippines (the)`,
    fr: `Philippines (les)`,
    iso2: `PH`,
    iso3: `PHL`,
    continents: [`AS`],
  },
  {
    en: `Pitcairn`,
    fr: `Pitcairn`,
    iso2: `PN`,
    iso3: `PCN`,
    continents: [`OC`],
  },
  {
    en: `Poland`,
    fr: `Pologne (la)`,
    iso2: `PL`,
    iso3: `POL`,
    continents: [`EU`],
  },
  {
    en: `Portugal`,
    fr: `Portugal (le)`,
    iso2: `PT`,
    iso3: `PRT`,
    continents: [`EU`],
  },
  {
    en: `Puerto Rico`,
    fr: `Porto Rico`,
    iso2: `PR`,
    iso3: `PRI`,
    continents: [`NA`],
  },
  {
    en: `Qatar`,
    fr: `Qatar (le)`,
    iso2: `QA`,
    iso3: `QAT`,
    continents: [`AS`],
  },
  {
    en: `Romania`,
    fr: `Roumanie (la)`,
    iso2: `RO`,
    iso3: `ROU`,
    continents: [`EU`],
  },
  {
    en: `Russian Federation (the)`,
    fr: `Russie (la Fédération de)`,
    iso2: `RU`,
    iso3: `RUS`,
    continents: [`AS`, `EU`],
  },
  {
    en: `Rwanda`,
    fr: `Rwanda (le)`,
    iso2: `RW`,
    iso3: `RWA`,
    continents: [`AF`],
  },
  {
    en: `Réunion`,
    fr: `Réunion (La)`,
    iso2: `RE`,
    iso3: `REU`,
    continents: [`AF`],
  },
  {
    en: `Saint Barthélemy`,
    fr: `Saint - Barthélemy`,
    iso2: `BL`,
    iso3: `BLM`,
    continents: [`NA`],
  },
  {
    en: 'Saint Helena, Ascension and Tristan da Cunha',
    fr: 'Sainte-Hélène, Ascension et Tristan da Cunha',
    iso2: `SH`,
    iso3: `SHN`,
    continents: [`AF`],
  },
  {
    en: `Saint Kitts and Nevis`,
    fr: `Saint - Kitts - et - Nevis`,
    iso2: `KN`,
    iso3: `KNA`,
    continents: [`NA`],
  },
  {
    en: `Saint Lucia`,
    fr: `Sainte - Lucie`,
    iso2: `LC`,
    iso3: `LCA`,
    continents: [`NA`],
  },
  {
    en: `Saint Martin (French part)`,
    fr: `Saint - Martin (partie française)`,
    iso2: `MF`,
    iso3: `MAF`,
    continents: [`NA`],
  },
  {
    en: `Saint Pierre and Miquelon`,
    fr: `Saint - Pierre - et - Miquelon`,
    iso2: `PM`,
    iso3: `SPM`,
    continents: [`NA`],
  },
  {
    en: `Saint Vincent and the Grenadines`,
    fr: `Saint - Vincent - et - les Grenadines`,
    iso2: `VC`,
    iso3: `VCT`,
    continents: [`NA`],
  },
  {
    en: `Samoa`,
    fr: `Samoa (le)`,
    iso2: `WS`,
    iso3: `WSM`,
    continents: [`OC`],
  },
  {
    en: `San Marino`,
    fr: `Saint - Marin`,
    iso2: `SM`,
    iso3: `SMR`,
    continents: [`EU`],
  },
  {
    en: `Sao Tome and Principe`,
    fr: `Sao Tomé - et - Principe`,
    iso2: `ST`,
    iso3: `STP`,
    continents: [`AF`],
  },
  {
    en: `Saudi Arabia`,
    fr: `Arabie saoudite (l')`,
    iso2: `SA`,
    iso3: `SAU`,
    continents: [`AS`],
  },
  {
    en: `Senegal`,
    fr: `Sénégal (le)`,
    iso2: `SN`,
    iso3: `SEN`,
    continents: [`AF`],
  },
  {
    en: `Serbia`,
    fr: `Serbie (la)`,
    iso2: `RS`,
    iso3: `SRB`,
    continents: [`EU`],
  },
  {
    en: `Seychelles`,
    fr: `Seychelles (les)`,
    iso2: `SC`,
    iso3: `SYC`,
    continents: [`AF`],
  },
  {
    en: `Sierra Leone`,
    fr: `Sierra Leone (la)`,
    iso2: `SL`,
    iso3: `SLE`,
    continents: [`AF`],
  },
  {
    en: `Singapore`,
    fr: `Singapour`,
    iso2: `SG`,
    iso3: `SGP`,
    continents: [`AS`],
  },
  {
    en: `Sint Maarten (Dutch part)`,
    fr: `Saint - Martin (partie néerlandaise)`,
    iso2: `SX`,
    iso3: `SXM`,
    continents: [`NA`],
  },
  {
    en: `Slovakia`,
    fr: `Slovaquie (la)`,
    iso2: `SK`,
    iso3: `SVK`,
    continents: [`EU`],
  },
  {
    en: `Slovenia`,
    fr: `Slovénie (la)`,
    iso2: `SI`,
    iso3: `SVN`,
    continents: [`EU`],
  },
  {
    en: `Solomon Islands`,
    fr: `Salomon (les Îles)`,
    iso2: `SB`,
    iso3: `SLB`,
    continents: [`OC`],
  },
  {
    en: `Somalia`,
    fr: `Somalie (la)`,
    iso2: `SO`,
    iso3: `SOM`,
    continents: [`AF`],
  },
  {
    en: `South Africa`,
    fr: `Afrique du Sud (l')`,
    iso2: `ZA`,
    iso3: `ZAF`,
    continents: [`AF`],
  },
  {
    en: `South Georgia and the South Sandwich Islands`,
    fr: `Géorgie du Sud - et - les Îles Sandwich du Sud (la)`,
    iso2: `GS`,
    iso3: `SGS`,
    continents: [`AN`],
  },
  {
    en: `South Sudan`,
    fr: `Soudan du Sud (le)`,
    iso2: `SS`,
    iso3: `SSD`,
    continents: [`AF`],
  },
  {
    en: `Spain`,
    fr: `Espagne (l')`,
    iso2: `ES`,
    iso3: `ESP`,
    continents: [`EU`],
  },
  {
    en: `Sri Lanka`,
    fr: `Sri Lanka`,
    iso2: `LK`,
    iso3: `LKA`,
    continents: [`AS`],
  },
  {
    en: `Sudan (the)`,
    fr: `Soudan (le)`,
    iso2: `SD`,
    iso3: `SDN`,
    continents: [`AF`],
  },
  {
    en: `Suriname`,
    fr: `Suriname (le)`,
    iso2: `SR`,
    iso3: `SUR`,
    continents: [`SA`],
  },
  {
    en: `Svalbard and Jan Mayen`,
    fr: `Svalbard et l'Île Jan Mayen (le)`,
    iso2: `SJ`,
    iso3: `SJM`,
    continents: [`EU`],
  },
  {
    en: `Sweden`,
    fr: `Suède (la)`,
    iso2: `SE`,
    iso3: `SWE`,
    continents: [`EU`],
  },
  {
    en: `Switzerland`,
    fr: `Suisse (la)`,
    iso2: `CH`,
    iso3: `CHE`,
    continents: [`EU`],
  },
  {
    en: `Syrian Arab Republic (the)`,
    fr: `République arabe syrienne (la)`,
    iso2: `SY`,
    iso3: `SYR`,
    continents: [`AS`],
  },
  {
    en: `Taiwan (Province of China)`,
    fr: `Taïwan (Province de Chine)`,
    iso2: `TW`,
    iso3: `TWN`,
    continents: [`AS`],
  },
  {
    en: `Tajikistan`,
    fr: `Tadjikistan (le)`,
    iso2: `TJ`,
    iso3: `TJK`,
    continents: [`AS`],
  },
  {
    en: 'Tanzania, the United Republic of',
    fr: `Tanzanie (la République - Unie de)`,
    iso2: `TZ`,
    iso3: `TZA`,
    continents: [`AF`],
  },
  {
    en: `Thailand`,
    fr: `Thaïlande (la)`,
    iso2: `TH`,
    iso3: `THA`,
    continents: [`AS`],
  },
  {
    en: `Timor - Leste`,
    fr: `Timor - Leste (le)`,
    iso2: `TL`,
    iso3: `TLS`,
    continents: [`AS`],
  },
  { en: `Togo`, fr: `Togo (le)`, iso2: `TG`, iso3: `TGO`, continents: [`AF`] },
  {
    en: `Tokelau`,
    fr: `Tokelau (les)`,
    iso2: `TK`,
    iso3: `TKL`,
    continents: [`OC`],
  },
  {
    en: `Tonga`,
    fr: `Tonga (les)`,
    iso2: `TO`,
    iso3: `TON`,
    continents: [`OC`],
  },
  {
    en: `Trinidad and Tobago`,
    fr: `Trinité - et - Tobago (la)`,
    iso2: `TT`,
    iso3: `TTO`,
    continents: [`NA`],
  },
  {
    en: `Tunisia`,
    fr: `Tunisie (la)`,
    iso2: `TN`,
    iso3: `TUN`,
    continents: [`AF`],
  },
  {
    en: `Turkmenistan`,
    fr: `Turkménistan (le)`,
    iso2: `TM`,
    iso3: `TKM`,
    continents: [`AS`],
  },
  {
    en: `Turks and Caicos Islands (the)`,
    fr: `Turks - et - Caïcos (les Îles)`,
    iso2: `TC`,
    iso3: `TCA`,
    continents: [`NA`],
  },
  {
    en: `Tuvalu`,
    fr: `Tuvalu (les)`,
    iso2: `TV`,
    iso3: `TUV`,
    continents: [`OC`],
  },
  {
    en: `Türkiye`,
    fr: `Türkiye (la)`,
    iso2: `TR`,
    iso3: `TUR`,
    continents: [`AS`, `EU`],
  },
  {
    en: `Uganda`,
    fr: `Ouganda (l')`,
    iso2: `UG`,
    iso3: `UGA`,
    continents: [`AF`],
  },
  {
    en: `Ukraine`,
    fr: `Ukraine (l')`,
    iso2: `UA`,
    iso3: `UKR`,
    continents: [`EU`],
  },
  {
    en: `United Arab Emirates (the)`,
    fr: `Émirats arabes unis (les)`,
    iso2: `AE`,
    iso3: `ARE`,
    continents: [`AS`],
  },
  {
    en: `United Kingdom of Great Britain and Northern Ireland (the)`,
    fr: `Royaume - Uni de Grande - Bretagne et d'Irlande du Nord (le)`,
    iso2: `GB`,
    iso3: `GBR`,
    continents: [`EU`],
  },
  {
    en: `United States Minor Outlying Islands (the)`,
    fr: `Îles mineures éloignées des États - Unis (les)`,
    iso2: `UM`,
    iso3: `UMI`,
    continents: [`NA`, `OC`],
  },
  {
    en: `United States of America (the)`,
    fr: `États - Unis d'Amérique (les)`,
    iso2: `US`,
    iso3: `USA`,
    continents: [`NA`],
  },
  {
    en: `Uruguay`,
    fr: `Uruguay (l')`,
    iso2: `UY`,
    iso3: `URY`,
    continents: [`SA`],
  },
  {
    en: `Uzbekistan`,
    fr: `Ouzbékistan (l')`,
    iso2: `UZ`,
    iso3: `UZB`,
    continents: [`AS`],
  },
  {
    en: `Vanuatu`,
    fr: `Vanuatu (le)`,
    iso2: `VU`,
    iso3: `VUT`,
    continents: [`OC`],
  },
  {
    en: `Venezuela (Bolivarian Republic of)`,
    fr: `Venezuela (République bolivarienne du)`,
    iso2: `VE`,
    iso3: `VEN`,
    continents: [`SA`],
  },
  {
    en: `Viet Nam`,
    fr: `Viet Nam (le)`,
    iso2: `VN`,
    iso3: `VNM`,
    continents: [`AS`],
  },
  {
    en: `Virgin Islands (British)`,
    fr: `Vierges britanniques (les Îles)`,
    iso2: `VG`,
    iso3: `VGB`,
    continents: [`NA`],
  },
  {
    en: `Virgin Islands (U.S.)`,
    fr: `Vierges des États - Unis (les Îles)`,
    iso2: `VI`,
    iso3: `VIR`,
    continents: [`NA`],
  },
  {
    en: `Wallis and Futuna`,
    fr: `Wallis - et - Futuna`,
    iso2: `WF`,
    iso3: `WLF`,
    continents: [`OC`],
  },
  {
    en: `Western Sahara`,
    fr: `Sahara occidental (le)`,
    iso2: `EH`,
    iso3: `ESH`,
    continents: [`AF`],
  },
  {
    en: `Yemen`,
    fr: `Yémen (le)`,
    iso2: `YE`,
    iso3: `YEM`,
    continents: [`AS`],
  },
  {
    en: `Zambia`,
    fr: `Zambie (la)`,
    iso2: `ZM`,
    iso3: `ZMB`,
    continents: [`AF`],
  },
  {
    en: `Zimbabwe`,
    fr: `Zimbabwe (le)`,
    iso2: `ZW`,
    iso3: `ZWE`,
    continents: [`AF`],
  },
  {
    en: `Åland Islands`,
    fr: `Åland (les Îles)`,
    iso2: `AX`,
    iso3: `ALA`,
    continents: [`EU`],
  },
];
ISO_3166_COUNTRIES.forEach((_) => Object.freeze(_));
export const ISO_3166_2_DIGIT_CODES: readonly Country2DigitIsoCode[] =
  ISO_3166_COUNTRIES.map((c) => c.iso2);
export const ISO_3166_3_DIGIT_CODES: readonly Country3DigitIsoCode[] =
  ISO_3166_COUNTRIES.map((c) => c.iso3);

type CountryIsoLookup = Readonly<{
  [isoCode in Country2DigitIsoCode | Country3DigitIsoCode]: Country;
}>;

export const ISO_3166_COUNTRIES_BY_CODE: CountryIsoLookup =
  ISO_3166_COUNTRIES.reduce((countries, country) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (countries as any)[country.iso2] = country;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (countries as any)[country.iso3] = country;
    return countries;
  }, {}) as CountryIsoLookup;

export function isIso3166CountryCode(
  value: string,
): value is Country2DigitIsoCode | Country3DigitIsoCode {
  return (
    ISO_3166_COUNTRIES_BY_CODE[
      value as Country2DigitIsoCode | Country3DigitIsoCode
    ] != null
  );
}
