<div class="label">{{ label }}</div>
<div #container>
  <div class="control">
    <textarea
      #input
      type="text"
      [value]="_value"
      (blur)="markAsTouched()"
      (input)="handleInputChange(input.value)"
      rows="1"
      [readonly]="static"
    ></textarea>
    <div class="suffix">
      <mat-icon *ngIf="firstError && touched" color="warn" [@fadeIn]
        >error
      </mat-icon>
    </div>
  </div>

  <div *ngIf="!(firstError && touched)" class="hint">
    {{ hint }}
  </div>

  <ng-container
    *ngIf="firstError && touched"
    [ngTemplateOutlet]="firstError.template"
  ></ng-container>
</div>
