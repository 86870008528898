import { PercentPipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { PrecisionService } from 'gain-lib/precision/precision';

@Pipe({
  name: 'gainPercent',
  standalone: true,
})
export class GainPercentPipe implements PipeTransform {
  private _pp: PercentPipe;
  private _digitsInfo: string;

  constructor(
    @Inject(LOCALE_ID) private _locale: string,
    private _precision: PrecisionService,
  ) {
    this._pp = new PercentPipe(_locale);
    const { percentageMinDigits, percentageMaxDigits } =
      _precision.getPrecisionSettings();
    this._digitsInfo = `1.${percentageMinDigits}-${percentageMaxDigits}`;
  }

  transform(value: number): unknown {
    if (value != null) {
      return this._pp.transform(value, this._digitsInfo, this._locale);
    }
    return value;
  }
}
