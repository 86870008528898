import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { DeprecatedInformationDialogDescriptionComponent } from 'gain-lib/deprecated-information-dialog/information-dialog-description/deprecated-information-dialog-description.component';
import { DeprecatedInformationDialogTitleComponent } from 'gain-lib/deprecated-information-dialog/information-dialog-title/deprecated-information-dialog-title.component';
import { DeprecatedInformationDialogComponent } from 'gain-lib/deprecated-information-dialog/deprecated-information-dialog.component';

@NgModule({
  declarations: [
    DeprecatedInformationDialogComponent,
    DeprecatedInformationDialogTitleComponent,
    DeprecatedInformationDialogDescriptionComponent,
  ],
  imports: [CommonModule, MatIconModule, MatDialogModule],
  exports: [
    DeprecatedInformationDialogComponent,
    DeprecatedInformationDialogTitleComponent,
    DeprecatedInformationDialogDescriptionComponent,
  ],
})
export class DeprecatedInformationDialogModule {}
