<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<div class="gax-label">
  {{ label }}<gax-required-asterisk *ngIf="required" /><gax-help-icon
    *ngIf="showInfoIcon"
    (click)="openInfoModal($event)"
    >help</gax-help-icon
  >
</div>
<div #container>
  <div class="control" cdkOverlayOrigin #origin="cdkOverlayOrigin">
    <div [ngSwitch]="!!selectTrigger">
      <div class="ga-select-min-line" *ngSwitchDefault>
        <span class="selected-value" [class.placeholder]="!displayValue">{{
          displayValue || placeholder
        }}</span>
      </div>
      <ng-container *ngSwitchCase="true">
        <ng-container *ngTemplateOutlet="selectTriggerTemplate"></ng-container>
      </ng-container>
    </div>

    <div class="suffix">
      <mat-icon color="warn" *ngIf="firstError && touched" [@fadeIn]
        >error
      </mat-icon>
      <div class="caret-wrapper" *ngIf="!hideCaret">
        <div class="drop-down-caret"></div>
      </div>
      <!-- <mat-spinner *ngIf="loading$ | async" [diameter]="24"></mat-spinner> -->
    </div>
  </div>

  <div [@fadeIn] *ngIf="!(firstError && touched)" class="hint">
    {{ hint }}
  </div>

  <ng-container
    *ngIf="firstError && touched"
    [ngTemplateOutlet]="firstError.template"
  ></ng-container>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayOffsetY]="0"
  [cdkConnectedOverlayOffsetX]="0"
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  cdkConnectedOverlayPanelClass="ga-select-overlay-panel"
  (backdropClick)="closePanel()"
  (detach)="closePanel()"
  [cdkConnectedOverlayWidth]="
    origin.elementRef.nativeElement.getBoundingClientRect().width
  "
  (positionChange)="onPanelPositionChange($event)"
>
  <div
    class="panel"
    [class.panelAbove]="panelAbove"
    [@dropDown]
    (@dropDown.done)="onPanelAmimationsDone($event)"
  >
    <div *ngIf="enableSearch()" class="search-bar">
      <mat-icon>search</mat-icon>
      <input
        #autocompleteInput
        class="autocompleteInput overlay-on-top"
        (input)="onAutocompleteInputChange($event)"
        [value]="autocompleteSearchTerm"
        type="text"
        placeholder="Search"
      />
    </div>
    <div class="drop-down-options">
      @if (multiple && showAllSelected) {
        <div
          [class.selected]="allSelected"
          (click)="toggleAllSelection()"
          class="select-all-option"
        >
          <input [checked]="allSelected" type="checkbox" />
          <div class="option-content">Select all</div>
        </div>
      }
      <ng-content></ng-content>
    </div>
  </div>
</ng-template>
