import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AsyncRequestHelper } from 'gain-lib/ga-async-request/async-request-helper';
import { PageEditTracker } from 'gain-lib/ga-page-editing/page-edit-tracker';
import { expandLeftAnimation } from 'gain-web/app/clients/client-shared/client-animations';
import { ClientContextService } from 'gain-web/app/clients/client-shared/client-context.service';
import { CountryRegionDataService } from 'gain-web/shared-modules/country-region-data/country-region-data.service';
import { ApiClient } from 'gain-web/shared-services/api-client.generated.service';

export interface SpecificCountryRegionTaxRatesPageData {
  result:
    | ApiClient.SpecificCountryRegionTaxRatesPageData
    | ApiClient.HypoSpecificCountryRegionTaxRatesPageData;
  countryName: string;
  countryCode: string;
  regionName?: string | null;
  regionCode?: string | null;
}

export interface TransformedData {
  table: string;
  bands: BandTransformed[];
}

export interface PlanTypeValue {
  defaultValue: number;
  newValue: number | null;
}

export interface BandTransformed {
  taxRateOverrides: ApiClient.TaxRateOverridesDto;
  lowerThreshold: number | null;
  upperThreshold: number | null;
  defaultTaxRate: number | null;
  taxRateFormula: null | string;
  bandingType: string;
  span: number;
  displayDescription: boolean;
  taxType: ApiClient.TaxTypeDto;
  taxRateType: ApiClient.TaxRateTypeDto;
  description: string;
  CashBonus: PlanTypeValue;
  RestrictedStockUnit: PlanTypeValue;
  StockOption: PlanTypeValue;
}

@Component({
  selector: 'app-specific-country-region-tax-rates-page',
  templateUrl: './specific-country-region-tax-rates-page.component.html',
  styleUrl: './specific-country-region-tax-rates-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [expandLeftAnimation],
})
export class SpecificCountryRegionTaxRatesPageComponent {
  navigationPath = input.required<string>();
  showSocialTax = input<boolean>(true);
  hypo = input<boolean>(false);

  pageData: SpecificCountryRegionTaxRatesPageData;
  userCanManage: boolean;
  currencyCode: ApiClient.ICurrencyDto | null;
  configuredPlanTypes: ApiClient.PlanTypeDto[];
  PlanType = ApiClient.PlanType;

  mappedTableData: TransformedData[];
  private readonly _defaultDisplayedColumns = [
    'description',
    'lowerThreshold',
    'upperThreshold',
    'defaultTaxRate',
  ];
  displayedColumns: string[][] = [];
  private readonly _maxDisplayedColumns: number;

  links: string[];
  activeLink: string;
  dataSources: MatTableDataSource<BandTransformed>[] = [];
  showSearch = false;

  saveOverrideDetailsRequest: AsyncRequestHelper<ApiClient.UpsertCountryRegionTaxRateOverridesCommand>;
  saveHypoOverrideDetailsRequest: AsyncRequestHelper<ApiClient.UpsertHypoCountryRegionTaxRateOverridesCommand>;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _specificCountryTaxRatesPageService: ApiClient.SpecificCountryRegionTaxRatesPageService,
    private _hypoSpecificCountryTaxRatesPageService: ApiClient.HypoSpecificCountryRegionTaxRatesPageService,
    private _clientContextService: ClientContextService,
    private _countryCodeService: CountryRegionDataService,
    private _pageEdits: PageEditTracker,
  ) {
    this.pageData = this._route.snapshot.data.pageData;
    this.userCanManage = this._clientContextService.value.userCanManage;

    this.configuredPlanTypes = this.pageData.result.configuredPlanTypes;
    this.mappedTableData = this.pageData.result.taxRateTables.map((table) => ({
      table: table.taxType.display,
      bands: table.rowGroups.flatMap((group) =>
        group.bands.map((band, bandIndex) => ({
          ...band,
          defaultTaxRate: band.defaultTaxRate ?? 0,
          span: group.bands.length,
          displayDescription: bandIndex === 0,
          taxRateType: group.taxRateType,
          taxType: table.taxType,
          description: group.taxRateType.description,
          bandingType: group.taxRateType.bandingType,
          residency: group.residency.display,
          CashBonus: {
            defaultValue: band.defaultTaxRate ?? 0,
            newValue: null,
          },
          RestrictedStockUnit: {
            defaultValue: band.defaultTaxRate ?? 0,
            newValue: null,
          },
          StockOption: {
            defaultValue: band.defaultTaxRate ?? 0,
            newValue: null,
          },
          ...band.taxRateOverrides.planTypeOverrides.reduce(
            (acc, planOverride) => {
              return {
                ...acc,
                [`${planOverride.planType}`]: {
                  defaultValue: band.defaultTaxRate ?? 0,
                  newValue: planOverride.override ?? null,
                },
              };
            },
            {},
          ),
        })),
      ),
    }));

    this._maxDisplayedColumns =
      this._defaultDisplayedColumns.length + this.configuredPlanTypes.length;

    this.dataSources = this.mappedTableData.map((table) => {
      this.displayedColumns.push([...this._defaultDisplayedColumns]);

      const dataSource = new MatTableDataSource(table.bands);

      dataSource.filterPredicate = (
        row: BandTransformed,
        filter: string,
      ): boolean =>
        [row.bandingType, row.description]
          .join('__')
          .toLowerCase()
          .includes(filter.trim().toLowerCase());

      return dataSource;
    });

    this.links = this.mappedTableData.map(({ table }) => table);
    this.activeLink = this.links[0];

    this.currencyCode = this._countryCodeService.findCurrencyByCountryCode(
      this.pageData.countryCode,
    );

    this.saveOverrideDetailsRequest =
      new AsyncRequestHelper<ApiClient.UpsertCountryRegionTaxRateOverridesCommand>(
        async (req) => {
          await this._specificCountryTaxRatesPageService
            .upsertCountryRegionTaxRateOverrides(req)
            .toPromise();

          this._pageEdits.markAsSaved();

          return this._router.navigateByUrl(this.navigationPath());
        },
      );

    this.saveHypoOverrideDetailsRequest =
      new AsyncRequestHelper<ApiClient.UpsertHypoCountryRegionTaxRateOverridesCommand>(
        async (req) => {
          await this._hypoSpecificCountryTaxRatesPageService
            .upsertHypoCountryRegionTaxRateOverrides(req)
            .toPromise();

          this._pageEdits.markAsSaved();

          return this._router.navigateByUrl(this.navigationPath());
        },
      );
    this.showOverriddenColumns();
  }

  showOverriddenColumns() {
    this.displayedColumns.forEach((_, i) => {
      if (this.pageData.result.taxRateTables[i] !== undefined) {
        const taxTypeValue =
          this.pageData.result.taxRateTables[i].taxType.value;
        const items = (
          this.pageData.result.overriddenTaxTypePlanType || []
        ).filter((item) => item.taxTypeValue === taxTypeValue);

        const sortedHypoCustomTaxTypes = items.sort((a, b) => {
          if (a.planType < b.planType) return -1;
          if (a.planType > b.planType) return 1;
          return 0;
        });
        sortedHypoCustomTaxTypes.forEach((item) => {
          this.toggleCustomPlanTypeColumn(item.planType, i);
        });
      }
    });
  }
  toggleCustomPlanTypeColumn(planType: ApiClient.PlanType, tableIndex: number) {
    const columnExists = this.displayedColumns[tableIndex].includes(planType);
    if (!columnExists) {
      this.displayedColumns[tableIndex].push(planType);
      return;
    }
    this.displayedColumns[tableIndex] = this.displayedColumns[
      tableIndex
    ].filter((value) => value !== planType);
  }

  get hasTableData() {
    return this.mappedTableData.length > 0;
  }

  get countryName() {
    return this.pageData.countryName;
  }

  get regionName() {
    return this.pageData.regionName;
  }

  get taxRatesData() {
    return this.pageData.result;
  }

  get activeTableIndex() {
    return this.links.indexOf(this.activeLink);
  }

  get configuredPlanTypeValues() {
    return this.configuredPlanTypes.map(({ optionValue }) => optionValue);
  }

  get allPlanTypesSelected() {
    if (!this.hasTableData) return false;

    return (
      this.displayedColumns[this.activeTableIndex].length ===
      this._maxDisplayedColumns
    );
  }

  get somePlanTypesSelected() {
    if (!this.hasTableData) return false;

    return (
      this._defaultDisplayedColumns.length <
        this.displayedColumns[this.activeTableIndex].length &&
      this.displayedColumns[this.activeTableIndex].length <
        this._maxDisplayedColumns
    );
  }

  showDropdownIcon(planType?: ApiClient.PlanType) {
    if (!this.hasTableData) return false;

    const _data = this.dataSources[this.activeTableIndex].data;

    const dropdownIcons = {
      CashBonus: _data
        .map(({ CashBonus }) => CashBonus)
        .some(this._differentValues),
      RestrictedStockUnit: _data
        .map(({ RestrictedStockUnit }) => RestrictedStockUnit)
        .some(this._differentValues),
      StockOption: _data
        .map(({ StockOption }) => StockOption)
        .some(this._differentValues),
    };

    return planType ? dropdownIcons[planType] : dropdownIcons;
  }

  getIcon(values: PlanTypeValue) {
    return this._differentValues(values) ? 'change_history' : null;
  }

  taxRateFormulaExists(tableIndex: number) {
    return this.mappedTableData[tableIndex].bands.find(
      (band) => !!band.taxRateFormula,
    );
  }

  getDefaultTaxRateHeader(tableIndex: number) {
    return this.taxRateFormulaExists(tableIndex)
      ? '/ tax amount methodology'
      : '';
  }

  toggleAllPlanTypes(
    changeEvent: MatCheckboxChange,
    selectAllPlanTypes: MatCheckbox,
  ) {
    if (!this.hasTableData) return;

    if (changeEvent.checked) {
      selectAllPlanTypes.checked = true;

      this.displayedColumns[this.activeTableIndex] = [
        ...this._defaultDisplayedColumns,
        ...this.configuredPlanTypeValues,
      ];
    } else {
      selectAllPlanTypes.checked = false;

      this.displayedColumns[this.activeTableIndex] = [
        ...this._defaultDisplayedColumns,
      ];
    }
  }

  togglePlanType(event: MatCheckboxChange, planType: ApiClient.PlanType) {
    if (!this.hasTableData) return;

    if (event.checked) {
      this.displayedColumns[this.activeTableIndex] = [
        ...this._defaultDisplayedColumns,
        ...this.configuredPlanTypeValues.filter(
          (v) =>
            this.displayedColumns[this.activeTableIndex].includes(v) ||
            v === planType,
        ),
      ];
    } else {
      this.displayedColumns[this.activeTableIndex] = this.displayedColumns[
        this.activeTableIndex
      ].filter((c) => c !== planType);
    }
  }

  toggleSearch(input: HTMLInputElement) {
    this.showSearch = !this.showSearch;

    if (this.showSearch) {
      input.focus();
    } else {
      input.blur();
    }
  }

  updateOverride(
    band: BandTransformed,
    planType: ApiClient.PlanType,
    newOverrideValue: string | number | null,
  ) {
    this._pageEdits.markAsEdited();

    if (newOverrideValue === null) {
      band[planType].newValue = null;
    } else {
      const parsedOverrideValue =
        typeof newOverrideValue === 'number'
          ? newOverrideValue
          : parseFloat(newOverrideValue);

      if (parsedOverrideValue > 100 || parsedOverrideValue < 0) {
        return;
      }

      band[planType].newValue = parsedOverrideValue;
    }
  }

  async cancel() {
    await this._router.navigateByUrl(this.navigationPath());
  }

  async saveAndContinue() {
    const overrides:
      | ApiClient.UpsertCountryTaxRateOverrideDto[]
      | ApiClient.UpsertHypoCountryTaxRateOverrideDto[] = [];

    this.mappedTableData.map((table) => {
      table.bands.forEach((band) => {
        const planTypeOverrides:
          | ApiClient.UpsertCountryTaxRatePlanTypeOverrideDto[]
          | ApiClient.UpsertHypoCountryTaxRatePlanTypeOverrideDto[] = [];

        const cbOverride = band.taxRateOverrides.planTypeOverrides.find(
          (override) => override.planType === ApiClient.PlanType.CashBonus,
        );
        const cbNewValue = band.CashBonus.newValue;

        const soOverride = band.taxRateOverrides.planTypeOverrides.find(
          (override) => override.planType === ApiClient.PlanType.StockOption,
        );
        const soNewValue = band.StockOption.newValue;

        const rsuOverride = band.taxRateOverrides.planTypeOverrides.find(
          (override) =>
            override.planType === ApiClient.PlanType.RestrictedStockUnit,
        );
        const rsuNewValue = band.RestrictedStockUnit.newValue;

        if (cbNewValue === null) {
          // Using default tax rate, no override needed
        } else if (cbNewValue === 0 || cbNewValue) {
          planTypeOverrides.push(
            new (this.hypo()
              ? ApiClient.UpsertCountryTaxRatePlanTypeOverrideDto
              : ApiClient.UpsertHypoCountryTaxRatePlanTypeOverrideDto)({
              planType: ApiClient.PlanType.CashBonus,
              override: cbNewValue,
            }),
          );
        } else if (cbOverride) {
          planTypeOverrides.push(cbOverride);
        }

        if (soNewValue === null) {
          // Using default tax rate, no override needed
        } else if (soNewValue === 0 || soNewValue) {
          planTypeOverrides.push(
            new (this.hypo()
              ? ApiClient.UpsertCountryTaxRatePlanTypeOverrideDto
              : ApiClient.UpsertHypoCountryTaxRatePlanTypeOverrideDto)({
              planType: ApiClient.PlanType.StockOption,
              override: soNewValue,
            }),
          );
        } else if (soOverride) {
          planTypeOverrides.push(soOverride);
        }

        if (rsuNewValue === null) {
          // Using default tax rate, no override needed
        } else if (rsuNewValue === 0 || rsuNewValue) {
          planTypeOverrides.push(
            new (this.hypo()
              ? ApiClient.UpsertCountryTaxRatePlanTypeOverrideDto
              : ApiClient.UpsertHypoCountryTaxRatePlanTypeOverrideDto)({
              planType: ApiClient.PlanType.RestrictedStockUnit,
              override: rsuNewValue,
            }),
          );
        } else if (rsuOverride) {
          planTypeOverrides.push(rsuOverride);
        }

        overrides.push(
          new (this.hypo()
            ? ApiClient.UpsertCountryTaxRateOverrideDto
            : ApiClient.UpsertHypoCountryTaxRateOverrideDto)({
            taxType: band.taxType.value,
            residency: band.taxRateType.residency,
            bandingType: band.taxRateType.bandingType,
            taxRateTypeCode: band.taxRateType.code,
            lowerThreshold: band.lowerThreshold ?? 0,
            upperThreshold: band.upperThreshold,
            planTypeOverrides: planTypeOverrides,
          }),
        );
      });
    });

    if (this.hypo()) {
      await this.saveHypoOverrideDetailsRequest
        .execute(
          new ApiClient.UpsertHypoCountryRegionTaxRateOverridesCommand({
            clientId: this._clientContextService.value.client.id,
            countryCode: this.pageData.countryCode,
            regionCode: this.pageData.regionCode || null,
            hypoRegionTaxRatePage: true,
            overrides,
          }),
        )
        .toPromise();
    } else {
      await this.saveOverrideDetailsRequest
        .execute(
          new ApiClient.UpsertCountryRegionTaxRateOverridesCommand({
            clientId: this._clientContextService.value.client.id,
            countryCode: this.pageData.countryCode,
            regionCode: this.pageData.regionCode || null,
            overrides,
          }),
        )
        .toPromise();
    }
  }

  private _differentValues({ defaultValue, newValue }: PlanTypeValue) {
    return newValue !== null && newValue !== defaultValue;
  }
}
