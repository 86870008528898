import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
  selector: 'gax-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrl: './page-footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageFooterComponent {
  @HostBinding('class.full-width')
  fullWidth: boolean = true;
}
