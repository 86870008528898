import { Injectable } from '@angular/core';
import { CountryRegionDataService } from 'gain-web/shared-modules/country-region-data/country-region-data.service';
import { RegionNameService } from 'gain-lib/geography';

@Injectable()
export class GainRegionNameService implements RegionNameService {
  constructor(private _countryRegion: CountryRegionDataService) {}

  findRegionName(
    {
      countryCode,
      regionCode,
    }: {
      countryCode: string[] | string;
      regionCode: string;
    },
    includeCode: boolean = false,
  ): string | undefined {
    const regions =
      this._countryRegion.getSupportedRegionsByCountryCode(countryCode);

    if (regions != null) {
      const region = regions.find((r) => r.code === regionCode);
      if (region != null) {
        if (includeCode) {
          return `${region.name} (${region.code})`;
        }
        return region.name;
      }
    }

    return undefined;
  }
}
