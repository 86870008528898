import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/overlay';

export interface SidePanelDialogConfig {}

@Injectable()
export class SidePanelDialogService {
  constructor(private _matDialog: MatDialog) {}

  // eslint-disable-next-line
  open<C, D, R = any>(
    component: ComponentType<C>,
    data: D,
  ): MatDialogRef<C, R> {
    return this._matDialog.open<C, D, R>(component, {
      height: '100%',
      width: '521px',
      position: { right: '0' },
      data,
    });
  }
}
