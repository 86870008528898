<div class="money-input-container">
  <gax-currency-select
    [label]="label()"
    [hint]="currencyCodeHint()"
    [requiredErrorMessage]="currencyCodeRequiredErrorMessage()"
    [currencyCodeFormControlName]="currencyCodeFormControlName()"
  ></gax-currency-select>
  <gax-money-amount-input
    [amountFormControlName]="amountFormControlName()"
    [currencyCode]="currencyCodeControl.value"
    [requiredErrorMessage]="amountRequiredErrorMessage()"
    [hint]="amountHint()"
  ></gax-money-amount-input>
</div>
