import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountryNamePipe, RegionNamePipe } from 'gain-lib/geography';
import { CountryComponent } from './country/country.component';
import { CountryInlineListComponent } from './country-inline-list/country-inline-list.component';
import { RegionComponent } from './region/region.component';

@NgModule({
  declarations: [
    CountryNamePipe,
    RegionNamePipe,
    CountryComponent,
    CountryInlineListComponent,
    RegionComponent,
  ],
  imports: [CommonModule],
  exports: [
    CountryNamePipe,
    RegionNamePipe,
    CountryComponent,
    CountryInlineListComponent,
    RegionComponent,
  ],
})
export class GaGeographyModule {}
