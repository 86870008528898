import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appDragDrop]',
})
export class DragDropDirective {
  @Output()
  fileDropped = new EventEmitter<DragEvent>();

  constructor() {}

  @HostListener('dragover', ['$event'])
  onDrag(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.fileDropped.emit(event);
  }
}
