<gax-select-form-field
  [showInfoIcon]="displayCountryInfo || displaySocialTaxInfo"
  (infoIconClicked)="
    displaySocialTaxInfo
      ? showSocialTaxInformationModal()
      : showCountryInformationModal()
  "
  [label]="countryLabel"
  [formControl]="countryControl"
  (searchChanged)="onSearchCountry($event)"
  [required]="countryRequired"
>
  <gax-select-trigger>
    {{ countryControl.value | countryName: true }}
  </gax-select-trigger>
  <gax-option
    *ngFor="let option of filteredCountries | async"
    [value]="option.code"
    [disabled]="disabledCountries?.includes(option.code) || false"
    >{{ option.code | countryName: true }}
  </gax-option>
  <gax-error *ngIf="countryControl.hasError('required')">
    {{ countryLabel }} required
  </gax-error>
  <gax-error
    *ngIf="this.countryControl.hasError('duplicateIncomeBandingCountry')"
  >
    This country has already been added to the income tax
  </gax-error>
  <gax-error *ngIf="this.countryControl.hasError('duplicateSocialTaxCountry')">
    This country has already been added to the social tax
  </gax-error>
</gax-select-form-field>

<gax-select-form-field
  class="mt-15"
  *ngIf="
    locationFormHelper.supportsRegionCalculations(countryControl.value) &&
    regionControl
  "
  [label]="regionLabel"
  [hint]="
    regionControl.invalid && regionControl.pristine
      ? regionLabel + ' required for ' + (countryControl.value | countryName)
      : ''
  "
  [formControl]="regionControl"
  [search]="true"
  (searchChanged)="onSearchRegion($event)"
  [required]="regionRequired"
>
  <gax-select-trigger>
    {{
      regionControl.value
        ? (regionControl.value | regionName: countryControl.value : true)
        : ""
    }}
  </gax-select-trigger>
  <gax-option
    *ngFor="let option of filteredRegions | async"
    [value]="option.code"
    [disabled]="disabledCountries?.includes(option.code) || false"
    >{{ option.code | regionName: countryControl.value : true }}
  </gax-option>
  <gax-error *ngIf="regionControl.hasError('required')">
    {{ regionLabel }} required for
    {{ countryControl.value | countryName }}
  </gax-error>
</gax-select-form-field>
