import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ErrorComponent } from 'gain-lib/error';
import { GaRequiredAsteriskComponent } from 'gain-lib/ga-required-asterisk';
import { GaHelpIconComponent } from 'gain-lib/help-icon/help-icon.component';
import { OptionGroupComponent } from './option-group/option-group.component';
import { OptionComponent } from './option/option.component';
import { GaSelectFormFieldComponent } from './select-form-field.component';
import { GaSelectTriggerComponent } from './select-trigger/select-trigger.component';

@NgModule({
  declarations: [
    OptionComponent,
    GaSelectFormFieldComponent,
    GaSelectTriggerComponent,
    OptionGroupComponent,
  ],
  imports: [
    CommonModule,
    OverlayModule,
    MatIconModule,
    ErrorComponent,
    MatProgressSpinnerModule,
    MatIconModule,
    MatChipsModule,
    GaRequiredAsteriskComponent,
    GaHelpIconComponent,
  ],
  exports: [
    OptionComponent,
    OptionGroupComponent,
    GaSelectFormFieldComponent,
    GaSelectTriggerComponent,
  ],
})
export class GASelectFormFieldModule {}
