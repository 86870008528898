<mat-form-field [class.full-width]="fullWidth">
  @if (label()) {
    <mat-label>{{ label() }}</mat-label>
  }

  <mat-select
    [formControl]="currencyCodeControl"
    (selectionChange)="resetFilter()"
  >
    <mat-select-trigger>{{ currencyCodeControl.value }}</mat-select-trigger
    ><gax-select-search
      (searchChange)="currencyCodeFilter.set($event)"
    ></gax-select-search>
    @for (option of filteredCurrencyOptions(); track option) {
      <mat-option [value]="option.currencyCode">
        <div class="currency-option-wrapper">
          <div class="currency-code">{{ option.currencyCode }}</div>
          <div class="currency-name">{{ option.currencyName }}</div>
        </div>
      </mat-option>
    }
  </mat-select>
  @if (currencyCodeControl.hasError("required")) {
    <mat-error>{{ requiredErrorMessage() }}</mat-error>
  }

  @if (hint()) {
    <mat-hint>{{ hint() }}</mat-hint>
  }
</mat-form-field>
