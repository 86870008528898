<div class="ga-select-label" [class.required]="required">{{ label }}</div>
<div #container>
  <div class="control" cdkOverlayOrigin #origin="cdkOverlayOrigin">
    <!-- Add chips here Chip 'x' removal just deselects component -->
    <div [ngSwitch]="!!selectTrigger">
      <ng-container *ngSwitchDefault class="ga-select-min-line">
        <ng-container *ngIf="autocomplete">
          <input
            *ngIf="isOpen && showAutocompleteInput"
            #autocompleteInput
            class="autocompleteInput overlay-on-top"
            type="text"
            [value]="autocompleteSearchTerm"
            (blur)="toggleAutocompleteInput(false)"
            (input)="onAutocompleteInputChange($event)"
          />
          <span
            *ngIf="!isOpen || !showAutocompleteInput"
            class="selected-value"
            [class.overlay-on-top]="isOpen"
            (click)="toggleAutocompleteInput(true)"
            >{{ displayValue || "" }}</span
          >
        </ng-container>

        <ng-container *ngIf="!autocomplete">
          <span class="selected-value">{{ displayValue || "" }}</span>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="true">
        <ng-container *ngTemplateOutlet="selectTriggerTemplate"></ng-container>
      </ng-container>
    </div>

    <div class="suffix">
      <mat-icon color="warn" class="color-warn" [@fadeIn]>error</mat-icon>
      <div class="caret-wrapper" *ngIf="!hideCaret">
        <div class="drop-down-caret"></div>
      </div>
      <mat-spinner *ngIf="loading$ | async" [diameter]="24"></mat-spinner>
    </div>
  </div>

  <div *ngIf="!(firstError && touched)" class="hint">
    {{ hint }}
  </div>

  <ng-container
    *ngIf="firstError && touched"
    [ngTemplateOutlet]="firstError.template"
  ></ng-container>
</div>

<ng-template
  cdkConnectedOverlay
  #overlay="cdkConnectedOverlay"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOffsetX]="0"
  [cdkConnectedOverlayOffsetY]="0"
  [cdkConnectedOverlayWidth]="
    origin.elementRef.nativeElement.getBoundingClientRect().width
  "
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  cdkConnectedOverlayPanelClass="ga-select-overlay-panel"
  (backdropClick)="closePanel()"
  (detach)="closePanel()"
  (positionChange)="onPanelPositionChange($event)"
>
  <div
    class="panel"
    [class.panelAbove]="panelAbove"
    [@dropDown]
    (@dropDown.done)="onPanelAnimationsDone($event, overlay.overlayRef)"
  >
    <div
      *ngIf="selectAll"
      class="select-all-option"
      (click)="toggleAllSelection()"
    >
      <input type="checkbox" [checked]="checkAllSelected()" />
      <app-option class="unset-style" #selectAllOption> Select all</app-option>
    </div>
    <div class="drop-down-options">
      <ng-content select="app-option"></ng-content>
    </div>
  </div>
</ng-template>
