import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

@Component({
  selector: 'gax-page-section-header',
  templateUrl: './page-section-header.component.html',
  styleUrl: './page-section-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageSectionHeaderComponent {
  @Input()
  @HostBinding('class.removeHeaderBorder')
  removeHeaderBorder: boolean = false;
}
