<div>
  <h1 mat-dialog-title>{{ popupHeaderText }}</h1>
  <mat-dialog-content class="content-modal">
    <p>{{ popupMessageText }}</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button color="primary" (click)="onConfirmClick()" tabindex="1">
      {{ popupConfirmText }}
    </button>
    <button
      mat-button
      mat-dialog-close
      tabindex="-1"
      (click)="dialogRef.close()"
    >
      {{ popupCancelText }}
    </button>
  </mat-dialog-actions>
</div>
