import {
  ChangeDetectionStrategy,
  Component,
  computed,
  HostBinding,
  Input,
  input,
  OnInit,
  signal,
} from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroupDirective,
} from '@angular/forms';

import { CurrencyNameService } from 'gain-lib/money';
import { CurrencyCode, currencyCodes } from 'gain-lib/money/src/currency';
import { sortBy } from 'lodash-es';

@Component({
  selector: 'gax-currency-select',
  templateUrl: './currency-select.component.html',
  styleUrls: ['./currency-select.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrencySelectComponent implements OnInit {
  label = input<string | null | undefined>('Currency');
  currencyCodeControl!: FormControl<string>;
  currencyCodeFormControlName = input.required<string>();
  requiredErrorMessage = input<string>('Currency required');
  currencyCodeOptions = input<CurrencyCode[]>([...currencyCodes]);
  hint = input<string | null | undefined>();

  // full-width class - used in add-rsu-transaction-page to not look weird
  @HostBinding('class.full-width')
  @Input()
  fullWidth = false;

  protected currencyCodeFilter = signal<string | null>(null);
  resetFilter() {
    this.currencyCodeFilter.set(null);
  }

  filteredCurrencyOptions = computed(() => {
    const options = this.currencyCodeOptions()
      .map((currencyCode) => ({
        currencyName: this._cn.findCurrencyName(currencyCode),
        currencyCode,
      }))
      .filter(({ currencyName, currencyCode }) => {
        const ccf = this.currencyCodeFilter()?.toLowerCase();
        if (ccf == null) {
          return true;
        }
        return (
          currencyCode.toLowerCase().includes(ccf) ||
          currencyName?.toLowerCase().includes(ccf)
        );
      });
    return sortBy(options, (o) => o.currencyName);
  });

  constructor(
    private formGroupDirective: FormGroupDirective,
    private _cn: CurrencyNameService,
  ) {}

  ngOnInit() {
    this.currencyCodeControl = this.formGroupDirective.form.controls[
      this.currencyCodeFormControlName()
    ] as FormControl;
  }
}
