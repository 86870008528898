export function toFriendlyDuration(ms?: number): string | null {
  function roundedToFixed(input: number, digits: number) {
    const rounder = Math.pow(10, digits);
    return Number((Math.round(input * rounder) / rounder).toFixed(digits));
  }

  if (ms == null) {
    return null;
  }
  if (ms < 1) {
    return `${ms}ms`;
  }
  if (ms < 1000) {
    return `${roundedToFixed(ms, 0)}ms`;
  }
  if (ms >= 1000 && ms < 10_000) {
    const secondsOneDigit = roundedToFixed(ms / 1000, 1);
    return `${secondsOneDigit}s`;
  }
  if (ms >= 10_000 && ms < 60_000) {
    const secondsNoDigits = roundedToFixed(ms / 1000, 0);
    return `${secondsNoDigits}s`;
  }
  if (ms >= 60_000 && ms < 60_000 * 60) {
    const minutes = Math.floor(ms / 60_000);
    const msRemainder = ms - minutes * 60_000;
    const sRemainder = roundedToFixed(msRemainder / 1000, 0);
    return sRemainder > 0 ? `${minutes}m ${sRemainder}s` : `${minutes}m`;
  }
  const hoursInMs = 3_600_000;
  const hours = Math.floor(ms / hoursInMs);
  const msRemainder = ms - hours * hoursInMs;
  const minutesRemainder = Math.floor(msRemainder / 60_000);
  return `${hours}h ${minutesRemainder}m`;
}
