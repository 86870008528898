import {
  AnimationEvent,
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { SelectionModel } from '@angular/cdk/collections';
import {
  ConnectedOverlayPositionChange,
  OverlayRef,
} from '@angular/cdk/overlay';
import {
  AfterContentInit,
  AfterViewInit,
  Attribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Injector,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl,
  Validators,
} from '@angular/forms';
import { Subject, merge, of } from 'rxjs';
import { startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { GainErrorComponent } from './error/error.component';
import { GainOptionComponent } from './option/option.component';
import { GaSelectTriggerComponent } from './select-trigger/select-trigger.component';

export type SelectValue<T> = T | T[] | null;

@Component({
  selector: 'app-select-form-field',
  templateUrl: './select-form-field.component.html',
  styleUrls: ['./select-form-field.component.scss'],
  animations: [
    trigger('dropDown', [
      state('void', style({ height: '0px', opacity: 0 })),
      state('*', style({ height: 'fit-content', opacity: 1 })),
      transition(':enter', [animate('300ms linear')]),
      transition(':leave', [animate('500ms ease-out')]),
    ]),
    trigger('fadeIn', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition(':enter', [animate('300ms linear')]),
    ]),
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: GaGainSelectFormFieldComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GaGainSelectFormFieldComponent<T>
  implements AfterContentInit, ControlValueAccessor, AfterViewInit
{
  /**
   * Input
   */
  @Input()
  label = '';

  @Input()
  hideAsterisk = false;

  @Input()
  displayWith: ((value: T | T[]) => string | string[] | number) | null = null;

  @Input()
  hint?: string;

  @Input()
  autocomplete = false;

  @Input()
  hideCaret = false;

  @Input()
  selectAll = false;

  @Input()
  loading$ = of(false);

  @Input()
  compareWith: (v1: T | null, v2: T | null) => boolean = (v1, v2) => v1 === v2;

  /**
   * Input - HostBinding
   */
  @Input()
  @HostBinding('class.disabled')
  disabled = false;

  @Input()
  @HostBinding('attr.tabIndex')
  tabIndex = 0;

  /**
   * HostBinding
   */
  @HostBinding('class.active')
  active = false;

  /**
   * Input - set / get
   */
  @Input()
  set value(value: SelectValue<T>) {
    this._setValue(value);
    this._highlightSelectedOptions();
    this.onChange(this.value);
    this._cd.markForCheck();
  }

  get value() {
    if (this.selectionModel.isMultipleSelection()) {
      return this.selectionModel.isEmpty() ? [] : this.selectionModel.selected;
    }

    return this.selectionModel.isEmpty()
      ? null
      : this.selectionModel.selected[0];
  }

  /**
   * Output
   */
  @Output()
  readonly opened = new EventEmitter<void>();

  @Output()
  readonly closed = new EventEmitter<void>();

  @Output()
  readonly selectionChanged = new EventEmitter<SelectValue<T>>();

  @Output()
  readonly searchChanged = new EventEmitter<string>();

  /**
   * HostListener
   */
  @HostListener('blur')
  markAsTouched() {
    if (!(this.disabled || (this.autocomplete && this.isOpen))) {
      this.onTouched();
      this.touched = true;
      this.active = false;
      this._cd.markForCheck();
    }
  }

  @HostListener('click')
  openPanel() {
    if (!this.disabled) {
      this.isOpen = true;
      this.active = true;
      if (this.autocomplete) {
        this.showAutocompleteInput = true;
        setTimeout(() => {
          this.autocompleteInput.nativeElement.focus();
        }, 0);
      }
      this._cd.markForCheck();
    }
  }

  @HostListener('keydown', ['$event'])
  protected onKeyDown(e: KeyboardEvent) {
    if (e.key === 'ArrowDown' && !this.isOpen) {
      this.openPanel();
    }

    if (e.key === 'Tab' && this.isOpen) {
      this.closePanel();
    }

    if ((e.key === 'ArrowDown' || e.key === 'ArrowUp') && this.isOpen) {
      this._listKeyManager.onKeydown(e);
    }

    if (
      (e.key === 'Enter' || e.key === ' ') &&
      this.isOpen &&
      this._listKeyManager.activeItem
    ) {
      e.preventDefault();
      this._handleSelection(this._listKeyManager.activeItem);
    }
  }

  /**
   * ViewChild
   */
  @ViewChild('autocompleteInput')
  autocompleteInput!: ElementRef;

  /**
   * ContentChild
   */
  @ContentChild(GaSelectTriggerComponent)
  selectTrigger!: GaSelectTriggerComponent;

  /**
   * ContentChildren
   */
  @ContentChildren(GainOptionComponent, { descendants: true })
  options!: QueryList<GainOptionComponent<T>>;

  @ContentChildren(GainErrorComponent)
  errors!: QueryList<GainErrorComponent>;

  /**
   * Public properties
   */
  panelAbove = false;
  multiple = false;
  allSelected = false;
  isOpen = false;
  touched = false;
  required = false;
  hasError = false;
  firstError!: GainErrorComponent;
  selectionModel = new SelectionModel<T>(this.multiple);
  selectTriggerTemplate!: TemplateRef<any>;
  showAutocompleteInput = false;
  autocompleteSearchTerm = '';

  /**
   * Private properties
   */
  private _ngControl!: NgControl;
  private _listKeyManager!: ActiveDescendantKeyManager<GainOptionComponent<T>>;
  private _optionMap = new Map<T | T[] | null, GainOptionComponent<T>>();
  private _onDestroy$ = new Subject();

  constructor(
    private _injector: Injector,
    @Attribute('multiple') multiple: string,
    private _cd: ChangeDetectorRef,
    private _hostEl: ElementRef,
  ) {
    this.multiple = multiple !== null;
    this.selectionModel = new SelectionModel<T>(this.multiple);
  }

  /**
   * Lifecycle methods
   */
  ngAfterContentInit() {
    if (this.selectTrigger) {
      this.selectTriggerTemplate = this.selectTrigger.template;
    }

    this._updateFirstError();

    this.errors.changes.pipe(takeUntil(this._onDestroy$)).subscribe(() => {
      this._updateFirstError();
    });

    this._listKeyManager = new ActiveDescendantKeyManager(
      this.options,
    ).withWrap();

    this._listKeyManager.change
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((itemIndex) => {
        // Scroll to active element
        this.options.get(itemIndex)?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      });

    this.selectionModel.changed
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((values) => {
        if (values.removed.length > 0 && !values.source.hasValue()) {
          this.onTouched();
          this.touched = true;
          this.active = false;
          this._updateFirstError();
          this._cd.markForCheck();
        }

        values.removed.forEach((rv) => this._optionMap.get(rv)?.deselect());
        values.added.forEach((av) => {
          this._optionMap.get(av)?.highlightAsSelected();
        });
      });

    this.options.changes
      .pipe(
        startWith<QueryList<GainOptionComponent<T>>>(this.options),
        tap(() => {
          this._refreshOptionsMap();
          this.checkAllSelected();
        }),
        tap(() => queueMicrotask(() => this._highlightSelectedOptions())),
        switchMap((options: QueryList<GainOptionComponent<T>>) =>
          merge(...options.map((o) => o.selected)),
        ),
        takeUntil(this._onDestroy$),
      )
      .subscribe((selectedOption: GainOptionComponent<T>) => {
        this._handleSelection(selectedOption);
        this._cd.markForCheck();
      });
  }

  ngAfterViewInit() {
    this._ngControl = this._injector.get(NgControl);
    this.required = this.hideAsterisk
      ? false
      : (this._ngControl.control?.hasValidator(Validators.required) ?? false);
    this._cd.markForCheck();
  }

  /**
   * Public methods
   */
  writeValue(value: SelectValue<T>): void {
    this._setValue(value);
    this._highlightSelectedOptions();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  clearSelection(e?: Event) {
    e?.stopPropagation();
    this.selectionModel.clear();
    this.selectionChanged.emit(this.value);
    this.onChange(this.value);
    this._cd.markForCheck();
  }

  checkAllSelected(): boolean {
    if (this._optionMap.keys === null) false;

    const allSelected: boolean =
      this._optionMap.size === this.selectionModel.selected.length;

    this.allSelected = allSelected;
    this._cd.markForCheck();

    return allSelected;
  }

  toggleAllSelection() {
    const valuesWithUpdatedReferences: any[] = [];

    this._optionMap.forEach(
      (value: GainOptionComponent<T>, key: T | T[] | null) => {
        const correspondingOption = this._findOptionsByValue(key);
        valuesWithUpdatedReferences.push(
          correspondingOption ? correspondingOption.value! : value,
        );
      },
    );

    if (this.allSelected) {
      this.allSelected = false;
      this.clearSelection();
      this._cd.markForCheck();
      return;
    }

    this.allSelected = true;
    this.selectionModel.clear();
    this.selectionModel.select(...valuesWithUpdatedReferences);
    this._findOptionsByValue(this.value)?.highlightAsSelected();
    this.selectionChanged.emit(this.value);
    this.onChange(this.value);
  }

  onPanelPositionChange(position: ConnectedOverlayPositionChange) {
    this.panelAbove = position.connectionPair.originY === 'top';
  }

  onPanelAnimationsDone(
    { fromState, toState }: AnimationEvent,
    overlayRef: OverlayRef,
  ) {
    if (fromState === 'void' && toState === null && this.isOpen) {
      this.opened.emit();
    }
    if (fromState === null && toState === 'void' && !this.isOpen) {
      this.closed.emit();
    }
    overlayRef.updatePosition();
    this._cd.markForCheck();
  }

  closePanel() {
    this.isOpen = false;
    this.onTouched();
    this._hostEl.nativeElement.focus();
    this.touched = true;
    this._cd.markForCheck();
  }

  /**
   * Protected methods
   */
  protected get displayValue() {
    if (this.displayWith && this.value) {
      if (Array.isArray(this.value)) {
        return this.displayWith(this.value);
      }
      return this.displayWith(this.value);
    }
    return this.value;
  }

  protected onChange: (newValue: SelectValue<T>) => void = () => {};
  protected onTouched: () => void = () => {};

  protected toggleAutocompleteInput(showInput: boolean) {
    this.showAutocompleteInput = showInput;

    if (showInput) {
      this.searchChanged.emit('');
      return;
    }
  }

  protected onAutocompleteInputChange(e: Event) {
    this.searchChanged.emit((e.target as HTMLInputElement).value);
  }

  /**
   * Private methods
   */
  private _updateFirstError() {
    this.firstError = this.errors.first;
  }

  private _findOptionsByValue(value: T | T[] | null) {
    if (this._optionMap.has(value)) {
      return this._optionMap.get(value);
    }

    if (Array.isArray(value)) {
      return (
        this.options &&
        this.options.find((o) =>
          this.compareWith(o.value, value.filter((val) => val === o.value)[0]),
        )
      );
    }

    return (
      this.options && this.options.find((o) => this.compareWith(o.value, value))
    );
  }

  private _handleSelection(selectedOption: GainOptionComponent<T>) {
    if (this.disabled) {
      return;
    }

    if (selectedOption.value) {
      this.selectionModel.toggle(selectedOption.value);
      this.selectionChanged.emit(this.value);
      this.onChange(this.value);
    }

    if (!this.selectionModel.isMultipleSelection()) {
      this.closePanel();
    }
  }

  private _highlightSelectedOptions() {
    const valuesWithUpdatedReferences = this.selectionModel.selected.map(
      (value) => {
        const correspondingOption = this._findOptionsByValue(value);

        return correspondingOption ? correspondingOption.value! : value;
      },
    );

    this.selectionModel.clear();
    this.selectionModel.select(...valuesWithUpdatedReferences);
    this._findOptionsByValue(this.value)?.highlightAsSelected();
    this._cd.markForCheck();
  }

  private _refreshOptionsMap() {
    this._optionMap.clear();
    this.selectAll = this.multiple && this.options.length > 2;

    this.options.forEach((o) => {
      if (this.multiple) {
        o.addMultipleSelect();
      }

      this._optionMap.set(o.value, o);
    });
  }

  private _setValue(value: SelectValue<T>) {
    this.selectionModel.clear();

    if (!value) {
      return;
    }

    if (Array.isArray(value)) {
      this.selectionModel.select(...value);
      return;
    }

    this.selectionModel.select(value);
  }
}
