import {
  ChangeDetectionStrategy,
  Component,
  input,
  OnDestroy,
  OnInit,
  signal,
} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'gax-page-section-table-header-bulk-actions',
  templateUrl: './page-section-table-header-bulk-actions.component.html',
  styleUrl: './page-section-table-header-bulk-actions.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageSectionTableHeaderBulkActionsComponent<T>
  implements OnInit, OnDestroy
{
  onDestroy$ = new Subject();
  selection = input.required<SelectionModel<T>>();
  protected hasSelections = signal(false);

  ngOnInit() {
    this.selection()
      .changed.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        const hasSelections = this.selection().hasValue();
        this.hasSelections.set(hasSelections);
      });
  }
  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
