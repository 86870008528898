import { Injectable, Injector } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRouteSnapshot,
  DefaultTitleStrategy,
  NavigationEnd,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { flatMap } from 'lodash-es';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export interface Breadcrumb {
  title: string;
  path?: string;
}

export interface BreadcrumbConfig {
  link?: boolean;
  title?: string;
}

@Injectable({ providedIn: 'root' })
export class Breadcrumbs {
  private _updateBreadcrumbs?: Subscription;
  private _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);

  get value(): Breadcrumb[] {
    this.init();
    return this._breadcrumbs$.getValue();
  }

  get valueChanges(): Observable<Breadcrumb[]> {
    this.init();
    return this._breadcrumbs$.asObservable();
  }

  constructor(private _injector: Injector) {}

  private init() {
    if (this._updateBreadcrumbs != null) {
      return;
    }
    const router = this._injector.get(Router);
    const title = this._injector.get(Title);

    this._breadcrumbs$.next(
      this.getBreadcrumbs(router.routerState.snapshot, title),
    );

    this._updateBreadcrumbs = router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        map(() => this.getBreadcrumbs(router.routerState.snapshot, title)),
      )
      .subscribe(this._breadcrumbs$);
  }

  public getBreadcrumbs(routerState: RouterStateSnapshot, titleService: Title) {
    let route: ActivatedRouteSnapshot | null = routerState.root;
    const breadcrumbs: Breadcrumb[] = [];
    while (route != null) {
      const breadcrumb = buildBreadcrumb(route, titleService);
      if (breadcrumb != null) {
        breadcrumbs.push(breadcrumb);
      }
      route = route.firstChild;
    }
    return breadcrumbs;
  }

  public getTitle(
    routerStateSnapshot: RouterStateSnapshot,
    titleService: Title,
  ) {
    const breadcrumbs: Breadcrumb[] = this.getBreadcrumbs(
      routerStateSnapshot,
      titleService,
    );
    const title = breadcrumbs
      .map((b) => b.title)
      .reverse()
      .join(' - ');
    return title;
  }
}

function buildBreadcrumb(
  route: ActivatedRouteSnapshot,
  titleSvc: Title,
): Breadcrumb | undefined {
  const config: BreadcrumbConfig = route.routeConfig?.data?.['breadcrumb'] ?? {
    link: true,
  };
  const titleFromBreadcrumbConfig = config.title;
  const titleFromTitleStrategy =
    route.routeConfig?.title != null
      ? new DefaultTitleStrategy(titleSvc).getResolvedTitleForRoute(route)
      : undefined;
  const title = titleFromBreadcrumbConfig ?? titleFromTitleStrategy;
  if (title == null) {
    return undefined;
  }
  let path: string | undefined;
  if (config.link === true) {
    path = flatMap(
      route.pathFromRoot.map((v) => v.url.map((segment) => segment.toString())),
    )
      .filter((s) => s !== '')
      .join('/');

    path = path.endsWith('/') ? path.slice(0, -1) : path;
  }
  return { title, path };
}
