<span title="{{ currencyName$ | async }}">{{ currencyCode$ | async }}</span>
<span
  title="{{ currencyCode$ | async }} {{ amount$ | async | number: '1.2-99' }}"
>
  @if (roundingBehavior === "none") {
    {{ amount$ | async | number: "1.2-99" }}
  } @else {
    {{ amount$ | async | number: digitsInfo }}
  }
</span>
