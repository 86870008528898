import { Injectable } from '@angular/core';
import { ISO_4217 } from 'gain-lib/money';

@Injectable({ providedIn: 'root' })
export class CurrencyNameService {
  findCurrencyName(currencyCode: string): string | undefined {
    return ISO_4217.find(
      (c) => c.currencyCode.toLowerCase() === currencyCode.toLowerCase(),
    )?.currencyName;
  }
}
