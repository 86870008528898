import { Pipe, PipeTransform } from '@angular/core';
import { capitalize, kebabCase, lowerCase, startCase } from 'lodash-es';

type CaseType =
  | 'sentence'
  | 'initials'
  | 'kebab'
  | 'sentenceSpecialCharacters'
  | 'title'
  | 'reverseFormat';

@Pipe({
  name: 'formatDisplay',
})
export class FormatDisplayPipe implements PipeTransform {
  transform(
    value: string[] | string | null | undefined,
    caseType: CaseType = 'sentence',
  ): string {
    if (!value) {
      return '';
    }
    if (Array.isArray(value)) {
      return value.map((v) => this._formatDisplay(v, caseType)).join(', ');
    }

    return this._formatDisplay(value, caseType);
  }

  private _formatDisplay(value: string, caseType: CaseType) {
    const questionMarkPositions: number[] = [];
    const coreValue = value.replace(/\?/g, (match, offset) => {
      questionMarkPositions.push(offset);
      return '';
    });

    let formattedValue: string;
    if (
      caseType !== 'reverseFormat' &&
      (coreValue.toLowerCase().replace(/\s/g, '') === 'restrictedstockunit' ||
        coreValue.toLowerCase() === 'rsu')
    ) {
      formattedValue = 'RSU';
    } else {
      switch (caseType) {
        case 'initials':
          formattedValue = coreValue.replace(/[a-z\s]/g, '');
          break;
        case 'kebab':
          formattedValue = capitalize(kebabCase(coreValue));
          break;
        case 'sentenceSpecialCharacters':
          formattedValue = capitalize(coreValue.toLowerCase());
          break;
        case 'title':
          formattedValue = startCase(coreValue);
          break;
        case 'reverseFormat': {
          if (coreValue.toLowerCase() === 'rsu') {
            formattedValue = 'RestrictedStockUnit';
          } else {
            formattedValue = coreValue.replace(/\s+([a-z])/g, (_, p1) =>
              p1.toUpperCase(),
            );
          }
          break;
        }

        default:
          formattedValue = capitalize(lowerCase(coreValue));
      }
    }

    questionMarkPositions.forEach((pos) => {
      formattedValue =
        formattedValue.slice(0, pos) + '?' + formattedValue.slice(pos);
    });

    return formattedValue;
  }
}
