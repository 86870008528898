import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[gaxWholeNumber]',
})
export class WholeNumbersDirective {
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const allowedKeys = [
      'Backspace',
      'Tab',
      'ArrowLeft',
      'ArrowRight',
      'Delete',
    ];
    if (
      (!allowedKeys.includes(event.key) && isNaN(Number(event.key))) ||
      event.key === '.'
    ) {
      event.preventDefault();
    }
  }
}
