import { Pipe, PipeTransform } from '@angular/core';
import {
  EnumDisplayService,
  EnumValueDisplayFormat,
} from 'gain-web/lib/enums/enum-display.service';

@Pipe({
  name: 'enumDisplay',
  standalone: true,
})
export class UntypedEnumDisplayPipe implements PipeTransform {
  constructor(private _enumDisplay: EnumDisplayService) {}

  transform(
    value: any,
    format: EnumValueDisplayFormat = 'display name',
  ): string | null {
    if (typeof value === 'string') {
      return this._enumDisplay.getEnumDisplayByValue(value, format);
    }
    return value;
  }
}
