import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

type FixedWidthPageOptions = 'normal' | 'wide';
export type PageWidthOptions = FixedWidthPageOptions | 'full';

@Component({
  selector: 'gax-page',
  templateUrl: './page.component.html',
  styleUrl: './page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageComponent {
  @Input()
  @HostBinding('class.page-width')
  width: PageWidthOptions = 'normal';

  @Input()
  pageHelperTextTitle = 'Helper Title';

  @Input()
  pageHelperTextDescription = 'Description of page helper text.';

  constructor(private matDialog: MatDialog) {}

  openHelperDialog() {
    // this.matDialog.open()
  }
}
