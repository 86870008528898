@if (!hideLabel) {
  <div class="label">{{ label }}</div>
}

<div #container>
  <div class="control">
    <textarea
      #input
      type="text"
      [value]="_value"
      (blur)="markAsTouched()"
      (input)="handleInputChange(input.value)"
      rows="1"
      [readonly]="static"
    ></textarea>
    <mat-icon (click)="handleSaveChanges(true)">check</mat-icon>
    <mat-icon (click)="handleSaveChanges(false)">close</mat-icon>
    <div class="suffix">
      <mat-icon *ngIf="firstError && touched" color="warn" [@fadeIn]
        >error
      </mat-icon>
    </div>
  </div>

  @if (!hideHintError) {
    <div *ngIf="!(firstError && touched)" class="hint">
      {{ hint }}
    </div>

    <ng-container
      *ngIf="firstError && touched"
      [ngTemplateOutlet]="firstError.template"
    ></ng-container>
  }
</div>
