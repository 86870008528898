<!-- eslint-disable @angular-eslint/template/no-inline-styles -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->

<div
  class="cell-content"
  #editValue
  [class.edited]="
    (value !== null && edited && enableEditedIcon) ||
    (value !== null && differentFromPrevious)
  "
>
  @if (!!editing) {
    @if (valueType === "string") {
      <input
        (keydown.escape)="exitEditMode()"
        (keydown.enter)="updateValue(editValueInput.value, $event)"
        #editValueInput
        class="cell-input"
        (input)="onInputChange()"
        [value]="userEditableValue"
      />
      <div class="cell-edit-icons">
        <mat-icon
          style="cursor: pointer"
          [matTooltip]="isClean ? '' : 'Save changes'"
          [class.clean]="isClean"
          (click)="updateValue(editValueInput.value, $event)"
          >check</mat-icon
        >
        @if (edited && revertable) {
          <mat-icon
            [matTooltip]="'Revert to previously saved value'"
            (click)="revertValue($event)"
            style="cursor: pointer"
            >undo</mat-icon
          >
        } @else {
          <mat-icon
            matTooltip="Cancel changes"
            (click)="exitEditMode()"
            style="cursor: pointer"
            >close</mat-icon
          >
        }
      </div>
    } @else {
      <input
        [placeholder]="displayValueNew | percent: percentageFormat"
        [min]="min"
        [max]="max"
        [matTooltip]="toolTipText"
        type="number"
        (keydown.escape)="exitEditMode()"
        (keydown.enter)="updateValue(editValueInput.value, $event)"
        #editValueInput
        class="cell-input"
      />
      <div class="cell-edit-icons">
        <mat-icon
          [class.icon-edit-disbled]="editValueInput.value === ''"
          style="cursor: pointer"
          matTooltip="Save changes"
          (click)="updateValue(editValueInput.value, $event)"
          >check</mat-icon
        >
        @if (edited && revertable) {
          <mat-icon
            [matTooltip]="'Revert to previously saved value'"
            (click)="revertValue($event)"
            style="cursor: pointer"
            >undo</mat-icon
          >
        } @else {
          <mat-icon
            matTooltip="Cancel changes"
            (click)="exitEditMode()"
            style="cursor: pointer"
            >close</mat-icon
          >
        }
      </div>
    }
  } @else {
    <div class="cell-display-value" (click)="enterEditMode(editValue)">
      <span>
        @if (valueType === "number") {
          @if (displayValueNew) {
            {{ displayValueNew | percent: percentageFormat }}
          } @else {
            <ng-content></ng-content>
          }
        } @else {
          @if (displayValue) {
            {{ displayValue | percent: percentageFormat }}
          } @else {
            <ng-content></ng-content>
          }
        }
      </span>
      <!-- if edited, show recycle icon, else show edit icon -->
      <mat-icon class="edited-icon" *ngIf="!edited && !differentFromPrevious"
        >edit</mat-icon
      >
      <!-- a{{edited}}b{{enableEditedIcon}}c{{differentFromPrevious}} -->
      <mat-icon
        *ngIf="(edited && enableEditedIcon) || differentFromPrevious"
        class="mat-icon-right"
        style="cursor: default"
        >{{ icon }}</mat-icon
      >
    </div>
  }
</div>
