import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'gax-icon',
  styleUrls: ['./icon.component.scss'],
  templateUrl: './icon.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
})
export class IconComponent extends MatIcon implements OnInit {
  @Input() icon: string = '';
  gaIconSource: string = 'ga-assets/icons/';

  override ngOnInit() {
    this.gaIconSource = `${this.gaIconSource}${this.icon}.svg`;
  }
}
