import {
  AnimationEvent,
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { SelectionModel } from '@angular/cdk/collections';
import { ConnectedOverlayPositionChange } from '@angular/cdk/overlay';
import {
  AfterContentInit,
  Attribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
  computed,
  input,
  signal,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ErrorComponent } from 'gain-lib/error';
import { Observable, Subject, Subscription, merge } from 'rxjs';
import { startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { OptionComponent } from './option/option.component';
import { GaSelectTriggerComponent } from './select-trigger/select-trigger.component';

export type SelectValue<T> = T | T[] | null;

@Component({
  selector: 'gax-select-form-field',
  templateUrl: './select-form-field.component.html',
  styleUrls: ['./select-form-field.component.scss'],
  animations: [
    trigger('dropDown', [
      state('void', style({ height: '0px', opacity: 0 })),
      state('*', style({ height: 'fit-content', opacity: 1 })),
      transition(':enter', [animate('300ms linear')]),
      transition(':leave', [animate('500ms ease-out')]),
    ]),
    trigger('fadeIn', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition(':enter', [animate('300ms linear')]),
    ]),
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: GaSelectFormFieldComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class GaSelectFormFieldComponent<T>
  implements AfterContentInit, ControlValueAccessor
{
  @Input()
  label: string = '';

  @Input()
  hint?: string;

  @Input()
  displayWith: ((value: T | T[]) => T | T[] | string | number) | null = null;

  @Input()
  autocomplete: boolean = true;

  @Input()
  hideCaret: boolean = false;

  @Input()
  required: boolean = false;

  @Input()
  showInfoIcon: boolean = false;

  @Output()
  readonly infoIconClicked = new EventEmitter<void>();

  openInfoModal(event: Event) {
    event.stopPropagation();
    this.infoIconClicked.emit();
  }

  @Input()
  @HostBinding('class.disabled')
  disabled: boolean = false;

  @HostBinding('class.active')
  active: boolean = false;

  @Input()
  compareWith: (v1: T | null, v2: T | null) => boolean = (v1, v2) => v1 === v2;

  @Input()
  placeholder: string = '';

  @Input()
  set value(value: SelectValue<T>) {
    this.setValue(value);
    this.highlightSelectedOptions();
    this.onChange(this.value);
  }

  search = input<true | false | 'auto'>('auto');

  private _initialOptionCount = signal<number>(0);

  enableSearch = computed(
    () =>
      this.search() === true ||
      (this.search() === 'auto' && this._initialOptionCount() > 5),
  );

  @Output()
  readonly opened = new EventEmitter<void>();

  @Output()
  readonly closed = new EventEmitter<void>();

  @Output()
  readonly selectionChanged = new EventEmitter<SelectValue<T>>();

  @Output()
  readonly searchChanged = new EventEmitter<string>();

  get value() {
    if (this.selectionModel.isEmpty()) return null;
    if (this.selectionModel.isMultipleSelection()) {
      return this.selectionModel.selected;
    }
    return this.selectionModel.selected[0];
  }

  @HostListener('blur')
  markAsTouched() {
    if (this.disabled || (this.autocomplete && this.isOpen)) return;
    this.onTouched();
    this.touched = true;
    this.active = false;
    this.cd.markForCheck();
  }

  @HostListener('keydown', ['$event'])
  protected onKeyDown(e: KeyboardEvent) {
    if (e.key === 'ArrowDown' && !this.isOpen) {
      this.openPanel();
    }

    if (e.key === 'Tab' && this.isOpen) {
      this.closePanel();
    }

    if ((e.key === 'ArrowDown' || e.key === 'ArrowUp') && this.isOpen) {
      this.listKeyManager.onKeydown(e);
    }
    if (
      (e.key === 'Enter' || e.key === ' ') &&
      this.isOpen &&
      this.listKeyManager.activeItem
    ) {
      e.preventDefault();
      this.handleSelection(this.listKeyManager.activeItem);
    }
  }

  @HostBinding('attr.tabIndex')
  @Input()
  tabIndex = 0;

  @Input()
  loading$: Observable<boolean> = new Observable();

  panelAbove: boolean = false;

  onPanelPositionChange(position: ConnectedOverlayPositionChange) {
    this.panelAbove = position.connectionPair.originY === 'top';
  }

  multiple: boolean = false;

  @HostListener('click')
  openPanel() {
    if (this.disabled) return;
    this.isOpen = true;
    this.active = true;
    if (this.autocomplete && this.multiple) {
      this.showAutocompleteInput = true;
      setTimeout(() => {
        if (this.autocompleteInput) {
          this.autocompleteInput.nativeElement.focus();
        }
      }, 200);
    }
    this.searchChanged.emit('');
    this.cd.markForCheck();
  }

  private selectionModel = new SelectionModel<T>(this.multiple);
  private listKeyManager!: ActiveDescendantKeyManager<OptionComponent<T>>;

  private optionMap = new Map<T | T[] | null, OptionComponent<T>>();
  private unsubscribe$: Subject<unknown> = new Subject();

  private setValue(value: SelectValue<T>) {
    this.selectionModel.clear();
    if (value === null) return;
    if (Array.isArray(value)) {
      this.selectionModel.select(...value);
      return;
    }
    this.selectionModel.select(value);
  }

  isOpen: boolean = false;

  touched: boolean = false;

  showAllSelected: boolean = false;

  protected get displayValue() {
    //

    if (this.displayWith && this.value) {
      if (Array.isArray(this.value)) {
        return this.displayWith(this.value);
      }
      return this.displayWith(this.value);
    }

    return this.value;
  }

  protected onChange: (newValue: SelectValue<T>) => void = () => {};
  protected onTouched: () => void = () => {};

  hasError: boolean = false;

  @ContentChild(GaSelectTriggerComponent)
  selectTrigger!: GaSelectTriggerComponent;
  selectTriggerTemplate!: TemplateRef<unknown>;

  @ViewChild('autocompleteInput')
  autocompleteInput!: ElementRef;

  @ContentChildren(OptionComponent, { descendants: true })
  options!: QueryList<OptionComponent<T>>;

  @ContentChildren(ErrorComponent)
  errors!: QueryList<ErrorComponent>;

  firstError!: ErrorComponent;

  subscription!: Subscription;

  private updateFirstError() {
    this.firstError = this.errors.first;
  }

  constructor(
    @Attribute('multiple') multiple: string,
    private cd: ChangeDetectorRef,
    private hostEl: ElementRef,
  ) {
    this.multiple = multiple !== null;
    this.selectionModel = new SelectionModel<T>(this.multiple);

    if (this.multiple) {
      this.showAllSelected = true;
    }
  }

  writeValue(value: SelectValue<T>): void {
    this.setValue(value);
    this.highlightSelectedOptions();
    if (this.selectTrigger) {
      this.selectTrigger.hasValue = !!this.value?.toString();
      this.cd.markForCheck();
    }
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cd.markForCheck();
  }

  clearSelection(e?: Event) {
    e?.stopPropagation();
    this.selectionModel.clear();
    if (this.selectTrigger) {
      this.selectTrigger.hasValue = false;
    }
    this.selectionChanged.emit(this.value);
    this.onChange(this.value);
    this.cd.markForCheck();
  }

  @Input()
  hideSelectAll: boolean = false;

  showAutocompleteInput: boolean = false;
  autocompleteSearchTerm: string = '';

  protected toggleAutocompleteInput(showInput: boolean) {
    this.showAutocompleteInput = showInput;
    if (showInput) {
      this.searchChanged.emit('');
      return;
    }
  }

  protected onAutocompleteInputChange(e: Event) {
    if (this.multiple) {
      this.showAllSelected = (e.target as HTMLInputElement).value.length === 0;
    }
    this.searchChanged.emit((e.target as HTMLInputElement).value);
  }

  ngAfterContentInit(): void {
    this._initialOptionCount.set(this.options.length);
    if (this.selectTrigger) {
      this.selectTrigger.hasValue = !!this.value?.toString();
      this.selectTrigger.placeholder = this.placeholder;
      this.selectTriggerTemplate = this.selectTrigger.template;
      this.cd.markForCheck();
    }

    this.updateFirstError();
    this.subscription = this.errors.changes
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.updateFirstError());

    this.listKeyManager = new ActiveDescendantKeyManager(
      this.options,
    ).withWrap();
    this.listKeyManager.change
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((itemIndex) => {
        // Scroll to active element
        this.options.get(itemIndex)?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        this.cd.markForCheck();
      });
    this.selectionModel.changed
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((values) => {
        values.removed.forEach((rv) => this.optionMap.get(rv)?.deselect());
        values.added.forEach((av) => {
          this.optionMap.get(av)?.highlightAsSelected();
        });
        if (this.selectTrigger) {
          this.selectTrigger.hasValue = !!values.added;
        }
        this.cd.markForCheck();
      });
    this.options.changes
      .pipe(
        startWith<QueryList<OptionComponent<T>>>(this.options),
        tap(() => {
          this.refreshOptionsMap();
        }),
        tap(() => queueMicrotask(() => this.highlightSelectedOptions())),
        switchMap((options: QueryList<OptionComponent<T>>) =>
          merge(...options.map((o) => o.selected)),
        ),
      )
      .subscribe((selectedOption: OptionComponent<T>) => {
        this.handleSelection(selectedOption);
        this.cd.markForCheck();
      });
  }

  private handleSelection(selectedOption: OptionComponent<T>) {
    if (this.disabled) return;
    if (selectedOption.value) {
      this.selectionModel.toggle(selectedOption.value);
      this.selectionChanged.emit(this.value);
      this.onChange(this.value);
    }
    if (this.selectTrigger) {
      this.selectTrigger.hasValue = this.selectionModel.hasValue();
    }
    if (!this.selectionModel.isMultipleSelection()) {
      this.closePanel();
    }
  }

  private highlightSelectedOptions() {
    const valuesWithUpdatedReferences = this.selectionModel.selected.map(
      (value) => {
        const correspondingOption = this.findOptionsByValue(value);
        return correspondingOption ? correspondingOption.value! : value;
      },
    );
    this.selectionModel.clear();
    this.selectionModel.select(...valuesWithUpdatedReferences);
    this.findOptionsByValue(this.value)?.highlightAsSelected();
  }

  private refreshOptionsMap() {
    this.optionMap.clear();

    this.options.forEach((o) => {
      if (this.multiple) o.addMultipleSelect();
      this.optionMap.set(o.value, o);
    });
  }

  get allSelected(): boolean {
    if (this.optionMap.keys === null) return false;

    const allSelected: boolean =
      this.optionMap.size === this.selectionModel.selected.length;

    return allSelected;
  }

  toggleAllSelection() {
    if (this.optionMap.keys === null) return;
    const valuesWithUpdatedReferences: (OptionComponent<T> | NonNullable<T>)[] =
      [];

    this.optionMap.forEach((value: OptionComponent<T>, key: T | T[] | null) => {
      const correspondingOption = this.findOptionsByValue(key);
      valuesWithUpdatedReferences.push(
        correspondingOption ? correspondingOption.value! : value,
      );
    });
    const allSelected: boolean =
      valuesWithUpdatedReferences.length ===
      this.selectionModel.selected.length;

    if (allSelected) {
      this.clearSelection();
      return;
    }
    this.selectionModel.clear();
    this.selectionModel.select(...(valuesWithUpdatedReferences as T[]));
    this.findOptionsByValue(this.value)?.highlightAsSelected();
    this.selectionChanged.emit(this.value);
    this.onChange(this.value);
    this.cd.markForCheck();
  }

  private findOptionsByValue(value: T | T[] | null) {
    if (this.optionMap.has(value)) {
      return this.optionMap.get(value);
    }
    if (Array.isArray(value)) {
      return (
        this.options &&
        this.options.find((o) =>
          this.compareWith(o.value, value.filter((val) => val === o.value)[0]),
        )
      );
    }
    return (
      this.options && this.options.find((o) => this.compareWith(o.value, value))
    );
  }

  onPanelAmimationsDone({ fromState, toState }: AnimationEvent) {
    if (fromState === 'void' && toState === null && this.isOpen) {
      this.opened.emit();
    }
    if (fromState === null && toState === 'void' && !this.isOpen) {
      this.closed.emit();
    }
  }

  closePanel() {
    this.isOpen = false;
    if (this.multiple) {
      this.showAllSelected = true;
      this.autocompleteSearchTerm = '';
      this.searchChanged.emit('');
    }
    this.onTouched();
    this.hostEl.nativeElement.focus();
    this.touched = true;
    this.cd.markForCheck();
  }
}
