import { MatPaginatorDefaultOptions } from '@angular/material/paginator';

export const DEFAULT_PAGE_SIZE = 25;
export const DEFAULT_PAGE_SIZE_OPTIONS: readonly number[] = [
  5, 10, 15, 25, 50, 100,
];

export const GA_MAT_PAGINATOR_OPTIONS: MatPaginatorDefaultOptions = {
  pageSize: DEFAULT_PAGE_SIZE,
  showFirstLastButtons: true,
  pageSizeOptions: [...DEFAULT_PAGE_SIZE_OPTIONS],
};
export function getPageInfo({
  pageSize,
  pageIndex,
}: {
  pageIndex: number;
  pageSize: number;
}): {
  pageIndex: number;
  page: number;
  pageSize: number;
} {
  return {
    page: pageIndex + 1,
    pageSize,
    pageIndex,
  };
}
