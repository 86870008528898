import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CountryRegionDataService } from 'gain-web/shared-modules/country-region-data/country-region-data.service';
import { ApiClient } from 'gain-web/shared-services/api-client.generated.service';
import { orderBy } from 'lodash-es';

export interface GaRegionSelectDialogComponentData {
  dialogTitle?: string;
  selectedRegions: string[];
  readonlyRegions: string[];
  countryCode: string;
  hiddenRegions?: string[];
}

export type GaRegionSelectDialogComponentResult =
  | 'Close'
  | {
      selectedRegions: string[];
    };

@Component({
  selector: 'app-ga-region-select-dialog',
  templateUrl: './region-select-dialog.component.html',
  styleUrls: ['./region-select-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class GaRegionSelectDialogComponent {
  readonly dialogTitle?: string;
  readonly regions: ApiClient.IRegionDto[] = [];
  readonly regionsByIsoCode: {
    [regionIsoCode: string]: ApiClient.IRegionDto;
  } = {};
  readonly country: ApiClient.ICountryDto;

  selectedRegions: { [regionCode: string]: ApiClient.IRegionDto } = {};
  searchCtrl = new FormControl<string>('');

  get isCountryIndeterminate(): boolean {
    return (
      Object.keys(this.selectedRegions).length > 0 &&
      !this.areAllRegionsSelected
    );
  }

  get areAllRegionsSelected(): boolean {
    return Object.keys(this.selectedRegions).length === this.regions.length;
  }

  get selectedRegionsSortedByName(): ApiClient.IRegionDto[] {
    return orderBy(Object.values(this.selectedRegions), 'code');
  }

  constructor(
    private _countryRegionDataService: CountryRegionDataService,
    public dialogRef: MatDialogRef<
      GaRegionSelectDialogComponent,
      GaRegionSelectDialogComponentResult
    >,
    @Inject(MAT_DIALOG_DATA) public data: GaRegionSelectDialogComponentData,
  ) {
    this.dialogTitle = data.dialogTitle;

    const _country = this._countryRegionDataService.findCountryByCode(
      data.countryCode,
    );

    const _hiddenRegions = data.hiddenRegions;

    if (_country === undefined) {
      throw new Error(`Unable to find country with code ${data.countryCode}`);
    }

    this.country = _country;
    // Todo filter region

    this.regions = _hiddenRegions
      ? _country.regions
          .map((r) => r)
          .filter((r) => !this.filterExistingRegions(r))
      : _country.regions;

    this.regionsByIsoCode = this.regions.reduce<{
      [key: string]: ApiClient.IRegionDto;
    }>((regionsByIsoCode, region) => {
      regionsByIsoCode[region.code] = region;
      return regionsByIsoCode;
    }, {});

    Object.freeze(this.regionsByIsoCode);

    this.selectRegions(data.selectedRegions);
  }

  showRegion(region: ApiClient.IRegionDto) {
    if (this.searchCtrl.value == null) {
      return true;
    }

    const value = this.searchCtrl.value.trim().toUpperCase();

    return (
      value === '' ||
      region.code === value ||
      region.name.toUpperCase().includes(value)
    );
  }

  isRegionReadonly(region: ApiClient.IRegionDto) {
    return this.data.readonlyRegions.includes(region.code);
  }

  filterExistingRegions(region: ApiClient.IRegionDto) {
    return this.data.hiddenRegions?.includes(region.code);
  }

  isRegionChecked(region: ApiClient.IRegionDto) {
    return this.selectedRegions[region.code] != null;
  }

  toggleRegion(region: ApiClient.IRegionDto, change: MatCheckboxChange) {
    if (this.data.readonlyRegions.includes(region.code)) return;

    change.checked
      ? this.selectRegions([region.code])
      : this.unselectRegions([region.code]);
  }

  onRegionCheckboxClick(e: Event, region: ApiClient.IRegionDto) {
    if (!this.isRegionReadonly(region)) return;
    e.preventDefault();
  }

  selectRegions(codes: string[]) {
    codes.forEach((code) => {
      this.selectedRegions[code] = this.regionsByIsoCode[code];
    });
  }

  unselectRegions(codes: string[]) {
    codes
      .filter((code) => !this.data.readonlyRegions.includes(code))
      .forEach((code) => {
        delete this.selectedRegions[code];
      });
  }

  toggleAll(change: MatCheckboxChange) {
    const _filteredCodes = this.regions
      .map((r) => r.code)
      .filter((code) => !this.data.readonlyRegions.includes(code));

    if (change.checked) {
      this.selectRegions(_filteredCodes);
    } else {
      this.unselectRegions(_filteredCodes);
    }
  }

  getRegionTooltip(region: ApiClient.IRegionDto) {
    return `${region.name} (${region.code})`;
  }

  select() {
    this.dialogRef.close({
      selectedRegions: Object.keys(this.selectedRegions),
    });
  }

  close() {
    this.dialogRef.close('Close');
  }
}
