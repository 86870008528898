import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Breadcrumb, Breadcrumbs } from 'gain-lib/breadcrumbs';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppBreadcrumbsComponent implements OnInit {
  @Input()
  breadcrumbs$?: Observable<Breadcrumb[]>;

  @Input()
  breadcrumbs?: Breadcrumb[];

  constructor(private _breadcrumbs: Breadcrumbs) {}

  ngOnInit() {
    if (this.breadcrumbs$) {
      return;
    }

    if (this.breadcrumbs) {
      this.breadcrumbs$ = of(this.breadcrumbs);
      return;
    }

    this.breadcrumbs$ = this._breadcrumbs.valueChanges.pipe(
      map((b) => b.slice(1, b.length - 1)),
    );
  }
}
