import { NgModule } from '@angular/core';
import { ConvertibleMoneyComponent } from 'gain-web/shared-modules/money/convertible-money/convertible-money.component';
import { CurrencyNameService, GaMoneyModule } from 'gain-lib/money';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { GainCurrencyNameService } from 'gain-web/shared-modules/money/gain-currency-name.service';

@NgModule({
  declarations: [ConvertibleMoneyComponent],
  imports: [CommonModule, GaMoneyModule, MatTooltipModule, MatIconModule],
  exports: [ConvertibleMoneyComponent, GaMoneyModule],
  providers: [
    { provide: CurrencyNameService, useClass: GainCurrencyNameService },
  ],
})
export class GainMoneyModule {}
