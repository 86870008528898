import {
  BehaviorSubject,
  from,
  isObservable,
  Observable,
  of,
  ReplaySubject,
} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ProcessMonitor } from '../ga-process-monitor/process-monitor';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RequestResult<TResultSuccess, TResultFailure = any> =
  | { status: 'SUCCESS'; success: true; failed: false; result: TResultSuccess }
  | { status: 'FAILED'; success: false; failed: true; result: TResultFailure };

type RequestFn<TRequest, TResultSuccess> =
  | ((req: TRequest) => Observable<TResultSuccess>)
  | ((req: TRequest) => Promise<TResultSuccess>);

export class AsyncRequestHelper<
  TRequest,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TResultSuccess = any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TResultFailure = any,
> {
  public readonly process = new ProcessMonitor();

  private readonly _result$ = new ReplaySubject<
    RequestResult<TResultSuccess, TResultFailure>
  >();
  private readonly _successResult$ = new BehaviorSubject<TResultSuccess | null>(
    null,
  );

  private readonly _failureResult$ = new BehaviorSubject<TResultFailure | null>(
    null,
  );

  public get result$(): Observable<
    RequestResult<TResultSuccess, TResultFailure>
  > {
    return this._result$.asObservable();
  }

  public get successResult$(): Observable<TResultSuccess | null> {
    return this._successResult$.asObservable();
  }

  public get successResult(): TResultSuccess | null {
    return this._successResult$.getValue();
  }

  public get success$(): Observable<boolean> {
    return this.successResult$.pipe(map((r) => r != null));
  }

  public get failureResult$(): Observable<TResultFailure | null> {
    return this._failureResult$.asObservable();
  }

  public get failureResult(): TResultFailure | null {
    return this._failureResult$.getValue();
  }

  public get failure$(): Observable<boolean> {
    return this.failureResult$.pipe(map((r) => r != null));
  }

  private readonly _requestFn: RequestFn<TRequest, TResultSuccess>;

  constructor(requestFn: RequestFn<TRequest, TResultSuccess>) {
    this._requestFn = requestFn;
  }

  public execute(
    request: TRequest,
  ): Observable<RequestResult<TResultSuccess, TResultFailure>> {
    this.process.markAsPending();
    const fnResult = this._requestFn(request);
    const result$ = isObservable(fnResult) ? fnResult : from(fnResult);
    return result$.pipe(
      map((r: TResultSuccess) => {
        this.process.markAsSuccess();
        this._successResult$.next(r);
        this._failureResult$.next(null);
        const result: RequestResult<TResultSuccess, TResultFailure> = {
          status: 'SUCCESS',
          success: true,
          failed: false,
          result: r,
        };
        this._result$.next(result);
        return result;
      }),
      catchError((e) => {
        this.process.markAsFailed();
        this._successResult$.next(null);
        this._failureResult$.next(e);
        const failureResult: RequestResult<TResultSuccess, TResultFailure> = {
          status: 'FAILED',
          success: false,
          failed: true,
          result: e,
        };
        this._result$.next(failureResult);
        return of(failureResult);
      }),
    );
  }
}
