import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DocumentEventService {
  click$: Subject<PointerEvent> = new Subject<PointerEvent>();

  emitClick(event: PointerEvent) {
    this.click$.next(event);
  }
}
