import { ActivatedRouteSnapshot } from '@angular/router';

export function findRouteData<T = any>(
  route: ActivatedRouteSnapshot,
  key: string,
): T | undefined {
  let value: T | undefined;
  let r: ActivatedRouteSnapshot | null = route;

  while (value == null && r != null) {
    value = r.data[key];

    if (value) {
      return value;
    }

    r = r.parent;
  }

  r = route;
  while (value == null && r != null) {
    value = r.data[key];

    if (value) {
      return value;
    }

    r = r.firstChild;
  }

  return value;
}
