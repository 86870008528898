import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { findRouteData } from 'gain-web/lib/router/find-route-data';
import { ApiClient } from 'gain-web/shared-services/api-client.generated.service';
import ApplicationContextDto = ApiClient.ApplicationContextDto;

@Injectable({ providedIn: 'root' })
export class ApplicationContextService {
  public get value(): ApplicationContextDto {
    return getApplicationContext(this._router.routerState.root.snapshot);
  }

  constructor(private _router: Router) {}

  findEnumDisplayDetails(
    enumType: string,
    enumValue: string,
  ): ApiClient.EnumValueDisplayDetails | null {
    const enumDetails = this.value.enums[enumType];
    if (enumDetails == null) {
      return null;
    }
    const enumDisplayValueDetails = enumDetails[enumValue];
    return enumDisplayValueDetails;
  }

  getEnumDisplayDetailsByValue(
    enumValue: string,
  ): ApiClient.EnumValueDisplayDetails[] {
    return Object.values(this.value.enums)
      .filter((v) => v[enumValue] != null)
      .map((v) => v[enumValue]);
  }
}

export function getApplicationContext(
  route: ActivatedRouteSnapshot,
): ApiClient.ApplicationContextDto {
  const appContext = findApplicationContext(route);
  if (appContext == null) {
    throw new Error('Unable to find application context in route tree');
  }
  return appContext;
}

export function findApplicationContext(
  route: ActivatedRouteSnapshot,
): ApiClient.ApplicationContextDto | undefined {
  return findRouteData<ApiClient.ApplicationContextDto>(route, 'appContext');
}

export async function findOrFetchApplicationContext(
  route: ActivatedRouteSnapshot,
  applicationContextService: ApiClient.ApplicationContextService,
): Promise<ApiClient.ApplicationContextDto> {
  let appContext = findApplicationContext(route);
  if (appContext == null) {
    appContext = await applicationContextService.get().toPromise();
    route.data = {
      ...route.data,
      appContext,
    };
  }
  return appContext;
}
