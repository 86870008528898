import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SelectSearchComponent } from './select-search/select-search.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect, MatSelectTrigger } from '@angular/material/select';
import { SelectAllOptionComponent } from './select-all-option/select-all-option.component';

@NgModule({
  declarations: [SelectSearchComponent, SelectAllOptionComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatIconModule,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    MatSelectTrigger,
  ],
  exports: [SelectSearchComponent, SelectAllOptionComponent],
})
export class GaFormsModule {}
