import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CurrencyNameService } from 'projects/gain-lib/money/src/currency-name.service';
import { PrecisionService } from 'projects/gain-lib/precision/precision';

export type RoundingBehavior = 'Use precision settings' | 'none';

@Component({
  selector: 'gax-money',
  templateUrl: './money.component.html',
  styleUrls: ['./money.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoneyComponent {
  currencyCode$: BehaviorSubject<string | null>;
  amount$: BehaviorSubject<number | null>;
  currencyName$: Observable<string | null | undefined>;
  digitsInfo: string;
  @Input()
  roundingBehavior: RoundingBehavior = 'Use precision settings';

  constructor(precision: PrecisionService, currencyName: CurrencyNameService) {
    const { numericMinDigits, numericMaxDigits } =
      precision.getPrecisionSettings();
    this.digitsInfo = `1.${numericMinDigits}-${numericMaxDigits}`;
    this.currencyCode$ = new BehaviorSubject<string | null>(null);
    this.amount$ = new BehaviorSubject<number | null>(null);
    this.currencyName$ = this.currencyCode$.pipe(
      map((currencyCode) => {
        if (currencyCode != null) {
          return currencyName.findCurrencyName(currencyCode);
        }
        return null;
      }),
    );
  }

  @Input()
  set currencyCode(value: string) {
    this.currencyCode$.next(value);
  }

  @Input()
  set amount(value: number) {
    this.amount$.next(value);
  }
}
