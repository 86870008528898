import { Injectable, OnDestroy } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { isEqual } from 'lodash';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  skip,
  startWith,
  takeUntil,
} from 'rxjs/operators';

export type DataChangeState = 'UNCHANGED' | 'SAVED' | 'EDITED';

@Injectable({ providedIn: 'root' })
export class PageEditTracker implements OnDestroy {
  private _state$ = new BehaviorSubject<DataChangeState>('UNCHANGED');

  get saved$() {
    return this._isState$('SAVED');
  }

  get saved() {
    return this.state === 'SAVED';
  }

  get changed$() {
    return this._isState$('EDITED');
  }

  get changed() {
    return this.state === 'EDITED';
  }

  get unchanged$() {
    return this._isState$('UNCHANGED');
  }

  get unchanged() {
    return this.state === 'UNCHANGED';
  }

  get state(): DataChangeState {
    return this._state$.getValue();
  }

  get state$(): Observable<DataChangeState> {
    return this._state$.asObservable();
  }

  _onDestroy$: Subject<void> = new Subject();

  constructor(private _router: Router) {
    this._router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        takeUntil(this._onDestroy$),
      )
      .subscribe(() => {
        this.markAsUnchanged();
      });
  }

  markAsEditedWhenValueChanges(
    control: AbstractControl,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    until: Observable<any>,
    ignoreInitialValue: boolean = false,
  ) {
    const initialValue = control.getRawValue();

    this._getControlValueChangesObservable$(control, until)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((value) => {
        ignoreInitialValue || !isEqual(initialValue, value)
          ? this.markAsEdited()
          : this.markAsUnchanged();
      });
  }

  markAsEditedWhenMultipleControlValuesChange(
    controls: AbstractControl[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    until: Observable<any>,
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const initialValues: any[] = [];

    controls.forEach((control) => initialValues.push(control.getRawValue()));
    const controlObservables$ = controls.map((control) =>
      this._getControlValueChangesObservable$(control, until),
    );

    combineLatest(controlObservables$)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((values) => {
        values.every((value, i) => isEqual(value, initialValues[i]))
          ? this.markAsUnchanged()
          : this.markAsEdited();
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  markAsEditedWhenValuesChange(
    control: AbstractControl,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    observable$: BehaviorSubject<any>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    until: Observable<any>,
  ) {
    const initialControlValue = control.getRawValue();
    const initialObservableValue = cloneDeep(observable$.value);

    combineLatest([
      this._getControlValueChangesObservable$(control, until),
      observable$,
    ])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([controlValue, observableValue]) => {
        isEqual(initialControlValue, controlValue) &&
        isEqual(initialObservableValue, observableValue)
          ? this.markAsUnchanged()
          : this.markAsEdited();
      });
  }

  private _getControlValueChangesObservable$(
    control: AbstractControl,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    until: Observable<any>,
  ) {
    return control.valueChanges.pipe(
      // Pull initial value so we can evaluate if
      // first emission from valueChanges actually
      // changes value of form
      startWith([control.getRawValue()]),
      // Get the raw value because it will include disabled fields
      map(() => control.getRawValue()),
      // Compare prev/next values explicitly, changes emitted
      // by the form control may occur even if values don't change
      distinctUntilChanged((a, b) => isEqual(a, b)),
      // Skip 1 because we pulled the initial value,
      // we only want the first change, not the first initial value
      skip(1),
      takeUntil(until),
    );
  }

  markAsEdited() {
    this._setState('EDITED');
  }

  markAsSaved() {
    this._setState('SAVED');
  }

  markAsUnchanged() {
    this._setState('UNCHANGED');
  }

  private _setState(value: DataChangeState) {
    this._state$.next(value);
  }

  private _isState$(value: DataChangeState) {
    return this.state$.pipe(
      map((v) => v === value),
      distinctUntilChanged(),
    );
  }
}
