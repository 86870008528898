<!-- Need two different header paddings - with and without actions -->
<div class="section-header-wrapper">
  <div class="section-header-text">
    <ng-content select="gax-alert-panel"></ng-content>
    <ng-content select="gax-page-section-header-title"></ng-content>
    <ng-content select="gax-page-section-header-description"></ng-content>
  </div>
  <ng-content select="gax-page-section-header-actions"></ng-content>
</div>
<ng-content></ng-content>
