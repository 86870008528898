/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GaLoadingProcessService } from '../ga-loading-process.service';
import { monitorNavigation } from '../monitor-navigation';

@Component({
  selector: 'gax-ga-loading-progress-bar',
  templateUrl: './ga-loading-progress-bar.component.html',
  styleUrls: ['./ga-loading-progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GaLoadingProgressBarComponent implements OnDestroy {
  onDestroy$ = new Subject();
  @Input()
  monitorNavigation: boolean = true;

  constructor(
    public readonly loading: GaLoadingProcessService,
    router: Router,
  ) {
    if (this.monitorNavigation) {
      router.events.pipe(takeUntil(this.onDestroy$)).subscribe((e) => {
        monitorNavigation(e as any, loading.process);
      });
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
