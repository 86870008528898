import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import { CurrencyConversionContext } from 'gain-web/shared-modules/money/currency-conversion-context';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { ProcessMonitor } from 'gain-lib/ga-process-monitor/process-monitor';

type ConvertableMoneyModel =
  | {
      state: 'original';
      money: { currencyCode: string; amount: number };
      tooltip: string;
    }
  | {
      state: 'converted';
      money: { currencyCode: string; amount: number };
      tooltip: string;
    }
  | {
      state: 'error';
      money: { currencyCode: string; amount: number };
      tooltip: string;
    };

@Component({
  selector: 'app-convertible-money',
  templateUrl: './convertible-money.component.html',
  styleUrls: ['./convertible-money.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConvertibleMoneyComponent implements OnDestroy {
  onDestroy$ = new Subject();
  private _inputCurrency$ = new BehaviorSubject<string | null>(null);
  private _inputAmount$ = new BehaviorSubject<number | null>(null);

  view$: Observable<ConvertableMoneyModel | null>;

  get getExchangeRateRequestProcess(): ProcessMonitor {
    return this._currencyConversionContext.getExchangeRateRequestProcess;
  }

  @Input()
  roundingBehavior: 'Use precision settings' | 'none' =
    'Use precision settings';

  @Input()
  set currencyCode(value: string) {
    this._inputCurrency$.next(value);
  }

  @Input()
  set amount(value: number | null) {
    this._inputAmount$.next(value);
  }

  constructor(private _currencyConversionContext: CurrencyConversionContext) {
    this._inputCurrency$.pipe(takeUntil(this.onDestroy$)).subscribe((curr) => {
      if (curr != null) {
        _currencyConversionContext.registerCurrency(curr);
      }
    });

    this.view$ = combineLatest([
      _currencyConversionContext.selectedCurrency$,
      this._inputCurrency$,
      this._inputAmount$,
    ]).pipe(
      switchMap(([selectedCurrency, inputCurrency, inputAmount]) => {
        if (inputCurrency == null || inputAmount == null) {
          return of(null);
        }
        if (selectedCurrency == null || selectedCurrency === inputCurrency) {
          return of({
            state: 'original',
            money: { currencyCode: inputCurrency, amount: inputAmount },
            tooltip: 'Original currency/amount',
          } as const);
        }

        return of(
          this._currencyConversionContext.convert({
            amount: inputAmount,
            fromCurrency: inputCurrency,
            toCurrency: selectedCurrency,
          }),
        ).pipe(
          map((result) => {
            if (result == null) {
              return {
                state: 'error',
                money: { currencyCode: inputCurrency, amount: inputAmount },
                tooltip: `Exchange rate not available for ${inputCurrency}/${selectedCurrency}`,
              } as const;
            }
            return {
              state: 'converted',
              money: {
                currencyCode: selectedCurrency,
                amount: result.amount,
              },
              tooltip: `Converted`,
            } as const;
          }),
        );
      }),
    );
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }
}
